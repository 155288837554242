// Content pages
.content__header {
  padding: $small-spacing 0;
  /*border-bottom: 1px solid $grey-lighter;*/
  @include clearfix;
  padding-bottom: $base-spacing;
  margin-bottom: 0;
  @include media($md) {
    padding-top: 0;
    margin-top: $base-spacing;
    margin-bottom: $base-spacing * 1.5;
  }
  .search-results-section & {
    margin-bottom: $base-spacing;
  }
}

.article__intro {
  font-family: $inter-bold;
  font-weight: normal;
}

.content__header-title {
  font-size: modular-scale(4);
  font-weight: normal;
  margin-bottom: 0;
  @include media($md) {
    @include span-columns(10);
    float: none;
  }
  @include media($lg) {
    @include span-columns(8);
    float: none;
  }
  .page__news-landing & {
    margin: 0;
  }
}

.content__header-intro {
  font-family: $inter-bold;
  font-size: modular-scale(1, 0.8em);
  font-weight: normal;
  margin: 0;
  padding-top: $base-spacing;
  @include media($md) {
    @include span-columns(8);
    padding-top: $base-spacing;
  }
}

.content__header--news-landing {
  @include media($md) {
    .breadcrumb {
      @include span-columns(5);
      @include omega();
      margin: 0;
      margin-top: 5px;
    }
  }
  @include media($lg) {
    padding-top: 0;
  }
}

.content__body {
  clear: both;
}






.blockquote.row {
    background-color: $brand-2024-ash2;
}
.blockquote__wrapper {
    display: flex;
    flex-direction: row;
    text-align:left;
    padding-bottom: 20px;
    @media (max-width: $small-screen) {
        flex-direction: column;
    }
}
.blockquote__wrapper .col2 {
    padding-left: 20px;
    @media (max-width: $small-screen) {
        padding-left: 0px;
        flex-direction: column;
    }
}

.blockquote img{
    @media (max-width: $small-screen) {
        max-height:70px;
    }
}

.blockquote {
    position: relative;
    clear: both;
    font-size: 1.2em;
    color: $grey-darker;
    padding: 0px;
    margin: 0px;
}
.citation {
    margin-top:15px;
    font-size: 12px;
    font-weight: bold;
    @include media($md) {
        font-size: 16px;
    }
}








.colorblock-title {
  color: #fff;
  background: $brand-primary;
  padding: ($base-spacing/2) $base-spacing;
  font-size: 1.3em;
  font-size: modular-scale(0);
  @include media($lg) {
    display: inline;
    float: left;
    font-size: modular-scale(1);
    margin: 0;
  }
}

.colorblock-title--primary {
  background: $brand-primary;
}

.colorblock-title--secondary {
  background: $brand-secondary;
}

.colorblock-title--offset {
  padding: ($base-spacing/3) ($base-spacing/2);
  padding-right: ($base-spacing * 2);
}

// Editor note
.editor-note {
  background: $brand-2024-sand1;



  padding: $base-spacing;



  line-height: 1.7;
  @include media($md) {
    padding: $base-spacing * 1.5.5;
  }
}

.editor-note__content {
  @include media($md) {
    @include span-columns(12);
  }
  @include media($lg) {
    @include span-columns(7);
    @include shift(0);
  }
}

.editor-note__content.editor-note__contact {
  @include media($md) {
    @include span-columns(6);
  }
  @include media($lg) {
    @include span-columns(4);
    @include shift(1);
  }
}

.editor-note__contact-dets {
  a {
    color: $brand-primary;
  }
  p {
    margin: 0;
  }
}

.editor-note__view-all {
  padding-top: $base-spacing;
}

.editor-note__title {
  font-size: modular-scale(2);
  @include media($lg) {
    font-size: modular-scale(1 + 1);
    font-family: $inter-bold;
  }
}
