// Breakpoints
$site-max-width: 1200px;
$small-screen: 500px;
$medium-screen: 768px;
$large-screen: 1000px;
$x-large-screen: 1200px;
// When the nav breaks to toggle
$desktop-menu: $large-screen;
// Paths
$fonts-path: "/fonts" !default;
$image-path: "/images" !default;
// break points
$sm: new-breakpoint(min-width $small-screen 12);
$md: new-breakpoint(min-width $medium-screen 12);
$lg: new-breakpoint(min-width $large-screen 12);
$xl: new-breakpoint(min-width $x-large-screen 12);
// Font path
$chevin: "Chevin",Helvetica,Arial,"Lucida Grande",sans-serif !default;
$chevin-bold: "Chevin-bold",Helvetica,Arial,"Lucida Grande",sans-serif !default;
$din: "DIN",Helvetica,Arial,"Lucida Grande",sans-serif !default;
$din-bold: "DIN-Bold",Helvetica,Arial,"Lucida Grande",sans-serif !default;
$inter: "Inter",Helvetica,Arial,"Lucida Grande",sans-serif !default;
$inter-bold: "Inter-bold",Helvetica,Arial,"Lucida Grande",sans-serif !default;


// Typography
/* 2024: Body used to Din, Headings Chevin. Now all is Inter. */
$base-font-family: $inter;
$heading-font-family: $inter; 
// Font Sizes
$base-font-size: 1em;
$small-font-size: $base-font-size * .85;
// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;
// Other Sizes+
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors - 2024 Rebrand
$brand-2024-rose1: #f09990;
$brand-2024-rose2: #c17c74;
$brand-2024-rose3: #754b46;

$brand-2024-ash1: #e4f2e7;
$brand-2024-ash2: #b8c4bb;
$brand-2024-ash3: #717872;

$brand-2024-sky1: #dceaff; // light
$brand-2024-sky2: #c9d6ea; // mid 
$brand-2024-sky3: #88919e; // dark

$brand-2024-sand1: #ffe6d3;
$brand-2024-sand2: #ebc8ad;
$brand-2024-sand3: #c7aa93;

// Colors
$brand-primary: black; /* #da202a;*/
$brand-secondary: #0892cb;
$brand-secondary-dark: #212449;
$brand-tertiary: #62a531;
$brand-black: #333333;
$brand-yellow: #fdda24;
$brand-grey1: #404044;
$brand-grey2: #53535a;
$brand-grey3: #c1c6c8;
$brand-grey4: #eaeef0; // Requested by Luminous - https://luminousweb.atlassian.net/browse/RM-6
$brand-grey5: #f5f6f7; // Requested by Luminous
$brand-darkGrey: #2a2a2c;
$brand-teal: #088578;
$grey-blue: #53535A;
$off-white: #f4f4f3;
$brand-success: #00853f; // green
$brand-danger: #e30613; // red
$brand-info: #00b0e8; // blue
$grey-darker: #2e2e2e; // headings
$grey-dark: #7f7f7f; // body copy
$grey: #d1d1d1;
$grey-light: #969696;
$grey-lighter: #f4f4f4;
// Used on link backgrounds
$link-grey: #efefef;
$nav-links: $brand-primary;
// Font Colors
$base-font-color: $grey-blue;
$action-color: $brand-info;
// Border
$base-border-color: $grey;
$base-border: 1px solid $base-border-color;
// Link link-states
$focus-colour: #e4e4e4;
// Background Colors
$base-background-color: $grey-lighter;
$secondary-background-color: $grey-light;
// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow,
0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
$input-background-color: #fff;
$input-border: 1px solid transparent;
$input-placeholder-color: $base-font-color;
$input-color: $base-font-color;
// Animations
$base-duration: 150ms;
$base-timing: ease;
// Apply to CSS Grid elements inside a @supports feature query for display: grid.
// Removes widths and margins for elements that are positioned with CSS Grid where available.
// Stops the fallback coming through and effecting grid elements.
@mixin grid-reset() {
  margin: 0;
  display: initial;
  width: auto;
  max-height: none;
  height: auto;
}

@mixin link-states($colour) {
  &:active,
  &:focus,
  &:hover {
    outline: 0;
  }
}

@mixin iefix {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin nice-underline($colour, $hoverColour, $backgroundColour) {
  color: $colour;
  padding: 2px 0;
  background-color: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 95%, #404044 100%);
  background: -webkit-linear-gradient(top, #ffffff 95%, #404044 100%);
  background: linear-gradient(to bottom, #ffffff 95%, #404044 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#404044', GradientType=0);
  display: inline;
  &:hover {
    background: #efefef;
    background: -moz-linear-gradient(top, #efefef 0%, #efefef 95%, #404044 100%);
    background: -webkit-linear-gradient(top, #efefef 0%, #efefef 95%, #404044 100%);
    background: linear-gradient(to bottom, #efefef 0%, #efefef 95%, #404044 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#404044', GradientType=0);
    display: inline;
  }
  &:focus,
  &:active {
    background: #efefef;
  }
}

/* ------------------------------------------------------------
Box Shadow mixin

Has Royal Mail Defaults so can be used without arguments.
Over-ride at the point of use eg:

    @include box-shadow($blur: 10px)
*/

@mixin box-shadow($top: 0, $left: 10px, $blur: 30px, $color: rgba(0, 0, 0, .3), $inset:"") {
  -webkit-box-shadow: $top $left $blur $color unquote($inset);
  -moz-box-shadow: $top $left $blur $color unquote($inset);
  box-shadow: $top $left $blur $color unquote($inset);
}

/* ------------------------------------------------------------
Override Neat's grid settings
*/

$column: golden-ratio(1em, 3) !default; // Column width
$gutter: golden-ratio(1em, 3) !default; // Gutter between each two columns
/* ------------------------------------------------------------
Set Bourbon's flex-grid variables to match what Neat is doing
http://bourbon.io/docs/#flex-grid
*/

$fg-column: $column;
$fg-gutter: $gutter;
/* ------------------------------------------------------------
OMEGA RESET
http://www.joshfry.me/blog/2013/05/13/omega-reset-for-bourbon-neat
*/

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: flex-gutter();
  }
  &:nth-child(#{$nth}+1) {
    clear: none
  }
}

// iPad sizing mixin
@mixin ipad-sizing() {
  @include media($lg) {
    @content;
  }
  @include media($md) {
    @media screen and (orientation: portrait) {
      @content;
    }
  }
}
