.field-validation-error {
  color: $brand-primary;
  display: block;
  font-family: $inter-bold;
  font-size: 14px;
  margin-bottom: $base-spacing;
  top: 0;
  clear: both;
}

.required:after {
  content: "*";
  color: lighten( $brand-primary, 5%)
}

.content-form {
  padding: $small-spacing 0;
}

.form__section-title {
  font-family: $inter-bold;
  color: $brand-grey3;
  font-size: modular-scale(1, 1.1em);
}