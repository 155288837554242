// Breadcrumbs
.breadcrumb {
  list-style: none;
  font-size: modular-scale(-1, 1em);
  margin: $small-spacing 0;
  margin-left: -$base-spacing/2;
  @include media($sm) {
    display: block;
  }
  @include media($md) {
    font-size: modular-scale(0, 1em);
    .content__header--article & {
      margin: $base-spacing/1.5 0;
    }
  }
}

.breadcrumb__item {
  margin-bottom: 4px;
  display: inline-block;
  font-weight: lighter;
  &:last-child .fa {
    display: none;
  }
}

.breadcrumb__item-sep {
  font-weight: bold;
  font-size: modular-scale(-1, 1em);
  position: relative;
  top: -2px;
  color: #333;
  .jumbo-header & {
    color: #fff;
  }
}

.breadcrumb__link {
  color: #333;
  display: inline;
  padding: 4px $base-spacing/2;
  margin-right: 4px;
  &:hover {
    color: $brand-primary;
    text-decoration: underline;
  }
  &:focus {
    background: none;
  }
  .jumbo-header & {
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.jumbo-header {
  .breadcrumb__link {
    color: #fff;
  }
}