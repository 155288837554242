

.jumbo-header {
  background-size: cover !important;

  @include iefix();
  position: relative;
  margin-bottom: 0;
  .container {
    height: 100%;
    @include media($md) {
      position: relative;
    }
  }

  @include media($sm) {
    height: 430px;
  }
  @include media($md) {
    height: 430px;
  }

}

.jumbo-header__gradient {
  height: 100%;
  background:  linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,.3) 15%,rgba(0,0,0,.3) 50%,rgba(0,0,0,0.7) 70%,rgba(0,0,0,0.7) 100%);
  @include media($sm) {
    background:  linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 15%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.7) 70%,rgba(0,0,0,0.7) 100%);
  }
}

.jumbo-header__content {
  @include iefix();
  bottom: $base-spacing;
  padding: $base-spacing;
  color: #fff;
  @include media($sm) {
    position: absolute;
    padding: 0;
    bottom: 4%;
  }

  @include media($md) {
    @include span-columns(8);
    padding: 0;
    bottom: 10%;
  }
  @include media($lg) {
    @include span-columns(10);
    padding: 20px 0;
    bottom: 4%;
  }
}



// TODO: Rename partial for general use of jumbo-header component
/*
.jumbo-header {
  padding: $base-spacing 0;
  padding-bottom: 0;
  @include media($md) {
    min-height: 430px;
  }
}
*/

.jumbo-header__title {
  color: #fff;
  font-weight: normal;
  margin: 0;
  padding: $small-spacing/2 0;
  font-size: modular-scale(4);
}

.jumbo-header__content {
  color: #fff;
  padding: $base-spacing 0;
  @include media($md) {
    padding-bottom: $base-spacing*1.5;
    @include span-columns(7);
    @include omega();
  }
}

.jumbo-header__search {
  padding: $base-spacing 0;
  border-top: 1px solid $grey-dark;
  font-size: modular-scale(-1);
  clear: both;
}

.jumbo-header__search-form {
  display: flex;
  margin-right: $base-spacing;
  margin: 0;
  @include media($md) {
    padding: $base-spacing 0;
    margin: 0 auto;
    max-width: 70%;
  }
}

.jumbo-header--search-btn {
    flex: 0 0 auto;
}

input[type="search"].jumbo-header__search-input {
    margin: 0;
    margin-right: 12px;
}

input[type="submit"].jumbo-header--search-btn {
  font-size: modular-scale(1);
  padding: $small-spacing $base-spacing*1.5;
}