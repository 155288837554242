﻿// Accordion component
.row.accordion {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;


}
ul.accordion {
    margin: 0;
}
.accordion__item {
  margin: 0;
  list-style: none;

  border: 2px solid $brand-2024-rose2;

  /*border-top: 0;*/
  &:first-child {
    /*border: 1px solid $grey-lighter;*/
  }
  &.active {
    .fa-angle-right {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}

.accordion__item-title-cont {
  position: relative;
}

.accordion__item-title--toplink {
  font-size: modular-scale(0, .9em);
  position: absolute;
  right: $base-spacing;
  top: 22px; //@include link-states($grey-dark);
  padding: 3px;
  color: darken($grey, 12%);
  &:hover {
    background: $grey-lighter;
  }
}

.accordion__content {
  padding: $small-spacing;
  .active & {
    display: block;
  }
}

.accordion__item-title {
  cursor: pointer;
  color: $brand-primary;
  margin: 0;
  font-size: 1.2em;
  font-family: $inter-bold;
  padding: $small-spacing / 2 $small-spacing;
  font-weight: normal;
  .fa {
    margin-top: 2px;
    transition: .5s 0s all;
  }
  span {
    display: inline-block;
    width: 90%;
  }
  &>* {
    vertical-align: top;
  }
}

.accordion.accordion--single {
    .accordion {
        &__item {
            border: 0;
        }
        &__item-title-cont {
            display: none;
        }
        &__content {
            display: block !important;
            padding: 0;
            p {
                &:last-of-type {
                    display: none;
                }
            }
        }
    }
}

.accordion-agenda {
    border: solid 2px $brand-2024-rose2;


    %detail-fonts {
        font-size: modular-scale(1, .66em);
        padding-right: $base-spacing * 1.5;

        @include media($md) {
            font-size: modular-scale(1.1, .66em);
            padding-right: $base-spacing * 1.75;
        }
    }

    @mixin accordion-item {
        margin: 0;
        transition: background-color 0.3s ease;

        &wrapper {
            @include margin(null ($base-spacing * 1.5));
        }

        &content {
            @include padding($base-spacing null);
            width: 100%;
            display: table;
            /*border-bottom: solid $border-width $off-white;*/
            box-sizing: border-box;

            > * {
                width: 50%;
                vertical-align: middle;
                display: table-cell;

                &:first-child {
                    font-weight: bold;
                    text-align: left;
                }

                &:not(:first-child) {
                    text-align: right;
                }
            }
        }
    }



    &__title {
        font-weight: bold;
        color: $brand-primary;
        @include margin(null 36px $base-spacing * 0.5);
    }

    &__item {
        @include accordion-item;

        &.has-subitems .js-acc-title {
            cursor: pointer;
        }

        &content {
            position: relative;
        }

        &__title {
            font-size: modular-scale(1, 1em);

            @include media($md) {
                font-size: modular-scale(1.2, 1em);
            }
        }

        &__detail {
            @extend %detail-fonts;
            color: $brand-primary;

            .icon__clock {
                $size: 14px;

                position: relative;
                top: 3px;
                right: $base-spacing;
                height: $size;
                width: $size;
                display: none;

                background: {
                    position: center center;
                    size: $size;
                    repeat: no-repeat;
                    image: url('#{$image-path}/icons/png/clock-red.png');
                    image: none, url('#{$image-path}/icons/clock-red.svg');
                }

                @include media($md) {
                    display: inline-block;
                }
            }
        }

        &__arrow {
            $size: 18px;

            position: absolute;
            top: 50%;
            margin-top: $size * -0.5;
            right: 0;
            height: $size;
            width: $size;
            transition: transform 0.5s ease;
            transform: rotateZ(90deg);

            .st0 {
                transition: fill 0.3s ease;
                fill: $brand-grey1;
            }
        }

        .accordion-agenda__subitem {
            @include accordion-item;
            background-color: $brand-2024-rose1; /*tint($off-white, 50%);*/
            border: solid 2px $brand-2024-rose2;

            &:first-child {
                /*margin-top: $border-width * -1;*/
            }

            &:not(:last-child) {
                .accordion-agenda__subitemcontent {
                    /*border-bottom-color: #fff;*/
                }
            }

            &__title {
                font-size: modular-scale(1, 0.85em);
                opacity: 0.75;
            }

            &__detail {
                @extend %detail-fonts;
                opacity: 0.75;
            }

            &s {
                opacity: 0;
                transition: opacity 0.3s ease;
                display: none;
            }

            &content {
                > * {
                    @for $i from 1 through 10 {
                        &:first-child:nth-last-child(#{$i}n),
                        &:first-child:nth-last-child(#{$i}n) ~ * {
                            width: 100% / $i;
                        }
                    }
                }
            }
        }

        &.active {
            background-color: $brand-2024-rose2;

            .accordion-agenda__item__arrow {
                transform: rotateZ(-90deg);

                .st0 {
                    fill: $brand-primary;
                }
            }

            .accordion-agenda__subitems {
                opacity: 1;
            }
        }
    }
}
