@mixin headers() {
  h2,h3,h4,h5,h6 {
    @content;
  }
}

@mixin btn-arrow {
  .btn.btn--arrow {
    padding-right: $base-spacing * 1.5;
  }
}

@mixin viewport-size($size: 10) {
  .wraptainer,
  .container:not(.sub-container) {
    $base-size: $size * 10vh;

    min-height: $base-size;
    min-height: calc(#{$base-size} - 64px);

    @include media($lg) {
      min-height: calc(#{$base-size} - 104px);
    }
  }

  @content;
}

@mixin full-viewport {
  &,
  .wraptainer,
  .container {
    height: auto !important;
  }

  .content__hero--panel .wraptainer,
  .content__hero--panel .container {
    min-height: 430px;
  }

  &.use-full-viewport {
    @include viewport-size() {
      @content;
    }
  }

  @for $bp from 1 through 10 {
    &.use-viewport--#{$bp} {
      @include viewport-size($bp) {
        @content;
      }
    }
  }
}

.site-header {
  width: 100%;
  position: fixed;
  z-index: 14;
  background: $brand-2024-sky1;
  @include media($desktop-menu) {
    border-top: 0;
    position: relative;
    .body__home & {
      position: absolute;
      /*background: $brand-black;*/
      border-top: 0;
      /*background: rgba(0, 0, 0, .65);*/
      .navbar {
          /*background: transparent;*/
      }
    }
  }
  transition: all 0s .5s ease-in-out;
}

// Stripes animation
.brand-stripes--img {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.brand-stripes--img.show-anim {
  opacity: 1 !important
}

// Header themes
@include media($desktop-menu) {
  .theme {
    .site-header,
    .navbar {
      /*background: $brand-primary;*/
      .navbar-logo {
        background-image: url('#{$image-path}/rmg-logo-wht.png');
        background-image: url('#{$image-path}/rmg-logo-wht.svg'),
        linear-gradient(transparent,
        transparent);
      }
      .navbar__container {
        border-bottom: 0
      }
    }
  }
}

.site-header--sticky {
  background: #fff;
  box-shadow: 0 8px 15px rgba(52, 52, 52, .15);
  @include media($desktop-menu) {
    .body__home & {
      background: $brand-2024-sky1; /*rgba(0, 0, 0, .65);*/
      .navbar {
          /*background-color: transparent;*/ 
      }
    }
  }
}

.site-header__social {
  display: none;
  background: $brand-darkGrey; //padding: $base-spacing/5  $base-spacing;
  padding: 6px $base-spacing;
  .container {
    padding-right: 8px;
  }
  @include clearfix;
  @include media($desktop-menu) {
    display: block;
    padding: 6px 0;
  }
}

.site-header__social-list {
    margin-top: 6px;
  float: right;
  text-transform: uppercase;
}

.site-header__social-list-link {
  color: #fff;
  @include link-states(#fff);
  &:hover,
  &:focus {
    background-color: transparent;
    color: #fff;
  }
  &:focus {
    outline: 1px solid #fff;
  }
}

.site-header__social-list-item {
  font-weight: lighter;
  color: #fff;
  list-style: none;
  display: inline;
  float: left;
  padding: 0 10px;
  margin: 0;
  font-size: 12px;
  &:last-child {
    padding-right: 0;
  }
}

.site-header__desktop {
  display: none;
  width: 100%;
  @include media($desktop-menu) {
    display: block;
  }
}

.navbar {
    position: relative;
    display: block;
    background-color: $brand-primary;
    @include clearfix;
}

.navbar__container {
  //border-bottom: 1px solid $brand-primary;
  border: 0;
  position: relative;
}

.navbar--mobile {
  @include media($desktop-menu) {
    display: none;
  }
}

// Logo
.navbar-logo {
    float: left;
    font-family: initial;
    text-indent: -9999em;
    font-weight: lighter;
    margin: 0;
    top: 0;
    width: 65%;
    height: 71px;
    max-width: 220px;
    background-image: url('#{$image-path}/rmg-logo-wht.jpg');
    background-image: url('#{$image-path}/rmg-logo-wht.svg'), linear-gradient(transparent, transparent);
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: contain;

    // removed sticky styling
    /*.site-header--sticky & {
        background-image: url('#{$image-path}/rmg-logo-blk.png');
        background-image: url('#{$image-path}/rmg-logo-blk.svg'),
    }*/

    @include media($desktop-menu) {
        position: absolute;

        .body__home & {
            background-image: url('#{$image-path}/rmg-logo-wht.png');
            background-image: url('#{$image-path}/rmg-logo-wht.svg'), linear-gradient(transparent, transparent);
        }
    }

    a:focus {
        outline: 0; //outline: 1px solid $grey-lighter;
        background: none;
    }

    .navbar--mobile & {
        position: absolute;
        top: 5px;
        max-width: 200px;
        height: 100%;
    }
}

.navbar-logo-link {
  color: $grey-blue;
  font-weight: bold;
  transition: color ease-in .2s;
  display: block;
  height: 100%;
  .body__home & {
    @include media($desktop-menu) {
      color: #fff;
    }
  }
}

.share-bar {
  display: none;
  justify-content: space-between;
  padding: 6px 0;
  margin: 0;
}

.share-bar__title {
  margin: 0;
  color: $brand-primary;
  font-size: 17px;
  margin-top: 4px;
  max-width: 80%;
  float: left;
}

.article__meta--sticky {
  margin-left: auto;
  text-align: right; // for IE9
  clear: none !important;
}

.site-header--clone {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  /* Translate -100% to move clone off screen initially */
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  /* Animations */
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  .share-bar {
    @include media($desktop-menu) {
      display: block; //display: flex;
      z-index: 12;
    }
  }
  .navbar-logo {
    max-width: 180px;
  }
  .navbar__container {
    height: auto;
  }
  .site-header__social {
    display: none;
  }
  .navbar-logo {
    height: 70px;
  }
}

.site-header--sticky {
  //z-index:11;
  /* Translate back to 0%; */
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.site-header--unstick {
  opacity: 0;
}

.skip {
  padding: 6px 8px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: white;
  border-right: 2px dotted white;
  border-bottom: 2px dotted white;
  border-bottom-right-radius: 3px;
  background: transparent;
  transition: top 1s ease-out, background 1s linear;
  z-index: 100;
  &:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    font-family: Arial, sans-serif;
    color: white;
    background: $brand-primary;
    outline: 0;
    transition: top .1s ease-in, background .5s linear;
  }
}

// Animated header element
.brand-stripes {
  display: none;
  position: absolute;
  top: 0;
  z-index: 20;
  @include media($desktop-menu) {
    display: block;
  }
}

.header-shares {
    display: inline-block;
    vertical-align: middle;
    padding-left: 2px;
    margin: 0;
    font-size: 0.9em;
    color: #fff;
    span {
        display: inline-block;
        vertical-align: bottom;
        margin-right: $small-spacing / 2;
        + span {
            font-weight: bold;
            + span {
                font-size: 0.8em;
                font-weight: normal;
            }
        }
    }
}

// Header with Text Component
.header-text {
  $header-text-padding: $base-spacing * 1.5;

  padding: $header-text-padding 0;
  background-color: white;

  @include media($md) {
    p {
      columns: 2;
      column-gap: 7.5%;
    }
  }

  .header-wrapper {
    @include margin(null auto);
    @include padding(null $header-text-padding);

    // max-width: $large-screen;
  }

  &__title {
    font-size: modular-scale(1, 1em);
    font-weight: bold;
    max-width: $medium-screen;

    @include media($sm) {
      font-size: modular-scale(2.25, 1em);
    }
  }
}

.jumbo-header {
    @include full-viewport;
    height: auto !important;
    overflow: hidden;

    .jumbo-header__bgs {
        & > * {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            opacity: 0;
            transition: opacity 300ms ease;

            background: {
                position: center center;
                repeat: no-repeat;
                size: cover;
            }

            &:first-child,
            &.active {
                opacity: 1;
            }
        }
    }

    .jumbo-header__gradient {
        background: none !important;

        > .wraptainer {
            position: relative;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            max-width: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            max-width: 100vw;

            > * {
                width: 100%;
                flex-shrink: 1;
                flex-grow: 1;
                flex-basis: 100%;
            }

            .sub-container {
                $sub-spacing: $base-spacing * 0.5;

                & > *:first-child {
                    padding-left: $sub-spacing;
                }

                & > *:last-child {
                    padding-right: $sub-spacing;
                }
            }

            @include media($md) {
                .sub-container {
                    $sub-spacing: $base-spacing * 1.5;

                    & > *:first-child {
                        padding-left: $sub-spacing;
                    }

                    & > *:last-child {
                        padding-right: $sub-spacing;
                    }
                }
            }
        }
        // IE-Specific (use table instead of flex)
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
            display: table;
            overflow: hidden;

            > .wraptainer {
                width: 100%;
                vertical-align: middle;
                display: table-cell;

                .jumbo-header__breadcrumb {
                    position: absolute;
                    top: 0;
                }

                .jumbo-header__hoverlinks {
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }

    .jumbo-header__wrapper {
        display: flex;
        align-items: center;

        padding-top: 1em;
        padding-bottom: 50px; /* Dentsu requested addition. This can be overridden by content editors which adds use-viewport-- class. */

        @include media($sm) {
            width: 100%;
            display: table;
        }
        /*      @include media($md) {
        @include padding($base-spacing * 2 null);
      }
*/
        // Add padding for hoverlinks on IE
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            &:nth-last-child(2) {
                margin-bottom: 165px;
            }
        }

        .sub-container {
            > * {
                display: table;
                width: 100%;
            }

            &::before,
            &::after {
                width: auto !important;
                display: none !important;
            }
        }
    }

    .jumbo-header__content,
    .jumbo-header__boxout {
        position: relative !important;
        float: none;
        clear: both;
        width: 100%;
        padding: 0;
        display: block;
        vertical-align: top;
        box-sizing: border-box;
    }

    .jumbo-header__content {
        bottom: 0 !important;
        padding: 0;
        // No Boxout
        &:only-child {
            @include media($md) {
                .jumbo-header__contentaswd {
                    padding-right: 33%;
                }
            }

            @media screen and (orientation: landscape) {
                & > * {
                    padding-right: 28%;
                }

                @include media($md) {
                    .jumbo-header__title {
                        padding-right: 0;
                    }
                }
            }

            @include media($xl) {
                .jumbo-header__title {
                    padding-right: 25% !important;
                }

                .jumbo-header__contentaswd {
                    padding-right: 50%;
                }
            }
        }
        // With boxout
        &:nth-last-child(2):first-child {
            @include media($sm) {
                &,
                & ~ * {
                    padding-right: 33%;
                }
            }

            @media screen and (orientation: landscape) {
                &,
                & ~ * {
                    display: table-cell;
                    width: 50%;
                    padding-right: 0;
                }

                padding-right: $base-spacing;

                @include media($sm) {
                    &.jumbo-header__content {
                        width: 58%;
                    }

                    & ~ .jumbo-header__boxout {
                        margin-top: 0;
                    }
                }

                @include media($md) {
                    padding-right: $base-spacing * 2;
                }

                @include media($lg) {
                    padding-right: $base-spacing * 3;
                }
            }
        }

        .jumbo-header__title {
            margin: 0;
            color: inherit;

            font: {
                size: modular-scale(3, 1em);
                weight: bold;
            }

            @include ipad-sizing() {
                font-size: modular-scale(4, 1em);
            }
        }
    }

    .jumbo-header__boxout {
        margin-top: $base-spacing * 2;

        @include ipad-sizing() {
            margin-top: $base-spacing * 3;
        }
        // @include media($lg) {
        //   width: 40%;
        // }
    }

    .jumbo-header__boxcontent {
        padding: $base-spacing;
        background-color: #fff;

        @include ipad-sizing() {
            padding: $base-spacing * 1.5;
            width: 372px;
        }

        @include media($md) {
            @media screen and (orientation: portrait) {
                width: 100%;
            }
        }

        > p:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .red-title {
            font: {
                size: modular-scale(2, 1em);
                weight: bold;
            }

            &:only-child {
                margin-top: $base-spacing * -0.5;
                display: block;

                @include media($sm) {
                    margin-top: 0;
                }
            }
        }

        .btn {
            @include padding($base-spacing null);
            display: block !important;

            @include btn-arrow();

            @include media($sm) {
                display: inline-block;
            }

            &:not(:only-child) {
                margin-top: $base-spacing * 1.5;
            }
        }
    }

    .jumbo-header__breadcrumb {
        align-self: flex-start;
    }

    .jumbo-header__hoverlinks {
        align-self: flex-end;
        background-color: rgba(#000000, .4);

        ul {
            padding-bottom: $base-spacing;

            @include media($sm) {
                padding-bottom: 0;
            }

            > li {
                margin: 0;
                padding: $base-spacing 0 0;
                vertical-align: top;

                @include media($sm) {
                    padding: 0;
                    margin-right: -4px;
                    width: 32.75%;
                    display: inline-block;
                }

                > a {
                    padding-right: $base-spacing;
                    display: block;

                    &:focus {
                        background: none !important;
                    }

                    & > div {
                        $duration: 0.3s;
                        $effect: ease;
                        $border-width: 3px;

                        position: relative;
                        font-size: 12px;
                        color: #ffffff;
                        width: 100%;
                        display: table;

                        border: {
                            style: solid;
                            color: transparent;
                            width: 0 0 0 $border-width;
                        }

                        transition: border $duration $effect, padding $duration $effect;

                        @include media($sm) {
                            top: $border-width * -1;
                            border-width: $border-width 0 0;
                            padding-bottom: $base-spacing;
                        }

                        @include media($md) {
                            font-size: 16px;
                        }

                        > * {
                            position: relative;
                            display: table-cell;

                            @include media($sm) {
                                transition: top $duration $effect, opacity $duration $effect;
                                top: $base-spacing * 0.66;
                                display: block;
                            }

                            &.arrow-icon {
                                $icon-size: 16px;

                                color: $brand-primary;
                                opacity: 0;

                                min: {
                                    height: $icon-size;
                                    width: $icon-size * 1.5;
                                }

                                background: {
                                    image: url('#{$image-path}/icons/png/arrow1-red.png');
                                    position: center right;
                                    repeat: no-repeat;
                                    size: $icon-size auto;
                                }

                                @include media ($sm) {
                                    margin-top: $base-spacing * 0.5;
                                }

                                span {
                                    display: none;

                                    @include media ($sm) {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    &:hover > div {
                        border-left-color: $brand-primary;
                        padding-left: $base-spacing * 0.5;

                        @include media($sm) {
                            border-left-color: transparent;
                            padding-left: 0;
                        }

                        > * {
                            top: 0;

                            &.arrow-icon {
                                opacity: 1;
                            }
                        }
                    }
                }

                &:nth-child(-n+3) a {
                    & > div {
                        @include media($sm) {
                            padding-top: $base-spacing;
                        }
                    }

                    &:hover > div {
                        border-top-color: $brand-primary;
                    }
                }
            }
        }
    }
    // Hoverlink page hero specific
    &--hoverlinks {
        $animation-duration: 300ms;

        @keyframes jumpfade {
            0%, 100% {
                opacity: 1;
            }

            50% {
                opacity: 0;
            }
        }

        .jumbo-header__mech {
            position: relative;
            width: auto !important;
            display: flex;

            transition: {
                property: transform;
                duration: 1;
                delay: $animation-duration / 2;
            }

            & > * {
                width: 100vw !important;
                transition: opacity $animation-duration ease;
                opacity: 1;
            }

            &.active {
                &:not(.transitioned) > * {
                    opacity: 0
                }

                @for $i from 1 through 10 {
                    &[data-hlactive="#{$i}"] {
                        transform: translateX(-100vw * $i);
                    }
                }
            }
        }
    }
}

.content__hero {
  @include full-viewport() {
    // @include media($lg) {
    //   .article__meta {
    //     position: absolute;
    //     bottom: $base-spacing;
    //   }
    // }
  }

  &,
  &--panel {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  &--panel {
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    }

    > .container {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &--wrapper {
    padding: $base-spacing * 1.5;
    padding-top: $base-spacing * 4;

    &.banner--text- {
      &white {
        *:not(.btn) {
          color: #fff;
        }

        .article__meta-item {
          &--author::before {
            content: url('/images/icons/png/author-white.png');
            content: url('/images/icons/author-white.svg');
          }
          &--share::before {
            content: url('/images/icons/png/share-white.png');
            content: url('/images/icons/share-white.svg');
          }
        }
      }

      &black {
        *:not(.btn) {
          color: $brand-black;
        }

        .article__meta-item {
          &--author::before {
            content: url('/images/icons/png/author-black.png');
            content: url('/images/icons/author-black.svg');
          }
          &--share::before {
            content: url('/images/icons/png/share-black.png');
            content: url('/images/icons/share-black.svg');
          }
        }
      }
    }

    .breadcrumb {
      position: absolute;
      top: 0;
    }

    .content__hero--details {
      margin-bottom: $base-spacing * 2;

      > * {
        $vert-spacing: 5px;

        font-size: 12px;
        display: inline-block;

        @include margin(null $base-spacing $vert-spacing null);

        &.btn {
          @include padding($vert-spacing $base-spacing);
        }
      }

      > a {
        text-transform: uppercase;
        color: $brand-black !important;
        border-color: #fff;
        background-color: #fff;
      }

      .content__hero--date,
      .content__hero--time {
        white-space: nowrap;
      }
    }

    .article__header-title {
      font-size: modular-scale(2, 1em);

      @include media($md) {
        font-size: modular-scale(3, 1em);
      }

      @include media($lg) {
        font-size: modular-scale(4, 1em);
        width: 66%;
      }

      @include media($xl) {
        width: 50%;
      }
    }

    .article__subtitle {
      margin-top: $base-spacing * 0.5;
      font-size: modular-scale(1, 1em);

      @include media($md) {
        font-size: modular-scale(2, 1em);
      }

      @include media($lg) {
        width: 66%;
      }
    }

    .content__hero--btn {
      margin-top: $base-spacing;

      @include media($xl) {
        margin-top: $base-spacing * 3;
      }

      .btn {
        display: inline-block;

        @include media($md) {
          @include padding($base-spacing);
          min-width: 25%;
        }
      }
    }

    .article__meta {
      margin-top: $base-spacing * 2;
      margin-bottom: 0 !important;

      @include media($xl) {
        margin-top: $base-spacing * 3;
      }

      & > li {
        margin-right: $base-spacing;
        color: #fff;
        fill: #fff;

        * {
          margin: 0;
          padding: 0;
          background: none;
          color: inherit;
          fill: inherit;
          display: inline-block;
        }

        &::before {
          $icon-size: 18px;

          position: relative;
          top: -1px;
          height: 100%;
          width: $icon-size;
          margin-right: $icon-size / 2;
          display: inline-block;
          vertical-align: middle;
          -webkit-filter: drop-shadow(0 3px 2px rgba(0,0,0,0.33));
          filter: drop-shadow(0 3px 2px rgba(0,0,0,0.33));
        }

        &::after {
          content: none;
        }
      }
    }

    @include btn-arrow();
  }
}

.main-content > .content__hero .article__main {
  width: 100% !important;

  > .row > .container {
    @include outer-container($x-large-screen);

    > *:first-child {
      padding-left: $base-spacing * 1.5;
    }

    > *:last-child {
      padding-left: $base-spacing * 1.5;
    }
  }
}
