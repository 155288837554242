﻿// News cards
.news-cards--no-carousel {
  .news-cards__list {
    display: flex;
    flex-wrap: wrap;
    padding: $base-spacing*1.2 0 0;
    @include media($lg) {
      flex-wrap: nowrap;
    }
  }
  .news-card {
    margin-bottom: $base-spacing*1.5;
    display: flex !important;
    flex-direction: column;
    @include media($sm) {
      @include omega-reset(2n+1);
      @include span-columns(6);
      @include omega(2n);
    }
    @include media($md) {
      margin-bottom: $base-spacing*1.5;
    }
    @include media($lg) {
      @include omega-reset(2n);
      @include span-columns(3);
      @include omega(4n);
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.news-cards__list {
  clear: both;
  padding: $base-spacing*1.2 0;
  @include media($md) {
    .slick-list {
      // margin-left: 0;
      //
    }
    .slick-track {
      display: flex;
    }
  }
  @include media($lg) {
    padding: $base-spacing*1.2 12px;
    padding-top: $base-spacing/1.3;
  }
}

.news-cards__select {
  margin: 0 6px;
}

.news-cards__header {
  float: left;
}

.news-cards {
    margin-bottom: $base-spacing;
    background-color: #f8f8f8;
}

.news-card {
  width: 100%;
  will-change: transform;
  word-wrap: break-word;
  margin-left: $base-spacing / 2;
  margin-right: $base-spacing / 2;
  border: none;
  padding-bottom: $base-spacing*.7;
  display: flex;

  border:solid 1px #ccc;
  background-color:white;

  .news__meta-wrap {
    margin-top: auto;
    padding-bottom: 0;
  }
  &.slick-slide {
    display: flex !important;
    flex-direction: column !important;
  }
  @include media($md) {
    margin-left: $base-spacing;
    margin-right: $base-spacing;
    margin-bottom: 0;
    /*@include box-shadow(0, 10px, 35px, rgba(0, 0, 0, .25));*/
  }
  @include media($lg) {
    /*@include box-shadow(0, 10px, 40px, rgba(0, 0, 0, .18)); //transition: transform ease-in .7s;*/
    margin-right: $base-spacing*1.7;
    margin-top: $small-spacing;
    margin-bottom: $base-spacing*1.5; // Reduce margin bottom if using a pager below
    .news-cards--nav-below & {
      margin-bottom: $base-spacing*1;
    }
  }
  &:focus {
    outline: none;
    border: none;
  }
}

.news-cards--nav-below {
    h4 {
        font-size: 1em;
        font-weight: bold;
    }
}
/*
.news-cards__list {
    @supports (display: grid){
        .slick-track {  
            display: -ms-grid;
            display: grid;  
            &:after,
            &:before {
                display: none;
            }
        }
        .news-card {  
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            grid-row: 1/2;  
        }
        .news__meta-wrap { 
            
        }
      } 
} 
*/
.news-card__image {
    height: 170px;
    cursor: grab;
    cursor: -webkit-grab;
    transition: background-size .5s 0s ease-in-out;
    -moz-transition: background-size .5s 0s ease-in-out;
    -ms-transition: background-size .5s 0s ease-in;
    -o-transition: background-size .5s 0s ease-in;
    -webkit-transition: background-size .5s 0s ease-in;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative;

    @include media($md) {
        height: 170px;
    }
}

.news-card__content-wrap {
  padding: $small-spacing*1.5;
  padding-bottom: $small-spacing;
  position: relative;
  @include media($md) {
    overflow: hidden;
  }
}

.news-card__content {
  padding: $small-spacing*1.5;
  padding-top: $small-spacing*1.3;
  padding-bottom: 0;
  position: relative;
  @include media($lg) {
    min-height: 120px;
  }
  @supports (display: flex) {
    flex: 1;
  }
}

.news__meta {
  @include clearfix;
  /*border-top: 1px solid $grey;*/
  clear: both;
  @include media($lg) {
    /*padding: $small-spacing;*/
  } // News meta in different components
  .trending-news__panel & {
    padding-left: 0;
  }
  .news-card & {
    @include media($lg) {
      padding: 14px 0;
      padding-bottom: 0;
    }
  }
  .news__hero-block & {
    padding-left: 0;
  }
}
.news__hero-block .news__meta a {
    float: right;
}
.news__meta-wrap {
    padding: $small-spacing*1.5;
}

.news__meta--opentop {
  border-top: 0;
}

.news__meta-list {
  list-style: none;
  margin-bottom: $small-spacing;
  float: left;
  padding-top: $small-spacing;
  @include media($md) {
    padding-top: 0;
  } // Change style for use in Trending panel
  .trending-news__panel & {
    float: none;
    @include media($lg) {
      @include span-columns(7.5);
      @include omega();
    }
  } // Change style for use in Card
  .news-card & {
    float: none;
    padding: $small-spacing 0;
    width: 100%;
    margin-bottom: 0;
    @include media($lg) {
      @include span-columns(12);
      padding: 0;
      margin-right: 0;
      margin-bottom: 10px;
    }
    @include media($xl) {
      @include span-columns(6.5);
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.news__meta-tags {
    list-style: none;
    margin-bottom: $small-spacing;
    float: left;
    //padding-top: $small-spacing;
    padding-right: $small-spacing;

    @include media($md) {
        padding-top: 0;
    }

    li {
        display: inline-block;
        vertical-align: middle;
    }
}

.news__meta--flex {
    @include media($md) {
        display: flex;
        justify-content: space-between;
        &:after {
            display: none;
        }
    }

    .btn {
        flex: 0 0 auto;
        align-self: flex-start;
    }
}

.news__meta-list--full {
  float: none;
}

.news__meta-item--views {
  &::after {
    display: none;
  }
}

.news__meta-item--small {
  font-size: 12px;
}

.news__meta-item {
  display: inline;
  color: $grey-light;
  position: relative;
  font-size: 13px;
  padding: 0 8px;
  font-family: $inter;
  font-weight: normal;
  a {
    color: $grey-light;
  }
  &:first-child {
    +li {
      padding-left: 0;
    }
    &::before {
      display: none;
    }
  }
  &:first-child {
    padding-left: 0;
  }
}

.news-card__cta {
  float: left;
  @include media($lg) {
    float: none;
    clear: none;
    @include span-columns(6);
    @include omega();
    width: auto;
    float: right;
  }
  .trending-news__content & {
    @include media($md) {
      float: right;
      width: auto;
    }
  }
  .news-card & {
    float: left;
    @include media($xl) {
      float: right;
    }
  }
}

.news-card__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 600px;
  a {
    @include link-states($brand-primary);
  }
  @include media($lg) {
    /*height: 90px;*/
  }

    
}

.news-card__desc {
  overflow:hidden;
  font-size: 15px;
  max-width: 600px;
  @include media($md) {
    font-size: 15px;
  }

  .slick-slider & {
    cursor: grab;
    cursor: -webkit-grab;
  }
}

.news__meta--small {
  padding: 8px 0;
  .btn {
    float: right;
  }
  .news__meta-list {
    padding: 0;
    margin: 0;
    margin-bottom: 6px;
    width: 100%;
  }
  @include media($sm) {
    .news__meta-list {
      /*@include span-columns(6);*/
      @include omega();
    }
    .btn {
      float: right;
    }
  }
  @include media($lg) {
    .btn {
      clear: none;
      //float: none;
    }
    .news__meta-item {
      font-size: modular-scale(-1);
      padding: 0 8px;
      display: inline;
      display: inline-block;
      &:first-child {
        padding-left: 0;
      }
    }
    .news__meta-item--share {
      padding-right: 0;
    }
  }
}

.news__meta-list--full {
  float: none;
}

.news__meta-item--share {
    margin-bottom: 0;
  a {
    padding: 0px;
    background-color: transparent;
    background-image: url('#{$image-path}/icons/png/share.png');
    background-image: url('#{$image-path}/icons/share.svg'),
    linear-gradient(transparent,
    transparent);
    background-position: center center;
    background-repeat: no-repeat;
    /*width: 50px;
    height: 50px;*/
    width: 15px;
    height: 15px;
    background-size: contain;
    &:hover,
    &:focus,
    &:active {
      background-image: url('#{$image-path}/icons/png/share-alt.png');
      background-image: url('#{$image-path}/icons/share-alt.svg'),
      linear-gradient(transparent,
      transparent);
      background-position: center center;
      background-size: contain;
      border: none;
      outline: 0;
    }
    &::after {
      content: url('#{$image-path}/icons/share-alt.svg');
      display: none;
    }
  }
}

.gallery-directions--right {
  float: right;
}

.news-list--row {
  .suggestion-grid {
    &__item {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 0;
      margin-bottom: 3em;
      @include media($md) {
        margin-bottom: 5em;
      }
      border-bottom: 1px solid $grey;

    }
    &__image-wrap {
      display: none;
      @include media($md) {
        @include span-columns(6 of 24);
      }
    }
    &__content {
      @include span-columns(24 of 24);
      @include media($md) {
        @include span-columns(18 of 24);
      }
    }
  }
    .btn-wrapper{
        height:40px;
    }
  .news {
    &__meta {
      border-top: 0;
      /*border-bottom: 1px solid $grey;*/
      .btn {
        /*float: right;*/
      }
    }
  }
}

.news-lister {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1em;

    &__col {
        display: block !important;
        margin-left: 0;

        @include media($md) {
            @include span-columns(8);
            @include omega(2n);
        }

        + .news-lister__col {
            @include media($md) {
                @include span-columns(4);
                @include omega(2n);
                /*border-left: 1px solid $grey;*/
            }
        }
    }

    .news {
        &__meta {
            border-top: 0;
            border-bottom: 1px solid $grey;

            .btn {
                float: right;
            }
        }
    }

    .news-lister {
        &__heading {
            padding: 1em 1em 0;

            &:after {
                content: "";
                display: table;
                clear: both;
            }

            p {
                display: inline-block;
                margin: 0;
            }
        }

        &__richtext {
            padding: 1em;
        }

        &__button {
            padding: 2em 0;
            @include media($lg) {
                float: right;
                display: inline-block;
                padding: 0;

                .btn {
                    float: right;
                }
            }
        }
    }
}

.news-row-style {
    &__desc {
        margin-bottom: 0;
    }
    .news__meta-list {
        margin-bottom: 0;
    }
}