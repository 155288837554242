fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

input[type="submit"] {
  color: #fff;
  border-radius: 0;
  cursor: pointer;
  border: 2px solid darken($brand-primary, 7%); //background: $brand-primary;
  font-family: $inter-bold;
  outline: 0;
  padding: 7px 13px;
  border: none;
  box-shadow: none;
  &:hover,
  &:active {
    background: darken($brand-primary, 2%);
  }
}

#{$all-text-inputs},
select[multiple] {
  background-color: $input-background-color;
  border: $input-border;
  border-radius: 0;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;
  color: $input-color;
  &:hover {
    border-color: shade($base-border-color, 20%);
  }
  &:focus {
    border-color: $brand-primary;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;
    &:hover {
      border: $base-border;
    }
  }
  &::placeholder {
    color: shade($input-placeholder-color, 25%);
  }
}

textarea {
  resize: vertical;
}

[type="search"] {
  appearance: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

input[type="text"] {
  border: 1px solid #ccc;
  outline: 0;
  box-shadow: none;
}

select {
  margin-bottom: $small-spacing;
  max-width: 100%;
  background: $grey-lighter;
  padding: $small-spacing;
  width: auto;
  outline: 0;
  transition: all 0.3s 0s ease;
  border: 1px solid $grey-lighter;
  &:active {
    box-shadow: none;
    border-color: initial;
  }
  &:focus {
    border-color: $grey;
    box-shadow: none;
  }
  &:hover {
    border-color: $grey;
  }
}

.content-form {
  textarea,
  input[type="text"] {
    border: 2px solid #dadada;
  }
  label {
    display: inline;
  }
  @include media($md) {
    @include span-columns(8.5);
  }
}

.form__section-title {
  padding-top: $base-spacing/2;
  border-top: 1px solid #d8d8d8;
  padding-bottom: $base-spacing/2;
  margin-top: $base-spacing/2;
}

.form-group {
  clear: both;
  @include clearfix;
  padding: 4px 0;
  .form-control--checkboxes {
    label {
      display: inline;
      font-family: $inter;
      font-weight: lighter;
    }
  }
  @include media($md) {
    >label {
      @include span-columns(6);
    }
    .form-control {
      @include span-columns(6);
      @include omega(2n);
      input[type="text"] {
        width: 100%;
      }
    }
    &.form-group--full {
      >label {
        width: 100%;
        float: left;
      }
    }
  }
}