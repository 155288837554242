$navigation-height: 65px;
/* Used to pad the top of the .main content.
   Actual height of nav isnt explicitly defined, determined by the padding on the links in the menu */

$navigation-color: $grey-darker;
$navigation-color-hover: $brand-info;
$navigation-hover: #f7f7f7;
$navArrowBg: #efefef;
$linkPadding: 8px;
.main-nav {
  position: absolute;
  display: none;
  width: 100%;
  top: $navigation-height + 3;
  bottom: 0;
  left: 0; //padding: $base-spacing;
  background: #fff;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; // mobile safari
  z-index: 1;
  &.show {
    display: block;
  }
  @include media($md) {
    // Bump nav off the top by the additional height of the red social bar
    top: $navigation-height + 26px;
  }
  @include media($desktop-menu) {
    display: block;
    position: relative;
    background: none;
    padding: 0;
    overflow-y: visible;
    @include span-columns(9.6);
    @include shift(2.5);
    @include omega();
    top: auto;
  }
} // Main list
.main-nav__list {
  @include media($desktop-menu) {
    margin: 0;
    padding: 0;
    font-family: $inter;
  }
}

.main-nav__list-item {
  @include media($desktop-menu) {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 0;
    float: none;
  }
}

.main-nav__megamenu-list--sub {
  @include media($desktop-menu) {
    display: none;
  }
} // Top level links
.main-nav__list--levelone > .main-nav__list-item {
    padding: 0;
    margin: 0;

    > a {
        font-family: $inter;
        display: block;
        width: 100%;
        font-size: .75em;
        position: relative;
        color: #fff;
        padding: 25px 15px;
        border-radius: 0;
        text-align: center;
        transition: all 300ms;

        .site-header--sticky & {
            padding: 25px 8px 24px;
            // removed sticky styling
            //color: $nav-links;
        }

        &:focus,
        &:hover {
            background: #fff;
            color: $nav-links;
        }

        @include media($xl) {
            font-size: .9em;
        }
    }

    &.current, &.active {
        > a {
            background-color: #fff;
            color: $nav-links;

            .site-header--sticky & {
                //color: #fff;
                // removed sticky styling
                //background-color: #e7e7e7;
            }
        }
    }
    // Treat links differently on homepage
    .body__home & {
        &.current > a {
            background: #504f51;
        }

        > a {
            color: #fff;

            &:focus,
            &:hover {
                background: #504f51;
            }
        }
    }
    // Search item
    &.main-nav__list-item--search {
        display: block;

        @include media($lg) {
            position: absolute;
            top: 0;
            right: 0;
        }
        // Preload hover SVG
        // a:after {
        //     content: url('#{$image-path}/icons/search1.svg');
        //     display: none;
        // }
        > a {
            padding: 20px 25px 19px;
            text-indent: -9999em;
            font-size: 0;

            .site-header--sticky & {
                padding: 20px 10px 18px;

                span {
                    // removed sticky styling
                    //background-color: $nav-links;
                }
            }

            span {
                display: block;
                padding: 16px;
                height: 18px;
                width: 18px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 18px;
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 50%;
                transition: background-color .3s ease-in-out;
                background-image: url('#{$image-path}/icons/png/search3.png');
                background-image: url('#{$image-path}/icons/search3.svg'), linear-gradient(transparent, transparent);
                transition: background-color .2s 0s ease-in-out;
            }



            .body__home & {
                /*background-repeat: no-repeat;
                background-position: center center;
                background-size: 18px;
                background-color: #4e4e50;
                transition: background-color .3s ease-in-out;
                background-image: url('#{$image-path}/icons/png/search3.png');
                background-image: url('#{$image-path}/icons/search3.svg'), linear-gradient(transparent, transparent);

                &:hover,
                &:focus {
                    background-color: lighten(#4e4e50, 10%);
                }*/
            }
        }

        &:hover, .current {
            span {
                background-color: $nav-links;
            }
        }
    }
    // Color nav items differently when any theme is applied.
    // This block is left over from a deprecated feature where there's an option to
    // change the colour of the header bar.
    // TODO: Rmeove anything relying on .theme on the body el.
    @include media($desktop-menu) {
        .theme & {
            // Content: .main-nav__list--levelone > .main-nav__list-item but with .theme as a parent
            &.current > a {
                background: rgba(255, 255, 255, .2);
            }

            > a {
                color: #fff;
                transition: background-color 0.1s 0s ease-in;

                &:focus,
                &:hover {
                    background-color: rgba(255, 255, 255, .4);
                }
            }
            // Search nav item, when .theme is applied
            &.main-nav__list-item--search {
                > a {
                    background-color: #ec3d46;
                    background-color: rgba(255, 255, 255, .2);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 18px;
                    background-image: url('#{$image-path}/icons/png/search3.png');
                    background-image: url('#{$image-path}/icons/search3.svg'), linear-gradient(transparent, transparent);

                    &:hover {
                        background-color: #f5505b;
                        background-color: rgba(255, 255, 255, .5);
                    }
                }
            }
        }
    }
    // Ends 'Theme' modified styles.
}

.submenu-section__link-panel {
  padding-right: 4px;
}

.main-nav__submenu-item {
    position: static;
    list-style: none;
    list-style-type: none;
    font-size: 18px;
    font-size: 1em;
    margin-bottom: 0;

    &.has-children {
        > a {
            position: relative;
            padding-right: 47px;
        }

        &:hover {
            > a {
                color: $brand-primary;
                background: $navigation-hover;
            }

            > .main-nav__submenu {
                display: block;
                //height: auto;
            }
        }
    }
}

.main-nav__submenu-item--view-all {
  font-size: modular-scale(1, .9em);
  margin-bottom: 8px;
  padding-left: 1px;
}

.main-nav__link-list .main-nav__submenu-item {
  font-size: modular-scale(1, 1.2em);
}

.main-nav__submenu-item__link {
    transition: background .2s 0s ease-in-out;
    padding: $linkPadding;

    &.active, &:hover {
        color: $brand-primary;
        background: $navigation-hover;
    }
    // Links inside a nested drop-down
    .main-nav__submenu--nested & {
        padding: 7px 5px;
    }
}

.main-nav__submenu-item .fa {
  font-size: 18px;
  margin-right: 3px;
  font-weight: bold;
  padding: 11px 16px;
  margin-left: 12px;
  position: absolute;
  right: -($linkPadding/2);
  top: 0;
} // Second level
.main-nav__submenu--nested {
  @include clearfix;
  padding: $small-spacing 0;
  padding-left: $small-spacing;
}

.main-nav__view-all {
  color: $brand-primary;
  cursor: pointer;
  margin: 8px 0 12px 0;
}

.main-nav__list--levelone {
  position: relative;
  height: 100%;
  padding: 0 82px 0 0; //@include media($desktop-menu){
  text-align: right;
  @include media($lg) {
      display: flex;
  }
  .site-header--sticky & {
      padding: 0 52px 0 0;
  }
  .body__home & {
    >li>a {
      color: #fff;
    }
  } // }
}

.main-nav__list--leveltwo {
  width: 100%;
  display: none;
  background: #ccc;
  transition: right 0.4s ease-in-out;
  @include media($desktop-menu) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.main-nav__list-item--has-submenu .main-nav__list-icon {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  background: transparent url(#{$image-path}/icons/arrow-right-red.svg) no-repeat center center;
  background-size: contain;
  @include media($md) {
    display: none;
  }
} // Submenus
.main-nav__submenu {
  .js & {
    display: none;
  }
}

.main-nav__list-item--has-submenu {
  position: relative;
} // First level submenus
.main-nav__list-item > .main-nav__submenu {
    text-align: left;
    background: #fff;
    padding: $base-spacing*2;
    list-style: none;
    list-style-type: none;
    font-size: 1em;
    width: 100%;
    //border-top: 2px solid $brand-primary;
    border: 0;
    box-shadow: 0px 10px 16px rgba(52, 52, 52, .4);
    top: 100%;
    z-index: 10;
    max-height: 550px; // fallback height
    max-height: 85vh;

    font-family: $base-font-family;
    &.closing {
        pointer-events: none;
    }
    @include media($desktop-menu) {
        //left: 5px;
        position: absolute;
        right: 0;
        //max-width: 99%;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(50, 50, 50, 0.4);
            border-radius: 6px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 16px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            -webkit-box-shadow: inset 0 0 6px rgba(50, 50, 50, 0.4);
            background-color: $brand-primary;
        }
    }
    // Search submenu
    &.main-nav__submenu--search {
        right: 0;
        left: auto !important;
        width: 650px;
        max-width: auto !important;
        padding: 0;
        overflow: visible;
    }
}

.submenu-section__link-list {
    a {
        display: block;

        &:hover {
            color: $grey-blue;
        }

        &:focus,
        &:active {
            background: $grey-lighter;
            outline: none;
            color: $brand-primary;
        }
    }
}

.main-nav__submenu-link {
  color: $brand-primary;
  font-size: modular-scale(1);
  display: inline;
} // View all link
.main-nav__submenu-link--view-all {
  margin-bottom: 10px;
  padding: 7px;
  font-family: $inter;
  font-weight: bold;
  &:hover {
      color: $brand-primary;
      text-decoration: underline;
  }
}

.main-nav__submenu-title {
  font-size: modular-scale(0, 1.22em);
  padding: 12px 0;
  padding-left: ($linkPadding)+1px;
  margin: 0;
  font-family: $inter-bold;
  .submenu-content & {
    padding-left: 2px;
  }
}

.main-nav__link-list,
.submenu-section__link-list {
  list-style: none;
  list-style-type: none;
  position: relative;
}

.submenu-section__link-panel {
  @include media($desktop-menu) {
    // border-right: 1px solid $grey-lighter;
    border-right: none;
    min-height: 210px;
  }
} // Turn navs on and off
#mobile-menu {
  // Hide mmenu on desktop
  @include media($desktop-menu) {
    display: none;
  }
}

.main-nav {
  display: none;
  @include media($desktop-menu) {
    display: block;
  }
} // Prevent flash of unstyled content before menu loads
.mobile-menu:not(.mm-menu) {
  display: none;
  @include media($desktop-menu) {
    display: none;
  }
} // Overrides from Memnu vendor CSS.
// Starts with mobile styles
// Counter the styles applied inline by Mmenu
#mobile-menu.mm-menu.mm-dropdown {
  box-shadow: none;
  width: 100%;
  max-width: none !important;
  right: auto !important;
  left: auto !important;
  height: 100%;
  background: #fff;
}

.mm-opened {
  //min-height: 100%!important;
}

.mm-navbar a:focus {
  background: none;
  outline: 1px solid $grey;
}

.mm-listview {
  padding-top: $small-spacing;
  .mm-next {
    color: $brand-primary;
    background: $grey-lighter;
  }
  li {
    margin-bottom: 4px;
    &:hover {
      a {
        background: $grey-lighter;
      }
    }
  }
}

.m-panels>.mm-panel {
  padding: 0;
}

.mm-panels {
  overflow: initial;
}

.mm-panels>.mm-panel {
  padding: 0 $small-spacing;
}

.mm-panels>.mm-panel>.mm-listview {
  margin: 0 !important;
  a {
    color: $brand-primary;
    &:focus {
      outline: 0;
    }
  }
}

.mm-listview>li,
.mm-listview>li:after,
.mm-listview>li .mm-next,
.mm-listview>li .mm-next:before {
  border-left: 2px solid #fff;
}

.mm-sronly {
  display: none;
}

.hamburger {
  cursor: pointer;
  float: right;
  margin-top: 8px;
  background: none;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  @include media($desktop-menu) {
    display: none;
  }
}

.main-nav {
  //height: $navigation-height;
  box-shadow: none;
  .mm-listview {
    color: $brand-primary;
    font-size: modular-scale(0);
  }
}

.submenu-section__link-list {
    a:active,
    a:focus {
        outline: 0;
    }
}

.main-nav__list-item {
    .main-nav__submenu {
        > li {
            width: 40%;
        }
    }

    .main-nav__submenu--2 {
        padding-top: 0;
        position: absolute;
        top: 0;
        left: 100%;
        width: 150%;
        height: 100%;
        background-color: #fff;
        .main-nav__submenu-item {
            width: 100%;
        }

        .main-nav__submenu-item__link {
            &:hover {
                color: $brand-primary;
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }

    .main-nav__submenu--4 {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        .main-nav__submenu-item__link {
            font-size: 0.9em;
            color: #707073;
        }
    }
}


.main-nav__submenu-item__link {
    

    &.active {
        + .main-nav__submenu {
            display: block;
            //height: auto;
        }
    }
}

.main-nav__submenu-section {
    list-style: none;

    a {
        color: $brand-grey1;
    }
    // First submenu (news) has more columns
    .main-nav__submenu--news & {
        @include media($desktop-menu) {
        }
    }
} // Submenu content removed from views - 04/18
// .submenu-content {
//     @include span-columns(6.5);
//     @include omega();
//     padding-right: $base-spacing*1.5;
//     p {
//         padding: 6px 0;
//         font-size: modular-scale(1, .8em);
//     }
//     a:hover {
//         color: $grey-blue;
//     }
// }
// .submenu-content__item {
//     @include span-columns(6);
//     @include omega(2n);
//     padding: 0 $small-spacing;
//     a {
//         padding: 3px;
//         display: inline;
//         &:hover {
//             color: $grey-blue;
//             + p a {
//                 color: $grey-blue;
//              }
//         }
//         &:focus, &:active {
//             outline: 0;
//             background: none;
//         }
//     }
// }
/* Nav modal **
Serves as a large click area to deactivate the sub menus when clicked
*/

.overlay-wrap {
  position: fixed;
  top: -5000px;
  left: -5000px;
  right: -5000px;
  bottom: -5000px;
  overflow: auto;
  &:after {
    display: inline-block;
    height: 100%;
    content: "";
  }
}

/* Js adds this class to the body to prevent scrolling whole window while nav is open.
Aids usability to confine scrolling to the nav itself.
*/

.submenu-open {
  //overflow-y: hidden;
  overflow-y: disabled;
}





/*** SIDE NAV ***/
.side-nav {
    &-wrapper {
        @include media($lg) {
            @include span-columns(3);
        }
    }

    &-body-wrapper {
        @include media($lg) {
            @include span-columns(9);
            @include omega();
        }

        .content__header {
            margin-top: 0;

            &-title, &-intro {
                width: 100%;
                margin-right: 0;
            }
        }

        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: .5em 1em;
        border-bottom: 1px solid;

        &:hover {
            color: $brand-primary;
            text-decoration: underline;
        }

        &--top {
            font-size: modular-scale(1);
            color: #fff;
            background-color: $brand-2024-sky3;
            border-bottom: 2px solid $brand-2024-sky2;

            &:hover {
                color: $grey-darker;
            }
        }
    }

    &__list {
        &--1 {
            .side-nav__link {
                background-color: $brand-2024-sky1;
                border-color: $brand-2024-sky2;
            }
        }

        &--2 {
            display: none;

            .side-nav__link {
                padding-left: 2em;
                background-color: #f0f1f2;
                border-color: #d3d7d8;
            }
        }

        &--3 {
            .side-nav__link {
                padding-left: 3em;
                background-color: #fafafb;
                border-color: #e4e6e6;
            }
        }
    }

    &__submenu-item {
        margin: 0;

        i {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 11px 16px;
            font-size: 18px;
            font-weight: bold;
            transition: transform 150ms;
        }

        &.active {
            > .side-nav__link {
                color: #fff;
                background-color: $brand-grey1;

                i {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.has-nav {
    margin-top: $base-spacing*1.5;
    @include clearfix;

    @media all and (max-width: $large-screen) {
        display: flex;
        flex-direction: column;

        .side-nav-body-wrapper {
            order: 1;
        }

        .side-nav-wrapper {
            order: 2;
            display: none;
        }
    }
}