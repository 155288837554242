@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-weight: normal;
    font-display: block;
    src: url('#{$fonts-path}/Inter-Regular.ttf');
}

@font-face {
    font-display: swap;
    font-family: 'Inter-bold';
    font-weight: normal;
    font-display: block;
    src: url('#{$fonts-path}/Inter-bold.ttf');
}


@font-face {
  font-display: swap;
  font-family: 'Chevin';
  font-weight: normal;
  font-display: block;
  src: url('#{$fonts-path}/2452DB_0_0.eot');
  src: url('#{$fonts-path}/2452DB_0_0.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts-path}/2452DB_0_0.woff') format('woff'),
  url('#{$fonts-path}/2452DB_0_0.ttf') format('truetype'),
  url('#{$fonts-path}/2452DB_0_0.svg#wf') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Chevin-bold';
  font-weight: normal;
  font-display: block;
  src: url('#{$fonts-path}/2452DB_1_0.eot');
  src: url('#{$fonts-path}/2452DB_1_0.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts-path}/2452DB_1_0.woff') format('woff'),
  url('#{$fonts-path}/2452DB_1_0.ttf') format('truetype'),
  url('#{$fonts-path}/2452DB_1_0.svg#wf') format('svg');
}

// @font-face {
//   font-display: swap;
//   font-family: 'DIN-Bold';
//   font-display: block;
//   src: url('#{$fonts-path}/DINOT-Bold.eot');
//   src: url('#{$fonts-path}/DINOT-Bold.eot?#iefix') format('embedded-opentype'),
//   url('#{$fonts-path}/DINOT-Bold.woff') format('woff'),
//   url('#{$fonts-path}/DINOT-Bold.ttf') format('truetype'),
//   url('#{$fonts-path}/DINOT-Bold.svg#DINOT-Bold') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-display: swap;
//   font-family: 'DIN';
//   font-display: block;
//   src: url('#{$fonts-path}/DIN-Regular.eot');
//   src: url('#{$fonts-path}/DIN-Regular.eot?#iefix') format('embedded-opentype'),
//   url('#{$fonts-path}/DIN-Regular.woff') format('woff'),
//   url('#{$fonts-path}/DIN-Regular.ttf') format('truetype'),
//   url('#{$fonts-path}/DIN-Regular.svg#DIN-Regular') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-display: swap;
  font-family: 'FontAwesome';
  src: url('#{$fonts-path}/fontawesome-webfont.eot?v=4.7.0');
  src: url('#{$fonts-path}/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
  url('#{$fonts-path}/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
  url('#{$fonts-path}/fontawesome-webfont.woff?v=4.7.0') format('woff'),
  url('#{$fonts-path}/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
  url('#{$fonts-path}/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* PF DIN */
@font-face {
  font: {
    family: 'DIN-Bold';
    weight: bold;
    style: normal;
    display: swap;
  }
  src: url('#{$fonts-path}/pf-din/pfdintextstd-bold-webfont.eot');
  src: url('#{$fonts-path}/pf-din/pfdintextstd-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts-path}/pf-din/pfdintextstd-bold-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/pf-din/pfdintextstd-bold-webfont.woff') format('woff'),
       url('#{$fonts-path}/pf-din/pfdintextstd-bold-webfont.ttf') format('truetype'),
       url('#{$fonts-path}/pf-din/pfdintextstd-bold-webfont.svg#pf_dintext_stdbold') format('svg');
}

@font-face {
  font: {
    family: 'DIN';
    weight: normal;
    style: italic;
    display: swap;
  }
  src: url('#{$fonts-path}/pf-din/pfdintextstd-italic-webfont.eot');
  src: url('#{$fonts-path}/pf-din/pfdintextstd-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts-path}/pf-din/pfdintextstd-italic-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/pf-din/pfdintextstd-italic-webfont.woff') format('woff'),
       url('#{$fonts-path}/pf-din/pfdintextstd-italic-webfont.ttf') format('truetype'),
       url('#{$fonts-path}/pf-din/pfdintextstd-italic-webfont.svg#pf_dintext_stditalic') format('svg');

}

@font-face {
  font: {
    family: 'DIN';
    weight: normal;
    style: normal;
    display: swap;
  }
  src: url('#{$fonts-path}/pf-din/pfdintextstd-reg-webfont.eot');
  src: url('#{$fonts-path}/pf-din/pfdintextstd-reg-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts-path}/pf-din/pfdintextstd-reg-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/pf-din/pfdintextstd-reg-webfont.woff') format('woff'),
       url('#{$fonts-path}/pf-din/pfdintextstd-reg-webfont.ttf') format('truetype'),
       url('#{$fonts-path}/pf-din/pfdintextstd-reg-webfont.svg#pf_dintext_stdregular') format('svg');
}
