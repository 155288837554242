//	Sizing left (default)
@mixin mm_sizing( $cls: "",
	$width: $mm_menuWidth, $minWidth: $mm_menuMinWidth, $maxWidth: $mm_menuMaxWidth
) {
	.mm-menu.mm-offcanvas#{$cls}
	{
		width: percentage( $width );
		min-width: $minWidth;
		max-width: $maxWidth;
	}
	html.mm-opening
	{
		.mm-menu#{$cls} ~ .mm-slideout
		{
			@include mm_translate( percentage( $width ), 0 );
		}
	}

	//	adjust for min- and max-width
	@media all and (max-width: $minWidth / $width ) {
		html.mm-opening
		{
			.mm-menu#{$cls} ~ .mm-slideout
			{
				@include mm_translate( $minWidth, 0 );
			}
		}
	}
	@media all and (min-width: $maxWidth / $width ) {
		html.mm-opening
		{
			.mm-menu#{$cls} ~ .mm-slideout
			{
				@include mm_translate( $maxWidth, 0 );
			}
		}
	}
}

//	Sizing right
@mixin mm_sizing_right( $cls: "",
	$width: $mm_menuWidth, $minWidth: $mm_menuMinWidth, $maxWidth: $mm_menuMaxWidth
) {
	html.mm-right.mm-opening
	{
		.mm-menu#{$cls} ~ .mm-slideout
		{
			@include mm_translate( -( percentage( $width ) ), 0 );
		}
	}

	//	adjust for min- and max-width
	@media all and ( max-width: $minWidth / $width ) {
		html.mm-right.mm-opening
		{
			.mm-menu#{$cls} ~ .mm-slideout
			{
				@include mm_translate( -$minWidth, 0 );
			}
		}
	}
	@media all and ( min-width: $maxWidth / $width ) {
		html.mm-right.mm-opening
		{
			.mm-menu#{$cls} ~ .mm-slideout
			{
				@include mm_translate( -$maxWidth, 0 );
			}
		}
	}
}

//	Sizing top + bottom
@mixin mm_sizing_zposition( $cls: "",
	$height: $mm_menuHeight, $minHeight: $mm_menuMinHeight, $maxHeight: $mm_menuMaxHeight
) {
	.mm-menu.mm-offcanvas#{$cls}
	{
		&.mm-top,
		&.mm-bottom
		{
			height: percentage( $height );
			min-height: $minHeight;
			max-height: $maxHeight;
		}
	}
}
