.search-suggestions--message {
  font-style: italic;
  color: $grey-light;
  padding: $small-spacing 0;
  font-size: modular-scale(2);
}

.results-list {
  .coming-soon-mark {
    float: left;
    margin-top: 6px;
  }
}

.download-list__files {
  list-style: none;
}

// Using linear gradient (below) as multiple background to test for the fallback to PNG also specified.
// Browsers which won't recognise multiple backgrounds also won't support SVG, so use PNG.
.download-list__item {
  &:first-child {
    margin-top: 0;
  }
  word-wrap: break-word;
  font-size: modular-scale(1);
  display: block;
  background-repeat: no-repeat;
  .results-list & {
    padding-top: $small-spacing;
    background-position: 28px 12px;
  }
  @include media($md) {
    font-size: modular-scale(0);
  }
}

.download-list__item-link {
  color: $brand-primary;
  font-size: modular-scale(0, .9em);
  padding: $small-spacing;
  padding-left: $base-spacing*4;
  padding-bottom: $small-spacing/2;
  margin: 0;
  background-repeat: no-repeat;
  background-position: 12px 18px;
  background-size: 35px 35px;
  background-image: url('#{$image-path}/icons/png/file.png');
  background-image: linear-gradient(transparent,
  transparent),
  url('#{$image-path}/icons/file.svg');
  min-height: 85px;
  display: block;
  .download-list__item--image & {
    background-image: url('#{$image-path}/icons/png/image.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/image.svg');
  }
  .download-list__item--link & {
    background-image: url('#{$image-path}/icons/png/link.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/link.svg');
  }
  .download-list__item--pdf & {
    background-image: url('#{$image-path}/icons/png/pdf.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/pdf.svg');
  }
  .download-list__item--video & {
    background-image: url('#{$image-path}/icons/png/watch.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/watch.svg');
  }
  .download-list__item--folder & {
    background-image: url('#{$image-path}/icons/png/folder.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/folder.svg');
  }
  .download-list__item--zip & {
    background-image: url('#{$image-path}/icons/png/zip.png');
    background-image : linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/zip.svg');
  }
}

.download-list__item-title {
  font-family: $inter-bold;
  font-weight: normal;
  margin: 0;
  font-size: modular-scale(1, .7em);
  @include media($md) {
    font-size: modular-scale(1, 1.1em);
  }
}

.download-details {
  color: $grey-darker;
  font-size: 16px;
  clear: both;
  display: block;
}

.download-hub {
  &__title {
    font-size: modular-scale(1, 1em);
    font-weight: bold;
    color: $brand-primary;

    @include padding(null $base-spacing);

    @include ipad-sizing {
      @include padding(null 36px);
      font-size: modular-scale(2.25, 1em);
    }
  }

  &__filelist {
    @include media($md) {
      width: calc(100% + #{$base-spacing});
      @include margin(0 $base-spacing * -0.5);
    }

    @include ipad-sizing {
      @include padding(null $base-spacing * 1.5);
    }

    @supports (display: flex) {
      display: flex;
      flex-wrap: wrap;
    }

    > * {
      width: 100%;
      display: block;

      @include margin(null 0 $base-spacing);
      @include media($md) {
        @include margin(null ($base-spacing * 0.5));
      }

      @supports (display: flex) {
        flex-basis: 95%;
        flex-grow: 1;

        @include media($md) {
          width: 50%;
          flex-basis: 45%;
          flex-grow: 2;
        }

        @include media($lg) {
          width: 33.333%;
          flex-basis: 28%;
          flex-grow: 3;
        }
      }
    }
  }

  &__item {
    $dl-icon-size: 18px;

    max-width: 260px;
    display: block;

    .download-hub__details {
      width: 75%;
      padding-right: $dl-icon-size * 1.5;
      background: {
        position: top right;
        size: $dl-icon-size auto;
        repeat: no-repeat;
        image: url('/images/icons/download-black.svg');
      }

      p {
        font-size: 0.75em;
        margin-bottom: $base-spacing * 0.25;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__files {
    padding: $base-spacing * 1.5;
    background-color: $brand-2024-sand2;
    transition: background-color 0.3s ease;
    &:hover {
    background-color: $brand-2024-sand3;
/*      .download-hub__details {
        background-image: url('/images/icons/download-black.svg');
      }*/
    }
  }
}

.search-panel__form {
  position: relative;
  padding:20px;
  margin: 0px 50px;
  background-color: $brand-2024-sky1; /*#f6f6f6;*/
  height:80px;
}

input[type="search"],
input.search-input {
  background-color: $brand-2024-sky1; /*#f6f6f6;*/
  background-image: url('#{$image-path}/Nav/search-icon-dark.svg'); //background-image:   linear-gradient(transparent, transparent), url('#{$image-path}/icons/search2.svg');
  /*margin-top: 12px;*/
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  /*color: $grey-blue;*/
  /*border: 1px solid $grey-lighter;*/
  border-bottom:solid 1px black;
  font-weight: bold;
  font-style: italic;
  outline: 0;
  position: relative;
  /*padding: 14px;*/
  font-weight: normal;
  box-shadow: none;
  font-size: modular-scale(1, .9em);
  border-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s 0s ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  min-width: 200px;
  @include media($md) {
    margin-top: 0;
  }
  &::-webkit-search-cancel-button {
    position: absolute;
    -webkit-appearance: none;
    height: 0px;
    width: 0px;
    border-radius: 10px;
    right: -20px;
    transition: all 0.6s 0s ease;
    opacity: .5;
    z-index: 5;
    background: none; //background: transparent url('#{$image-path}/icons/cancel.svg') no-repeat center center;
    //background-size: 20px;
  }
  &::-ms-clear {
    margin-right: 20px;
  }
  &::placeholder {
    color: $grey-light;
  }
  &:active {
    box-shadow: none;
    border-color: initial;
  }
  &:focus {
    border-color: $grey;
    box-shadow: none;
  }
  &:hover {
    border-color: $grey;
  }
  &:not(:valid)~.search-clear-icon {
    display: none;
  }
  .main-nav__searchform & {
    background-color: #f6f6f6;
    background-image: none;
  }
  .search-panel & {
    width: 100%;
    float: left;
    margin-right: 0;
  }
}

input::-ms-clear {
  display: none;
  opacity: 0;
  appearance: none;
}

// 'Clear input' button on search fields
.close-icon {
  display: none;
  border: 1px solid transparent;
  background-color: transparent;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  opacity: .4;
  right: 24px;
  top: 18px;
  height: 20px;
  width: 20px;
  position: absolute;
}

.close-icon:after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background: transparent url('#{$image-path}/icons/cancel.svg') no-repeat center center;
  z-index: 1; //right: 18px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  cursor: pointer;
  position: absolute;
}

.search-input-wrap {
  position: relative;
  clear: both;
  >label {
    position: absolute;
    top: -23px;
    font-size: 15px;
  }
  input[type="submit"]:hover,
  input[type="submit"]:active {
    background-color: transparent;
  }
}

.search-box:not(:valid)~.close-icon {
  display: none;
}

.search-suggestions {
  clear: both;
  font-size: modular-scale(0);
  @include media($md) {
    padding-left: 4em;
  }
  .btn {
    clear: both;
    display: block;
  }
}

.search-suggestions--message {
  font-size: modular-scale(1, 1em);
}

.search__suggestions__list {
  @include clearfix;
  list-style: none;
  margin-bottom: $small-spacing;
}

.search__suggestions__list-item {
  margin-bottom: $small-spacing;
  a {
    color: $brand-primary;
    &:hover {
      color: darken($brand-primary, 10%);
    }
  }
  font-size: 18px;
  @include media($lg) {
    @include span-columns(6);
    @include omega(2n); // Results as shown in the nav drop-down
    .main-nav__submenu & {
      font-size: 1em;
      //float: none;
    }
  }
}






// Search form on 'Press Release' page.
.news-options .black-outline-form {
    /*background-color:hotpink!important;*/
    form {
        height: 40px;
    }

    input [type="search"], input.search-input {
        background-color: white;
        border: solid 1px black;
    }

    .search-panel__form {
        margin: 0px;
        padding: 0px;
        background-color: white;
    }

    .news-options__selection {
        margin: 0px;
        padding: 0px;
        height:40px;
        background-color: white;
        border: solid 1px black;
    }
}







// Search results as shown in the nav drop-down
// ( .search-suggestions over-ride for when this component is used in the global search context at /Search )
.nav-results {
    @include clearfix;
    padding: $base-spacing*1.8;
    padding-top: $base-spacing;
    padding-bottom: $base-spacing*2;
    padding-left: 60px;

    .search-suggestions & {
        padding-left: 0;
        padding-top: 0;
    }
}

.nav-results__title {
  font-size: 1em;
  padding: 6px 0;
  .search-suggestions & {
    font-size: modular-scale(0);
  }
}

input.main-nav__search-input {
  font-size: modular-scale(1 + .2);
  margin: 0;
}

.main-nav__searchform {
  margin-bottom: 0
}

.search-panel {
  @include media($md) {
    @include outer-container(80vw);
    padding: $base-spacing 0;
  }
  @include media($lg) {
    @include outer-container(70vw)
  }
}

.no-results {
  clear: both;
  font-weight: bold;
  padding: $small-spacing 0;
  @include media($md) {
    padding: $base-spacing 0;
  }
}

.searched {
    float: left;
    width: 100%;
  padding: $small-spacing 0;
  p {
    margin: 0;
  }
  & + .btn {
      float: left;
  }
}

// Search results in Navigation
.main-nav__search-submit[type="submit"] {
  float: left;
  outline: 0;
  text-indent: -999em;
  width: 55px;
  height: 55px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px;
  cursor: pointer;
  border: 0;
  background: transparent url('#{$image-path}/icons/search2.svg') no-repeat 12px center;
  background-size: 25px;
  &:hover {
    background: transparent url('#{$image-path}/icons/search2.svg') no-repeat 12px center;
    background-size: 25px;
  }
}

// Search results page filtesrs
.roundel {
  display: inline-block;
  vertical-align: middle;
  background-color: $brand-grey2;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-family: $inter-bold;
}

.filter-off {
  background: #8495a6;
}
