img {
  margin: 0;
  max-width: 100%;
}

// Download page
.download-view {
  @include outer-container();
}

.download-link-plain {
  word-break: break-word;
  padding-top: $base-spacing;
  a {
    color: $brand-primary;
  }
}

.download__preview {
  position: relative;
  padding-bottom: 40%;
  padding-top: 40px;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 350px;
  }
  @include media($md) {
    @include span-columns(6);
  }
  iframe {
    //max-width: 100%;
    //margin: 0 auto;
    //min-height: 30vw;
    @include media($md) {
      //min-height: 270px;
      // width: 100%;
    }
    @include media($lg) {
      // text-align: left;
      // width: 100%;
      // padding-top: 0;
    }
  }
}

.download__embed {
  @include media($md) {
    @include span-columns(6);
  }
  @include media($lg) {
    margin: $base-spacing 0;
    margin-top: 0;
  }
  color: $brand-primary;
  background: $grey-lighter;
  padding: $base-spacing;
  padding-bottom: 1em;
  text-align: center;
  p {
    clear: both;
  }
  .copied-confirm {
    padding-top: $small-spacing;
  }
  input[type="textbox"] {
    font-size: modular-scale(1);
    border: 2px solid $grey;
    transition: all 0.2s 0s ease-in-out;
    padding: 12px;
    display: block;
    width: 100%;
    outline: 0;
    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      outline: 0;
      border-color: darken($grey, 20%);
    }
    &:focus {
      border-color: $brand-primary;
    }
  }
}

.download__embed.download__embed--link {
  @include media($lg) {
    @include span-columns(10);
  }
}

.copy-embed {
  .fa {
    padding-left: $small-spacing;
  }
}

// Large media component
.large-media {
    @include outer-container;
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
}

.large-media__wrap {
  margin: 0;
}

.large-media__media {
  margin-bottom: $small-spacing;

  @include media($md) {
    @include span-columns(8);
  }
}

.large-media__content {
  @include media($md) {
    padding: 0;
    @include span-columns(4);
    @include omega();
  }
  @include media($lg) {}
}

.large-media__description {
  @include media($lg) {
    padding: $small-spacing;
    padding-top: $base-spacing;
    position: relative;
  }
}

.large-media__title {
    @include media($lg) {
        position: relative;
        padding-left: ($base-spacing*2.5);
        margin-bottom: $base-spacing;
        left: -($base-spacing*2.5);
        font-size: modular-scale(0);
        //top: $base-spacing;
        z-index: 10;
        + p {
            float: left;
            width: 100%;
        }
    }
}

.larger-media {
  max-width: none;

  @include margin(0 null);
  @include padding(0 null);

  .large-media__media {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;

    .media-container {
      @include padding(null 0);
    }
  }

  .large-media__wrap {
    pointer-events: none !important;
    transition: opacity 0.3s ease;
    opacity: 1;

    .larger-media__content {
      $larger-padding: $base-spacing * 1.5;

      top: 0;
      left: 0;
      width: 100%;
      padding: $larger-padding;

      @include media($sm) {
        display: none;
      }

      @include media($lg) {
        padding-top: $larger-padding * 1.5;
      }

      // &,
      // & > * {
      //   color: #000;
      // }

      .larger-media__title {
        margin-bottom: $base-spacing * 0.33;
        font-size: modular-scale(1);
        font-weight: bold;

        @include media($sm) {
          font-size: modular-scale(3);
          max-width: 50%;
        }

        @include media($md) {
          max-width: 75%;
        }

        @include media($xl) {
          font-size: modular-scale(4);
          max-width: 50%;
        }
      }

      .larger-media__description {
        @include media($sm) {
          max-width: 50%;
        }
      }
    }

    &--float {
      .container--inner {
        position: relative;
      }

      .larger-media__content {
        position: absolute;
        // padding: $base-spacing * 2;

        &,
        & > * {
          color: #fff;
        }

        @include media($sm) {
          display: block;
        }

        .larger-media__description {
          display: none;
        }

        .larger-media__title,
        .larger-media__description {
          @include media($sm) {
            display: block;
          }
        }
      }
    }

    &--after {
      .larger-media__title {
        display: none;
      }
    }
  }

  iframe.video-active ~ .large-media__wrap {
    opacity: 0;
  }
}

// Handle iframe videos
.media__iframe {
  position: relative; // > div {
  //     position: relative!important;
  // }
  iframe {
    width: 100%;
    height: 350px;
    height: 55vw;
    @include media($md) {
      height: 450px;
      height: 40vmax;
      max-height: 500px;
    } // Vimeo fullscreen class
    .js-player-fullscreen {
      position: absolute;
    }
  }
  +img {
    display: none;
  }
}

.media-container {
  position: relative; //padding-bottom: 56.25%;
  //padding-top: 30px;
  padding: 20px; //height: 0;
  overflow: hidden;
  .gallery-item & {
    p {
      display: none;
    }
  }
  iframe,
  object,
  embed {
    //position: absolute;
    top: 0;
    margin-bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.share-tooltip__list {
  text-align: center;
  max-width: 100%;
  li {
    display: inline;
  }
  .fa {
    padding: 3px 8px;
    color: $brand-yellow;
  }
  a:hover {
    .fa {
      color: #fff;
    }
  }
}

// Social tooltip on cards
.share-tooltip {
  a:focus,
  a:active {
    outline: 0;
    background: none;
    .fa {
      color: #fff;
    }
  }
}

.jBox-Tooltip {
  .jBox-container {
    background: $grey;
    background: rgba(64, 64, 68, .7);
    box-shadow: none;
  }
  .jBox-pointer:after {
    background: $grey;
    background: rgba(64, 64, 68, .7);
  }
}

// Youtube thumbs
.media-container {
  // min-height: 320px;
  // width: 100%;
  position: relative;
  cursor: pointer;
  padding-bottom: 56.3%;
  padding-top: 0px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 12px;
  }
}

// .slick-lightbox-iframe {
//     position: relative;
//     width: 100%;
//     height: 0px;
//     margin: 0 auto;
//     padding-bottom: 60%;
//     @include media($md) {
//         max-width: 80%;
//     }
//     iframe {
//         position: absolute;
//         left: 0px;
//         top: 0px;
//         width: 100%;
//         height: 100%;
//     }
// }
// Hide the thumbnail once the video iframe receives a class
// .video-active + .video-thumb {
//     opacity: 0;
//     visibility: hidden;
// }
// .video-inactive + .video-thumb {
//     opacity: 1;
//     visibility: initial;
// }
/* Position Video play button with Flexbox, except ie9 which forces /*
percentage position on ::after element
 */

.video-thumb {
  &.playing {
    opacity: 0;
    visibility: hidden;
    transition: all .5s .5s ease-in-out;
  }
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s 0.3s ease-in-out;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.video-thumb--sm {
    // video thumbs for 'small gallery' only
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: center center;
    @include media($sm) {
      background-size: cover;
    }
  }
  .lt-ie9 & {
    position: absolute;
    display: block;
  }
  &:after {
    content: '';
    display: block; //position: absolute;
    //top: 33%;
    //left: 38%;
    width: 80px;
    height: 80px;
    background-image: url('#{$image-path}/icons/png/play-button.png');
    background-image: url('#{$image-path}/icons/play-button.svg'),
    none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    @include media($sm) {
      //   left: 40%;
      //   top: 38%;
    }
    @include media($md) {
      //   left: 41%;
      //   top: 40%;
    }
    @include media($lg) {
      //   left: 42%;
      //   top: 42%;
    }
    .lt-ie9 & {
      top: 40%;
      left: 40%;
      display: block;
      position: absolute;
    }
  }

  &.video-thumb--red:after {
    background-image: url('#{$image-path}/icons/png/play-button-red.png');
    background-image: url('#{$image-path}/icons/play-button-red.svg'),
    none;
  }
}

// Media icons
// In 'cards' and on Home page slider, all specified here
.media-indicators {
  margin-bottom: $small-spacing*1.5;
  @include clearfix;
  .media-indicator__icon {
    background: #333;
    background: rgba(0, 0, 0, 0.6);
    padding: 6px;
    float: left;
    transition: all .5s 0s ease-in-out;
    text-align: center;
    margin-right: 8px;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 20px;
  }
  .suggestion-grid--small-items & {
    img {
      width: 16px;
    }
  }
  &.media-indicators--news-card {
    margin: $small-spacing 0;
    margin-top: 0;
    @include media($lg) {
      position: absolute;
      margin: 0;
      bottom: 0;
      left: 0;
    }
  }
  &.media-indicators--title {
    margin-top: $small-spacing;
  }
  &.media-indicators--sml-card {
    @include media($md) {
      position: absolute;
      bottom: 2px;
      margin-bottom: 0;
    }
  }
  &.media-indicators__subtitle {
    display: none;
    margin: 0;
    margin-bottom: 4px;
    float: left;
    @include media($lg) {
      display: inline;
      float: left;
    }
    .media-indicator__icon {
      margin-right: 6px;
    }
  }
}

// Aspect ratio mixin
@mixin aspect-ratio($width,
$height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  >img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// figure
figure {
  margin: 0;
  overflow: hidden;
  padding: 0;
  &.fourbythree {
    @include aspect-ratio(4,
    3);
  }
  &.sixteenbynine {
    @include aspect-ratio(16,
    9);
  }
  &.square {
    @include aspect-ratio(1,
    1);
  }
}
