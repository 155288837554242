// Article


.article__meta {
    clear: both;
    @include media($md){
        margin-bottom: 4px;
    }
}

.article__meta--footer {
    padding-bottom: $small-spacing;
}

.article-tags--footer {
    font-size: modular-scale(0, 1.1em);
}

.article__meta-item {
    list-style: none;
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    list-style-position: inside;
    color: $grey-dark;
    font-size: 17px;
/*    &::after {
    content: '  \25CF ';
    padding: 0 14px;
    color: $grey-dark;
  }*/
}

    .article__meta-item--share {
        .no-js & {
            display: none;
        }
        &::after {
        display: none;
        }
        a {
            font-weight:800;
            margin-left:30px;
            transition: none;
            /*background-image: url('#{$image-path}/icons/png/share.png');
            //background-image:  linear-gradient(transparent, transparent), url('#{$image-path}/icons/share.svg');
            background-repeat: no-repeat;
            background-position: right 4px;
            background-size: 16px;
            padding-right: 35px;
            &::after {
                content: url('#{$image-path}/icons/png/share-alt.png');
                display: none;
            }*/
            &:hover {
                 color: $brand-primary;
                 background-image: url('#{$image-path}/icons/png/share-alt.png');
                 //background-image: linear-gradient(transparent, transparent), url('#{$image-path}/icons/share-alt.svg');

                }
        }
    }

    .article__meta-item-link {
        color: $grey-dark;
        @include link-states($brand-primary);
    }


.content__header--article {
    @include media($md) {
         padding-top: 0;
    }
}


.content__header-panel {
    margin-top: $base-spacing;
    @include outer-container(12);
    @include clearfix;
}

.content__header-image-wrap {
  margin-bottom: $small-spacing;
  height: 190px;
  overflow: hidden;
  position: relative;
    @include media($md) {
        margin-bottom: 0;
        overflow: auto;
        height: auto;
        @include span-columns(4);
    }
    @include media($lg) {
        @include span-columns(3);
    }
}

.content__header-main {
  @include media($md){
      @include span-columns(8);
      @include omega();
      display: flex;
      flex-direction: column;
   }
    @include media($lg) {
        @include span-columns(9);
        display: flex;
        flex-direction: column;
    }

    .article__meta, .media-indicators {
        .js & {
           // opacity: 0;
            transition: opacity .2s 0s ease-in;
        }
        .js-heights-matched & {
            //opacity: 1;
        }
    }
}


.article__header {
    padding: $base-spacing 0;
    margin-bottom: $base-spacing;
    border-bottom: 1px solid $grey-lighter;
    padding-top: $base-spacing;
    @include clearfix;
}

.article__header-title {
    font-size: modular-scale(3);
    font-weight: normal;
    color: $brand-primary;
    margin-bottom: $small-spacing/1.6;
     @include media($md){
        font-size: modular-scale(3);
    }
     @include media($lg){
        font-size: modular-scale(3, 1.2em);
    }
}

.article__header-intro {
    margin: 0;
     @include media($md){
        @include span-columns(8);
    }
}



// Article structure.
// 04/18 - Articles are single column until $lg breakpoint since Components now need the space to be in the main content column

.article__summary {
    word-wrap: break-word;
    padding-bottom: $base-spacing;
    // @include media($md) {
    //     @include span-columns(3);
    // }
    @include media($lg) {
        @include span-columns(3);
    }
}

.article__main {
    // @include media($md) {
    //     @include span-columns(9);
    //     @include omega();
    // }
    @include media($lg) {
        @include span-columns(9);
        @include omega();
    }

    &:only-child {
      @include margin(null auto);
      float: none;
      clear: both;
    }

    // If components are added to a News page, remove their padding
    .container,
    .container--compact {
        padding: 0;
        max-width: none;
    }
}

.article__subtitle {
    font-size: modular-scale(2, 1.2em);
    margin-bottom: 0;
    font-weight: normal;
    color: $brand-primary;
    padding-bottom: $small-spacing;
}

// Article side panel

.article__tags {
    margin-bottom: $base-spacing;
}

.article__tag {
    margin: 4px 0;
    margin-right: 4px;
    display: inline;
    font-size: 0.9em;
}

    .article__tag-link {
        color: $brand-primary;
        font-weight: 800;
        @include link-states($brand-primary);
    }

    .article__tag--active {
        color: $brand-primary;
        @include link-states($brand-primary);
    }

.article__key-points {
    p {
        clear: both;
    }
}

.article__key-points-title {
    float: left;
/*  background: $brand-primary;
    color: #fff;
    padding: ($base-spacing/2) ($base-spacing/1.3);
    padding-top:($base-spacing/1.9);
    text-transform: uppercase;
*/    
    font-size: 1.6em;
    font-weight:600;
    margin-bottom: $base-spacing;
}

.article__key-points-list {
    clear: both;
    font-weight: lighter;
    ul {
        padding-top: $small-spacing;
        li {
            font-size: 15px;
        }
    }
    @include media($md){
        padding-right: $small-spacing;
    }
}


/* Article content can be added as a component outside of the main .article itself.
   This component is where Rich Text goes and so also has the class .editor-content to control that.
   Subsequent rich text components get the modifier --component to move it to the right.
*/
.article__content--component {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.article__content {
    font-weight: lighter;
    line-height: 1.7;
}

.article__main > .article__content > p:first-child {
    font-weight: bold;
}


.article-footer {
    border-top: 1px solid $grey-lighter;
    padding: $small-spacing;
    @include media($md){
        padding: $base-spacing 0;
    }
     @include media($lg){

    }
}
