.highlight {
  &__title {
    @include padding(null $base-spacing * 0.66);
    margin-bottom: $base-spacing * 0.33;
    font-weight: bold;
    color: $brand-primary;

    @include media($sm) {
      @media screen and (orientation: portrait) {
        margin-bottom: $base-spacing * 0.66;
      }
    }

    @include media($lg) {
      margin-bottom: $base-spacing * 0.66;
    }
  }

  &__wrapper {
    width: 100%;
    display: block;

    @include outer-container($x-large-screen);

    @include media($lg) {
      display: table;
    }

    .container {
      border: {
        style: solid;
        width: 0 ($base-spacing * 1.5) 0 ($base-spacing * 1.5);
        color: transparent;
      }

      &--inv {
        @media screen and (max-width: $small-screen - 1) {
          margin: 0;
          padding: 0;
          border-width: 0;
        }

        @include media($lg) {
          width: 486px;
          border-right-width: 0;
          box-sizing: content-box;
        }
      }

      @include media($lg) {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  &__hero {
    position: relative;
    margin-bottom: $base-spacing;

    @include media($sm) {
      width: 50%;

      @media screen and (orientation: portrait) {
        width: 60%;
        margin-bottom: $base-spacing * 2;
      }
    }

    @include media($lg) {
      width: 100%;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
      max: {
        width: 486px;
        height: 456px;
      }
    }

    .highlight__image {
      height: auto;
      width: 100%;
      min-height: 100px;
      margin: 0;
      padding: 0;
      display: block;
    }

    &__content {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: -moz-linear-gradient(top, rgba($brand-primary, 0) 0%, rgba($brand-primary, 0.33) 100%);
      background: -webkit-linear-gradient(top, rgba($brand-primary, 0) 0%, rgba($brand-primary, 0.33) 100%);
      background: linear-gradient(to bottom, rgba($brand-primary, 0) 0%, rgba($brand-primary, 0.33) 100%);
    }
  }

  &__details {
    $border-offset: $base-spacing * 0.5;
    $border-width: 2px;

    border-left: solid $border-width $brand-primary;
    margin: $base-spacing 50px;
    padding-left: $border-offset;
    color: #fff;
    font-size: 0.8em;

    &__icon {
      $icon-size: 16px;

      position: absolute;
      top: $base-spacing;
      // left: calc((#{$border-offset} * -1.66) - #{$icon-size});
      left: $base-spacing * 0.5;
      left: calc(((#{$border-offset} + (#{$icon-size} * 1.5)) / 2) + (#{$border-width} * 4));
      height: $icon-size;
      width: $icon-size;
      display: block;
    }

    &__title {
      max-width: $medium-screen;
      font-weight: bold;
    }
  }

  &__content {
    &__title {
      font: {
        size: modular-scale(1, 1em);
        weight: bold;
      }

      @include media($sm) {
        width: 50%;

        @media screen and (orientation: portrait) {
          width: 60%;
          font-size: modular-scale(2.25, 1em);
        }
      }

      @include media($lg) {
        font-size: modular-scale(2.25, 1em);
        width: auto;

        @media screen and (orientation: portrait) {
          width: 75%;
        }
      }
    }

    &__body {
    }

    &__cta {
      .btn {
        padding-right: $base-spacing * 1.5;
        margin-top: $base-spacing;
        display: block;

        @include media($sm) {
          display: inline-block;
        }
      }
    }
  }
}
