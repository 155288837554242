.body__home {
  @include media($desktop-menu) {
    .main-content {
      position: relative;
    }
  }
}

.page__home {
  .main-content {
    padding-top: 0;
  }
}

// Slider
.hero {
  background: #fff;
  display: block;
  position: relative;
  @include media($md) {
    //margin-top: -72px;
  }
}

.hero__item {
  // Hide until JS initialised
  display: none;
  background-size: cover !important;
  @include media($md) {
    background-size: cover !important;
  }
  &:first-child {
    display: block;
  }
  .slick-initialized & {
    display: block;
  }
  @include iefix();
  position: relative;
  height: 340px;
  margin-bottom: 0;
  .container {
    height: 100%;
    @include media($md) {
      position: relative;
    }
  }
  @include media($sm) {
    height: 460px;
    max-height: 720px;
  }
  @include media($md) {
    height: 650px;
    height: 100vh;
    max-height: 720px;
  }
}

// Video slider
.hero__item--iframe {
  iframe {
    position: absolute;
    width: 140%;
    height: 100%;
    margin: 0;
    top: 0;
    left: auto;
    right: auto;
    display: none;
    @include media($md) {
      display: block;
      width: 100%;
      height: 130%;
    }
    @include media($lg) {
      height: 160%;
      top: -100px;
    }
  }
}

.hero__item--video-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
  background-size: cover;
  height: 100%;
  width: 100%;
  display: none;
  @include media($md) {
    display: block;
  }
}

.carousel-gradient {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.0) 40%,rgba(0, 0, 0, 0.7) 70%) no-repeat center center;
  background-size: cover
}

.hero__content {
  @include iefix();
  bottom: 0;
  padding: $base-spacing;
  padding-top: $navigation-height*1.8;
  padding-bottom: 3em;
  color: #fff;
  opacity: 0;
  transition: all .3s ease-in-out;
  transition-delay: 1s;
  position: absolute;
  @include media($md) {
    @include span-columns(8);
    padding: 0;
    bottom: 10%;
  }
  @include media($lg) {
    @include span-columns(10);
    padding: 20px 0;
    bottom: 4%;
  }
}

.slick-active .hero__content {
  opacity: 1;
}

.hero__content-link {
  float: left;
  cursor: pointer !important;
  &:focus {
    outline: 0;
    border: 0;
    background: transparent;
  }
}

.hero__title {
  color: #fff;
  font-size: 32px;
  font-size: 5vw;
  @include media($sm) {
    font-size: 25px;
    font-size: 4vw;
  }
  @include media($md) {
    font-size: 25px;
    font-size: 4vw;
  }
  @include media($lg) {
    padding-top: 50px;
    font-size: 37px;
  }
  display: inline;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  .hero__content-link:hover & {
    border-bottom-color: white;
  }
}

.hero__subtitle {
  margin-top: 16px;
}

.hero__subtitle--text {
  margin-bottom: 5px;
  font-size: modular-scale(-1);
  color: #fff;
  @include media($sm) {
    font-size: modular-scale(0);
  }
  @include media($md) {
    font-size: modular-scale(1);
    padding-right: $base-spacing*2;
  }
}

.hero__meta {
  font-size: modular-scale(0);
  color: #fff;
  border-top: 1px solid #9e9e9e;
  padding: 6px 0;
  @include media($md) {
    clear: both;
    padding-right: $base-spacing;
    margin-top: 12px;
    float: left;
  }
}

// Mobile and desktop image behaviours. Showing and hiding smaller images.
img.hero__image {
  display: none;
  width: 100%;
  @include media($md) {
    display: block;
  }
}

img.hero__image.hero__image--mobile {
  display: block;
  @include media($md) {
    display: none;
  }
}

// Slick slider dots overrides
.slick-dots {
  max-width: 80%;
}

.hero__dots {
  position: absolute;
  width: 100%;
  bottom: 40px;
  padding-left: $base-spacing;
  z-index: 10;
  @include media($md) {
    bottom: 26px;
  }
  @include media($lg) {
    max-width: 100%;
    padding-left: $small-spacing;
  }
  li {
    float: left;
    margin-right: 12px;
    margin-left: 0;
    @include media($md) {
      margin-right: 5px;
    }
  }
  .container {
    width: 100%;
    position: relative;
    @include media($md) {
      padding-left: 0
    }
  } // Override 'font size' of bullet character. Defined first in Slick Theme
  .slick-dots {
    bottom: 0;
    li button:before {
      font-size: 14px;
      @include media($md) {
        left: 5px;
        bottom: 10px;
      }
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

// Trending in News card
.trending-news {
  @include media($lg) {
    margin-bottom: 0;
  }
}

.trending-news__panel {
  clear: both;
  padding-top: $base-spacing/2;
  @include clearfix;
}

.trending-news__image {
  @include media($md) {
    @include span-columns(6);
    margin-bottom: 12px;
  }
  @include media($lg) {
    @include span-columns(6.5);
  }
}

.trending-news__content {
  padding: $small-spacing;
  @include media($sm) {
    padding: $base-spacing;
    padding-top: $small-spacing;
  }
  @include media($md) {
    @include span-columns(6);
    @include omega();
    padding: $base-spacing*2;
    padding-top: $small-spacing;
  }
  @include media($lg) {
    @include span-columns(5.5);
    @include omega();
    padding: $base-spacing*2;
    padding-top: $small-spacing;
  }
}

.trending-news__title {
  font-family: $inter;
  color: $brand-primary;
  font-size: modular-scale(1, 1.2em);
  @include media($lg) {
    font-size: modular-scale(1, 1.3em);
  }
}

.trending-news__intro {
  font-size: 19px;
}

.bg-photo {
  padding-top: 0;
  @include clearfix;
  @include media($lg) {
    margin: $base-spacing/2 auto;
    margin-top: $base-spacing;
  }
}

.bg-photo__container {
  @include outer-container(1200px);
  @include media($lg) {
    padding: 0 16px;
    display: table;
    max-height: 400px; //overflow: hidden;
    // only apply gird for $lg
    @supports (display: grid) {
      display: grid;
      max-height: none;
      grid-template-columns: 1fr 2fr 1px;
    }
  }
}

.bg-photo__figure {
  height: 260px;
  overflow-y: hidden;
  @include media($sm) {
    height: 300px;
  }
  @include media($lg) {
    overflow: hidden;
    position: relative;
    max-height: auto;
    display: table-cell;
    width: 50%;
    @include clearfix;
    @supports (display: grid) {
      .bg-photo__image--img {
        display: none;
      }
      @include grid-reset; //min-height: 300px;
      height: 420px;
      grid-column: 1/2;
    }
  }
}
.bg-photo__image--img {
  max-width: 100%;
  @include media($lg) {
    position: absolute;
    @supports (display: grid) {
      @include grid-reset;
    }
  }
}

.bg-photo__content {
  padding: $base-spacing;
  padding-bottom: 1em;
  background: $off-white;
  @include media($lg) {
    position: relative;
    display: table-cell;
    width: 50%;
    vertical-align: top;
    padding: $base-spacing*2;
    p {
      font-weight: normal;
      &:last-of-type {
        margin-bottom: $base-spacing;
      }
    }
    @supports (display: grid) {
      display: grid;
      @include grid-reset;
      grid-template-rows: 1fr minmax(20px, 135px);
      border-top: 0;
      grid-column: 2/end;
      padding: $base-spacing*2;
    }
  }
}

.bg-photo__cta {
  grid-row: end;
  margin: 1em 0;
  .btn {
      display: inline-block;
  }
}

.bg-photo__title {
  font-size: modular-scale(1, 1.3em);
  @include media($md) {
    font-size: modular-scale(2, 1em)
  }
  @include media($lg) {
    font-size: modular-scale(2, 1.3em)
  }
}

.bg-photo__copy {
  font-size: 19px;
  margin-bottom: $base-spacing;
  @include media($lg) {
    min-height: 110px;
    @supports(display: grid) {
      margin-bottom: 0;
      padding-right: $base-spacing*2.5
    }
  }
}

// Media block
.media-block {
  padding-top: $base-spacing;
}

.media-block__list {
  list-style: none;
}

.media-block__title {
  margin-bottom: $small-spacing/2;
  @include media($lg) {
    font-size: modular-scale(2, 1.3em)
  }
}

.media-block__item {
  margin-bottom: $base-spacing*1.5;
  overflow: hidden;
  @include media($sm) {
    @include span-columns(6);
    @include omega(2n);
  }
  @include media($md) {
    @include span-columns(5.8);
    @include omega(2n);
    &:nth-child(2n) {
      float: right;
    }
  }
  @include media($lg) {
    overflow: initial;
  }
}

.media-block__link {
  display: block;
}

.media-block__img {
  @include media($md) {
    @include box-shadow(); // margin-top: -100px;
  }
  @include media($lg) {
    margin-top: initial;
  }
}

.js-download-link {
  @include link-states($brand-primary);
}

.media-block__email a {
  word-wrap: break-word;
  color: $brand-primary;
  font-family: $inter;
  @include link-states($brand-primary)
}

.media-block__content {
  @include media($lg) {
    @include span-columns(4);
  }
}

.media-block__intro {
  font-size: 19px;
}

.media-block__popular {
  @include media($md) {
    //padding-top: 27px;
  }
  @include media($lg) {
    @include span-columns(7.5);
    @include shift(.5);
    @include omega();
  } // Moves 'view downloads' button to baseline
  .btn {
    @include media($lg) {
      margin-top: 12px;
      display: inline-block;
    }
  }
}

.media-block__popular-title {
  //font-size: modular-scale(1 + 0.3);
  padding-right: $base-spacing;
  @include media($sm) {
    float: left;
  }
  @include media($md) {
    //font-size: 27px;
  }
}

.media-block__name {
  font-size: modular-scale(1);
  padding-left: 40px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url('#{$image-path}/icons/png/download_sm.png');
  background-image : linear-gradient(transparent,
  transparent),
  url('#{$image-path}/icons/png/download.png');
  background-position: 0 center;
  background-size: 30px;
  font-family: $inter;
  font-weight: bold;
  @include media($lg) {
    font-size: 22px;
  } // For different parent selector
  .media-block__item--embed & {
    background-image: url('#{$image-path}/icons/png/code.png'); // background-image: linear-gradient(transparent, transparent), url('#{$image-path}/icons/code.png');
    background-size: 27px;
    background-position: 0 center;
  }
}

.media-block__details {
  font-size: modular-scale(-1);
}

.media-block__tel {
  line-height: 1;
}

.media-block__info {
  p {
    margin: 3px 0;
  }
  position: relative;
  padding: $small-spacing 0;
  padding-bottom: $small-spacing/2;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    background: $brand-primary;
    width: 15%;
    bottom: 0;
    left: 0;
  }
}

.media-block__media {
  padding-top: $base-spacing;
  @include clearfix;
}

.media-block__meta {
  margin-bottom: $base-spacing;
  p {
    margin: 0;
    font-family: $inter;
    font-size: 18px;
    &:first-child {
      font-size: 15px;
      margin-bottom: 6px;
      line-height: 1.5;
      font-family: $inter-bold;
    }
  }
  @include media($md) {
    padding-top: $base-spacing;
  }
}

// Social area
.social-feed {
  padding: 12px 0;
  padding-bottom: 12px;
  +.social-feed {
    padding-top: 0;
  }
}

.social-feed .slick-list {
  overflow: visible;
  @include media($md) {
    margin-left: 0;
  }
}

.social-feed .fa {
  font-size: 30px;
  margin-bottom: 12px;
  &:hover {
    color: $brand-primary;
  }
}

.social-feed__item {
  float: left;
  margin-right: $base-spacing;
  min-height: 100px;
  width: auto;
  &:focus {
    outline: 0;
  }
}

.insta-feed__list {
  top: -($base-spacing*1.1);
  @include media($md) {
    padding-left: 0;
  }
}

.insta-feed__item {
  padding: $base-spacing*1.2 $base-spacing/1.5;
  margin-right: 0;
}

.insta-feed__image {
  box-shadow: 0 10px 35px rgba(52, 52, 52, 0.5);
  transition: all .3s 0s ease-in-out;
  &:hover {
    box-shadow: 0 5px 25px rgba(52, 52, 52, 0.5);
    margin-top: -3px;
  }
}

.twitter-feed {
  .slick-track {
    /*@supports(display: grid ){
      &::before, &::after { display: none;}
      max-height: none;
      float: none;
      height: auto;
      width: auto;
      margin: auto;
      display: grid;
      grid-template-rows: minmax(auto, auto);
        .social-feed__item {
          height: auto!important;
          grid-row: 1/2;
        }
    }*/
  }
}

.twitter-feed__item {
  font-family: $inter;
  font-size: modular-scale(0);
  background: #7bc9dc;
  background: rgba(123, 201, 220, .8);
  transition: background .3s 0s ease-in-out;
  border-radius: 3px;
  margin-right: $base-spacing;
  position: relative;
  padding: $small-spacing*1.8;
  line-height: 1.4;
  @include media($sm) {
    //width: 100%;
    max-width: 440px;
  }
  &:hover {
    background: #7bc9dc;
    cursor: pointer;
  }
  &--lrg {
    max-width: 600px;
  }
  >a .twitter-feed__content:first-child {
    margin-left: 0;
  }
  a {
    display: block;
  }
}

.twitter-feed__content {
  color: #fff;
  word-wrap: break-word;
  font-size: 19px;
  margin: 0;
  display: block;
  @include media($sm) {
    //@include shift(5);
    //@include omega();
  }
}

.twitter-feed__image-wrap {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
  display: none !important;
  @include media($sm) {
    display: inline-block !important;
    vertical-align: top;
    float: left;
    width: 100%;
    max-width: 160px;
    padding-right: $small-spacing*1.8;
    /*@include span-columns(5);
    height: 0;
    padding-top: 35%;*/
  }
  +.twitter-feed__content {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    @include media($sm) {
      width: calc(100% - 160px);
    }
  }
}

.border-spacer {
  margin-top: $base-spacing*1.5;
  border-top: 1px solid #d1d1d1;
}
