.slick-light-open {
    overflow: hidden;
}

.slick-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: #404044;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease
}

.slick-lightbox .slick-loading .slick-list {
    background-color: transparent
}

.slick-lightbox { 

   
    .slick-arrow {
        background: #fff;
        top: 30px;
        z-index: 3;
        //  &:before {
        //     font-family: "slick";
        //     font-size: 55px;
        //     line-height: 1; 
        //     opacity: 0.85;
        //     background: none;
        //     -webkit-font-smoothing: antialiased;
        //     -moz-osx-font-smoothing: grayscale;        
        // }
    }
    $offset: 18px;
    $closeOffset: 15px;
    $spacing: 12px;
    $width: 28px;
    .slick-prev {
        right: $offset + (($width + $spacing) * 4) !important;
        background: url(#{$image-path}/icons/gallery-left-white.svg) no-repeat center center  !important;
        background-size: contain;

    } 
    .slick-next {
        right: $offset + (($width + $spacing) * 1) !important;
        background: url(#{$image-path}/icons/gallery-right-white.svg) no-repeat center center !important;
        background-size: contain;
    }

     .lightbox-info-btn {
        position: absolute;
        top: 0;
        right: $offset + (($width + $spacing) * 5) !important;
        display: block;
        width: 28px;
        height: 28px;
        line-height: 0; 
        font-size: 0;
        cursor: pointer;        
        transition-duration: .3s;
        transition-property: background-image;
        background-image: url(#{$image-path}/icons/info.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
        color: transparent;
        padding: 0;
        border: none;
            &:active,
            &:focus {
            outline: 0;
        } 
            &.info-opened {
                background-image: url(#{$image-path}/icons/info-close.svg);
            }
    }

    .slick-lightbox-close {
        position: absolute;
        top: 0;
        right: $closeOffset !important;
        display: block;
        width: 28px;
        height: 28px;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        background-color: transparent;
        background-image: url(#{$image-path}/icons/png/close-alt.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        color: transparent;
        padding: 0;
        border: none
    } 

    .slick-arrow, 
    .lightbox-info-btn,
    .lightbox-count,
    .slick-lightbox-close {
        display: block;
        width: 28px !important;
        height: 28px !important;
        position: absolute;
        display: inline-block;
        top: $offset   !important;
        z-index: auto !important; 
        
        &:before {
            pointer-events: none;
            content: " ";
            opacity: 1 !important;
            display: block;
            background-color: transparentize(#404044, 0.3);
            position: absolute;
            height: 28px + $spacing;
            width: 28px + $spacing;
            top: -($spacing / 2);
            left: -($spacing / 2);
            z-index: -1;
        }
    }
    
    .lightbox-count { 
        color: #fff;
        font-weight: bold;
        line-height: 1.6;
        right: $offset + (($width + $spacing) * 2) !important;
        width: 70px!important;
        text-align: center;
        &:before {
            width: 100%;
            left: 0;
        }
    }
    .lightbox-info-btn {
        &:after {
            content: ' ';
            background: #7b7b7b;
            position: absolute;
            right: -6px;
            display: block;
            top: 0;
            height: 28px;
            width: 1px;
        }
    }
    .slick-lightbox-close {
        &:before {
            background-color: transparentize($brand-primary, 0.2);
        }
    }
}

.slick-lightbox-slick.slick-caption-dynamic {
    button {
        display: none !important;
    }
}

.slick-lightbox-hide {
    opacity: 0;
}

.slick-lightbox-hide.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0)
}

.slick-lightbox-hide-init {
    position: absolute;
    top: -9999px;
    opacity: 0
}

.slick-lightbox-hide-init.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0)
}

.slick-lightbox-inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.slick-lightbox-slick-item {
    text-align: center;
    overflow: hidden;
    height: 100vh;
}

.slick-lightbox-slick-item:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em
}

.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 20px
}

.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    display: block;
    text-align: center
}

.slick-lightbox-slick-item-inner {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    @include media($md){
        width: auto;
    }
    //margin-top: 12px;
    //max-width: 90%;
    //max-height: 90%
}

.slick-lightbox-slick-img {
    margin: 0 auto;
    display: block; 
    //max-height: none;
    max-height: 90vh;
    width: auto;
    max-width: 100%;
}

.slick-caption__title {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    margin: $small-spacing 0;
}

.slick-lightbox-slick-caption {
    display: block;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    padding: $base-spacing*1.5;
    text-align: left;
    width: 330px;
    background-color: #404044;
    background-color: transparentize(#404044, 0.3);
    //margin-bottom: 20px
    z-index: 1;
    opacity: 0;
    transition: all 200ms ease-in-out;
    &.show-caption {
        opacity: 1;
    }

}

.slick-caption__content {
    //margin: $base-spacing 0 $small-spacing 0;
    font-style: italic;
}


.slick-lightbox-close:focus {
    outline: none
}

.slick-lightbox-close:before {

}

.slick-lightbox-controls {
    position: absolute;
    top: 0;
    z-index: 21;
    width: 100%; 
    text-align: right;
}