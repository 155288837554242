
.takeover-layer {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:  rgba(0,0,0,0.6);
    overflow: hidden;


    &__container {
        max-width: 750px;
        width: 90%;
        top: 345px;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background: rgba(0,0,0,.3);
        border-radius: 5px;
        margin: 0;
        padding: 0;
        padding: 20px;
        box-sizing: border-box;
        color: #ffffff;
        @include media($lg) {
            width: 90%;
            position: fixed;
        }
    }
    
    &__image {
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        margin-bottom: 25px;
    }
    
    &__rte {
        background-color: rgba(255,255,255,.9);
        color: black;
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 25px; 
        p {
            font-size: 30px;
            line-height: 34px;
        }
    }
    
    &__link {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    &__hidden{
        display:none;
    }

    &__header{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 25px;
        .bi.bi-x-lg {
            width: 25px;
            height: 25px;
            cursor: pointer;
        }
    }
    
    &__title {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-weight: bold;
    }
}



