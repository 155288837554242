@mixin mm_colors( $cls: "",
	$baseBg: $mm_backgroundColor,
	$color: $mm_textColor, $dimmedColor: $mm_dimmedTextColor,
	$emphasizedBg: $mm_emphasizedBackgroundColor, $highlightedBg: $mm_highlightedBackgroundColor,
	$borderColor: $mm_borderColor,
	$navbarColor: $mm_navbarColor
) {
	.mm-menu#{$cls}
	{
		background: $baseBg;
		border-color: $borderColor;
		color: $color;

		.mm-navbar
		{
			> *,
			a
			{
				color: $navbarColor;
			}
		}

		.mm-btn
		{
			&:before,
			&:after
			{
				border-color: $navbarColor;
			}
		}

		.mm-listview
		{
			border-color: $borderColor;

			> li
			{
				.mm-next:after
				{
					border-color: $dimmedColor;
				}

				a:not(.mm-next)
				{
					-webkit-tap-highlight-color: $emphasizedBg;
					tap-highlight-color: $emphasizedBg;
				}

				&.mm-selected
				{
					> a:not(.mm-next),
					> span
					{
						background: $emphasizedBg;
					}
				}
			}
		}

		&.mm-vertical .mm-listview > li.mm-opened,
		.mm-listview > li.mm-opened.mm-vertical
		{
			> a.mm-next,
			> .mm-panel
			{
				background: $highlightedBg;
			}
		}

		.mm-divider 
		{
			background: $highlightedBg;
		}
	}
}

@mixin mm_colors_checks( $cls: "",
	$color: $mm_textColor
) {
	.mm-menu#{$cls} label.mm-check:before
	{
		border-color: $color;
	}
}

@mixin mm_colors_counters( $cls: "",
	$dimmedColor: $mm_dimmedTextColor
) {
	.mm-menu#{$cls} em.mm-counter
	{
		color: $dimmedColor;
	}
}

@mixin mm_colors_dividers( $cls: "",
	$highlightedBg: $mm_highlightedBackgroundColor
) {
	.mm-menu#{$cls}
	{
		.mm-fixeddivider span
		{
			background: $highlightedBg;
		}
	}
}

@mixin mm_colors_pageshadow( $cls: "",
	$pageShadow: $mm_pageShadow
) {
	.mm-menu.mm-shadow-page#{$cls}
	{
		&:after
		{
			@if ( $pageShadow )
			{
				box-shadow: $pageShadow;
			}
			@else
			{
				content: none;
				display: none;
			}
		}
	}
}
@mixin mm_colors_panelshadow( $cls: "",
	$panelShadow: $mm_panelShadow
) {
	.mm-menu.mm-shadow-panels#{$cls}
	{
		.mm-panel.mm-opened:nth-child(n+2)
		{
			box-shadow: $panelShadow;
		}
	}
}

@mixin mm_colors_searchfield( $cls: "",
	$inputBg: $mm_inputBackgroundColor, $color: $mm_textColor,
	$dimmedColor: $mm_dimmedTextColor
) {
	.mm-menu#{$cls}
	{
		.mm-search input
		{
			background: $inputBg;
			color: $color;
		}
		.mm-noresultsmsg
		{
			color: $dimmedColor;
		}
	}
}

@mixin mm_colors_sectionindexer( $cls: "",
	$color: $mm_dimmedTextColor
) {
	.mm-menu#{$cls} .mm-indexer a
	{
		color: $color;
	}
}

@mixin mm_colors_setselected( $cls: "",
	$emphasizedBg: $mm_emphasizedBackgroundColor
) {
	.mm-menu
	{
		&.mm-hoverselected#{$cls} .mm-listview > li
		{
			> a:not(.mm-fullsubopen):hover,
			> a.mm-fullsubopen:hover + span
			{
				background: $emphasizedBg;
			}
		}

		&.mm-parentselected#{$cls} .mm-listview > li
		{
			> a.mm-selected
			{
				&:not(.mm-fullsubopen),
				&.mm-fullsubopen + span,
				&.mm-fullsubopen + a
				{
					background: $emphasizedBg;
				}
			}
		}
	}
}

@mixin mm_colors_keyboardnavigation( $cls: "",
	$emphasizedBg: $mm_emphasizedBackgroundColor
) {
	.mm-menu
	{
		&.mm-keyboardfocus a:focus
		{
			background: $emphasizedBg;
		}
	}
}

@mixin mm_colors_toggles( $cls: "",
	$buttonBg: $mm_backgroundColor,
	$offBg: $mm_borderColor, $onBg: $mm_toggleCheckedColor
) {
	.mm-menu#{$cls}
	{
		label.mm-toggle
		{
			background: $offBg;
		
			&:before
			{
				background: $buttonBg;
			}
		}
		input.mm-toggle:checked ~ label.mm-toggle
		{
			background: $onBg;
		}
	}
}