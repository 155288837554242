$table-cell-padding: 8px;
$table-border-width: 1px;
$table-border-color: #53535a;
$table-thead-background-color: $grey-dark;
$table-tfoot-background-color: $grey-lighter;
$body-bg: transparent;
.editor-content table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  background-color: transparent;
  overflow-x: auto;
  display: table;
  width: fit-content;
  max-width: 100%;
  border: 0 !important;
  th,
  td {
    padding: $table-cell-padding;
    padding: $table-cell-padding;
    vertical-align: middle;
    border: $table-border-width solid $table-border-color; //white-space: nowrap;
  }
  thead th,
  thead td {
    vertical-align: bottom;
    border: $table-border-width solid $table-border-color;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
    background-color: $table-thead-background-color;
    color: #fff;
  }
  tfoot th,
  tfoot td {
    border: $table-border-width solid $table-border-color;
    border-top: (2 * $table-border-width) solid $table-border-color;
    background-color: $table-tfoot-background-color;
  }
  tbody+tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
  .table {
    background-color: $body-bg;
  }
  p {
    margin-bottom: 0;
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
  // Prevent double border on horizontal scroll due to use of `display: block;`
  >.table-bordered {
    border: 0;
  }
}
