.footer {
    background: $brand-2024-sand2;
    @include clearfix;
}

.footer__blocks-container {
  @include outer-container(100%);
  @include iefix();
  margin-bottom: $base-spacing;
  @include media($md) {
    border-bottom: 1px solid black;
    @include outer-container(100%);
    display: block;
    margin-bottom: $base-spacing;
    padding-bottom: $base-spacing;
  }
}

.footer__blocks {
  @include media($lg) {
    @include span-columns(12);
  }
}

.footer {
    font-size:14px;
  a {
    color: black;
    padding: 2px 0;
    &:focus,
    &:active {
      background-color: $brand-2024-sand2;
      outline: 1px;
    }
  }
}

.footer__blocks-item {
  @include span-columns(12);
  @include media($md) {
    @include span-columns(3);
  }
}

.footer-title {
  color: black;
  font-size: modular-scale(1);
  font-weight: 800;
}

.footer {
  padding: $base-spacing/1.5 0;
  padding-top: $base-spacing*1.3;
  position: relative;
  &:before {
/*    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: $brand-primary;
    position: absolute;
    top: 0;
    left: 0;*/
  }
}

.footer__blocks-content {
  color: black;
  font-weight: lighter;
  a {
    text-decoration: underline;
  }
}

.footer__blocks-title {
  font-size: modular-scale(1);
} // Footer toggle
.toggle-footer {
  @include media($md) {
    display: none;
  }
}

.toggle-footer__button {
  border-radius: 0;
  border: 0;
  box-shadow: none;
  padding: 20px;
  cursor: pointer;
  text-indent: 60px;
  background: transparent url('#{$image-path}/icons/plus.svg') no-repeat left center;
  background-size: contain;
  transition: background-image 0.2s 0s;
  color: black;
  font-weight: normal;
  &:focus {
    outline: 0;
  }
  &.open {
    background-image: url('#{$image-path}/icons/minus.svg');
  }
}

.js-reveal {
  display: none;
  @include media($md) {
    display: block !important;
  }
}

.toggle-label-ref {
  display: none;
}

// Footer menus 
.footer__menus {
  @include media($sm) {
    @include outer-container;
  }
  @include media($lg) {
    @include span-columns(10);
  }
}

.footer__menu-col {
  @include span-columns(12);
  margin-bottom: $base-spacing/2;
  @include media($sm) {
    @include span-columns(6);
    @include omega(2n);
  }
  @include media($md) {
    @include span-columns(3);
    @include omega-reset(2n);
    @include omega(4n);
  }
  @include media($lg) {
    @include omega-reset(4n);
    @include span-columns(2.4);
    @include omega(5n);
  }
  &.footer__menu-col--stamp {
    display: none;
    @include media($lg) {
      padding: $base-spacing*1.5;
      padding-top: 0;
      @include span-columns(2);
      @include omega()
    }
  }
}

.footer__meta-col {
  @include span-columns(12);
  padding-top: $base-spacing/2;
  @include media($md) {
    padding-top: 0;
    @include span-columns(6);
    @include omega();
  }
  @include media($lg) {
    @include span-columns(2);
    @include omega();
  }
}

.footer__meta-col-stamp {
  display: none;
}

.footer__menu {
  list-style: none;
  color: black;
}

.footer__menu-item {
  margin-bottom: $base-spacing/2;
  font-weight: lighter;
  text-transform: capitalize;
}

.footer__menu-item a {
  color: black;
  @include link-states($grey);
}

.footer__menu-item .fa {
  width: 20px;
}

.footer__menu-item--welsh {
  a {
    padding-left: 28px;
    background-image: url('#{$image-path}/icons/png/welsh.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
}

.footer__menu-item--eng {
  a {
    padding-left: 28px;
    background-image: url('#{$image-path}/icons/png/gb.png');
    background-image: url('#{$image-path}/icons/gb.svg'),
    linear-gradient(transparent,
    transparent);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

// Share price
.footer__shares-content {
  color: black;
  font-size: modular-scale(-1);
  margin: 0;
  font-weight: lighter;
}

.footer__shares-value {
  font-size: modular-scale(2);
  font-weight: bold;
}

.footer-currency {
  font-size: 13px;
}

.footer-toggle {
  @include media($sm) {
    display: none;
  }
}

.footer__lastbar {
    font-size:14px;
    background: $brand-2024-sand1;
    padding: ($base-spacing/2) 0;

    @include media($md) {
        padding: $base-spacing/1.3 0;
    }
}

.footer__lastbar-copy {
  color: $brand-black;
  margin-bottom: $small-spacing;
  font-family: $inter;
  font-weight: bold;
  margin-bottom: $small-spacing;
  @include media($md) {
    padding-top: $small-spacing;
    @include span-columns(5.5);
  }
  @include media($lg) {
    padding-top: $small-spacing;
    @include span-columns(7);
  }
}

.footer__logos {
 display:flex;
 justify-content:center;
 align-items:center;
}

// Individual logos
.footer__logos a {
  padding-left: 25px;
  padding-top: 16px;
}
.footer__logos img{
  max-height:80px;
}