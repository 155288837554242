@mixin headers() {
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

%banner-bg {
  background: {
    size: cover;
    position: center center;
    repeat: no-repeat;
    color: $off-white;
  }
}

.banner-cta {
  width: 100%;
  padding-bottom: 0;

  @include media($sm) {
    display: table;

    // Use Flex if supported
    @supports (display: flex) {
      display: flex;
      flex-direction: row-reverse;
      align-items: stretch;
    }
  }

  &__floater {
    padding: $base-spacing * 1.5;
    display: inline-block;

    > .container > *:not(.btn) {
      margin: 0;
      padding: 0;
    }

    @include media($sm) {
      max-width: $x-large-screen / 2;
    }
  }

  &__image,
  &__content {
    min-height: 120px;

    .banner-cta__floater {
      color: #000;
      width: 100%;

      .h2 {
        font-weight: bold;
        color: inherit;
      }
    }

    @include media($sm) {
      display: table-cell;
      width: 50%;
      vertical-align: middle;

      @supports (display: flex) {
        display: flex;
        align-items: center;
      }
    }

    @include media($lg) {
      min-height: 192px;
    }

    @include media($xl) {
      min-height: 240px;
    }
  }

  &__image {
    @extend %banner-bg;

    .banner-cta__floater {
      text-align: center;
    }

    @include media($sm) {
      text-align: right;

      @supports (display: flex) {
        text-align: left;
      }
    }
  }

  &__content {
    background-color: $brand-2024-sky2;
    direction: rtl;

    .banner-cta__floater {
      text-align: left;
      direction: ltr;

      .btn {
        @include padding(0 null 0 0);
        margin-top: $base-spacing;
        display: inline-block;
      }
    }

    @include media($sm) {
      text-align: left;

      @supports (display: flex) {
        text-align: right;
      }
    }

    .btn {
      padding-left: 0;
      border: none;
    }
  }
}

.banner-cta-hero {
  @extend %banner-bg;

  &.container__full {
    padding: $base-spacing;

    @include media($md) {
      padding: ($base-spacing * 4) ($base-spacing * 1.5);
    }
  }

  > .row {
    @include media($lg) {
      width: 100%;
      display: table;
    }
  }

  &__content,
  &__boxout {
    @include media($md) {
      width: $small-screen * 0.6;
    }

    @include media($lg) {
      display: table-cell;
      vertical-align: middle;
    }
  }

  &__content {
    color: #fff;

    > *:last-child {
      margin-bottom: $base-spacing;
    }

    @include headers {
      font: {
        size: modular-scale(3, 1em);
        weight: bold;
      }
      color: inherit;
      margin-bottom: $base-spacing * 0.33;
    }

    @include media($md) {
      padding-bottom: $base-spacing * 3;

      @include headers {
        font-size: modular-scale(4, 1em);
      }
    }

    @include media($lg) {
      width: auto;
      padding-right: 20vw;
    }

    @include media($xl) {
      padding-right: 33vw;
    }
  }

  &__boxout {
    padding: $base-spacing;
    background-color: #fff;

    @include media($md) {
      padding: $base-spacing * 1.5;
    }

    .red-title {
      font: {
        size: modular-scale(1, 1em);
        weight: bold;
      }

      &:only-child {
        margin-top: $base-spacing * -0.5;
        display: block;

        @include media($md) {
          margin-top: 0;
        }
      }
    }

    .btn {
      padding-right: $base-spacing * 1.5;
      margin-top: $base-spacing;
      display: block;

      @include media($md) {
        display: inline-block;
      }
    }
  }
}

.reversible-image {
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  > .row {
    display: flex;
    align-items: end;

    > .container {
      width: 100%;
    }
  }

  &__imgwrapper {
    height: auto;
    width: 1px;
    overflow: hidden;
    opacity: 0;

    > img {
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      display: block;
      width: 100vw;
      height: auto;
      min: {
        height: auto;
        width: auto;
      }
      max: {
        height: none;
        width: none;
      }
    }
  }

  &__wrapper {
    $spacing-buffer: $base-spacing * 1.5;
    text-align: left;
    overflow: hidden;

    padding: ($spacing-buffer * 2) $spacing-buffer;
    width: 80%;

    @include media($sm) {
      width: 50%;
    }

    .btn {
      padding: 0;
      padding-right: $base-spacing * 1.5;

      margin-top: $base-spacing;
      display: inline-block;
      background: none;
      border: none;
      color: $brand-primary;

      &::after {
        background-image: url("/images/icons/png/arrow1-black.png");
        background-image: none, url("/images/icons/arrow1-black.svg");
      }
    }
  }

  &__title,
  &__content {
    &.text-colour-- {
      $text-color-options: (
        black: $brand-black,
        white: #fff,
        red: $brand-primary
      );

      @each $color-name, $color-value in $text-color-options {
        &#{$color-name} {
          color: $color-value;
        }
      }
    }
  }

  &__title {
    font: {
      weight: bold;
      size: modular-scale(2);
    }

    @include media($md) {
      font-size: modular-scale(3);
    }
  }

  // Flip container; and flip inner content back again
  &.imageOnLeft {
    &,
    & .reversible-image__wrapper {
      transform: scaleX(-1);
      text-align: right;
    }
  }
}

.number-callout {
  &.background-colour-- {
    &white {
      &.row {
        position: relative;
        top: $base-spacing * -0.5;
        margin-bottom: 0;
      }
    }
  }

  &__wrapper {
    width: 100%;
    text-align: left;
    margin-bottom: $base-spacing * -0.75;

    @supports (display: flex) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-bottom: 0;
    padding: 0 $base-spacing * 1.5 $base-spacing * 1.5;
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    @supports (display: flex) {
      display: block;
    }

    @include media($sm) {
      width: 47.5%;

      @supports (display: flex) {
        width: 50%;
      }
    }

    @include media($md) {
      width: 30%;

      @supports (display: flex) {
        width: 33.333%;
      }
    }
  }

  &__value {
    color: $brand-primary;

    &.text-colour--dark-blue {
      color: $brand-secondary-dark;
    }

    font-size: modular-scale(5);
  }

  &__title {
    @include margin($base-spacing * -0.5 null $base-spacing);
    text-transform: uppercase;
    letter-spacing: 0.33em;
  }

  &__description {
  }
}

/*.blockquote {
  padding:20px;
  background-color: $brand-2024-ash2;

  &__wrapper {
    display:flex;
    margin: 0;

    @include media($sm) {
    }

    color: $brand-primary;
    font: {
      size: modular-scale(1);
    }

    @include media($md) {
      font-size: modular-scale(2);
      flex-direction:column;
    }

    div,
    p {
      text-align: left;
    }

    cite {
      color: $brand-black;
      font: {
        size: $base-spacing * 0.5;
        weight: 400;
      }
    }
  }

  &.background-colour-- {
    &white {
      &.row {
        margin: 0 !important;
      }
    }
  }
}
*/