// Results & reports
.report-panel {
  @include clearfix;
  clear: both;
  padding: $base-spacing/1.5 $base-spacing;
  background: $off-white;
  margin-bottom: $base-spacing/2;
  &:nth-child(2n) {
    background: #fff;
    .side-line span {
      background: white;
    }
  }
}

.report-panel__date {
  font-size: modular-scale(2);
  margin: 0;
  font-family: $inter;
  font-weight: normal;
  @include media($sm) {
    @include span-columns(4);
  }
  @include media($md) {
    @include span-columns(3);
  }
  @include media($lg) {
    @include span-columns(2);
  }
}

.report-panel__month {
  line-height: 1;
}

.report-panel__year {
  font-size: modular-scale(-1);
  display: inline;
  clear: both;
  @include media($sm) {
    font-size: modular-scale(-2);
  }
}

.report-panel__main {
  padding-top: 2px;
  @include media($sm) {
    @include span-columns(9);
    @include omega();
  }
  @include media($md) {
    @include span-columns(9);
    @include omega();
  }
  @include media($lg) {
    @include span-columns(10);
  }
}

.report-panel__title {
  font-size: modular-scale(1, 1.1em);
  margin-bottom: $small-spacing;
  font-family: $inter-bold;
  line-height: 1.3;
  font-weight: normal;
}

.side-line {
  position: relative;
  margin-bottom: $small-spacing*1.5;
  &:before {
    content: "";
    position: absolute;
    height: 1px;
    border-bottom: 1px solid $grey;
    top: 15px;
    width: 100%;
  }
  span {
    position: relative;
    font-size: modular-scale(0);
    background: $off-white;
    padding-right: $base-spacing;
  }
}

.event-attachents {
  float: left;
  @include media($md) {
    float: right;
  }
}

.event-attachents__item {
  color: $brand-primary;
  list-style: none;
  display: inline;
  font-size: moduar-scale(1);
  @include media($md) {
    margin-left: $small-spacing;
  }
  a {
    font-size: modular-scale(-1);
    background: white;
    border: 1px solid $grey-lighter;
    color: $brand-primary;
    padding: 4px 8px;
    text-transform: uppercase;
  }
}