ul,
ol {
  margin: 0;
  padding: 0;
}

.bare-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Content lists with red bullets. Also as a utility class 'red-list' on the UL
.editor-content ul li,
.editor-note__content ul li,
.red-list li,
.content__list-item,
.two-cols li {
  position: relative;
  list-style: none;
  list-style-position: outside;
  padding-left: 18px;
  &::before {
    content: ' \25CF ';
    position: absolute;
    top: 0;
    left: 0;
    color: $brand-primary;
    padding-right: 6px;
  }
  >ul li {
    &::before {
      content: ' \2013 ';
    }
  }
}

.red-list li {
    color: #da202a;
}


.editor-content ol,
.editor-note__content ol {
  counter-reset: item;
  li {
    position: relative;
    list-style: none;
    list-style-position: outside;
    padding-left: 18px;
    &::before {
      content: counter(item) ". ";
      counter-increment: item;
      position: absolute;
      top: 0;
      left: 0;
      color: black;
      padding-right: 6px;
      font-weight: bold;
    }
  }
}

.results-list {
  list-style: none;
}

.results-list__desc {
  /*font-style: italic;*/
  margin: 4px 0;
}

.results-list__title {
  /*color: $brand-primary;*/
  font-weight:800;
  margin: 0;
  font-size: modular-scale(0);
}

.results-list__meta {
  font-size: modular-scale(0, .9em); //padding-top: $small-spacing;
}

.results-list__date {
  color: $brand-grey3;
}

.results-list__item {
  position: relative;
  margin-bottom: $small-spacing;
  border-bottom: 1px solid $grey;
  .news__meta {
    border-top: 0;
    padding: 0;
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

.search-sections-container {
  p {
    padding-bottom: $base-spacing;
  }
}

.search-results-section {
  margin-bottom: $base-spacing;
  .download-list__item {
    margin: 0;
  }
}

.sections-list {
  list-style: none;
  margin-bottom: $base-spacing;
}

.sections-list__item {
  margin: 0;
  +.sections-list__item {
    margin-top: $base-spacing;
  }
  .roundel+a {
    margin-left: .5em;
    &:hover {
      color: $brand-grey2;
    }
  }
  a {
    display: inline;
    vertical-align: middle;
    font-family: $inter;
    font-size: modular-scale(0);
    @include media($lg) {
      font-weight: normal;
    }
  }
  &.active {
    a {
      font-family: $inter-bold;
      color: $brand-grey3;
    }
  }
}

// Pagination
.pagination__showing {
  float: left;
  padding-top: 2px;
}

.pagination {
  padding: $base-spacing 0;
}

.pagination__list {
  float: left;
}

.pagination__item {
  list-style: none;
  float: left;
  margin: 0;
  color: $brand-primary;
  font-size: modular-scale(1);
  a {
    color: $brand-primary;
    padding: 0 $small-spacing/2;
    margin: 0 3px;
    &:focus {
      background: none;
    }
    &.active {
      font-weight: bold;
    }
  }
}

.select-list {
  option {
    position: relative;
  }
}