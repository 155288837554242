/* 
    Markup for this is in Rich Text Editor on the Glossary page, not in a template
    
*/

.editor-content {
  .glossary-index__item-link::before {
    content: ' &nbsp;';
    display: none;
  }
  .glossary-index__item {
    float: left;
    max-width: none;
    text-align: center;
    @include media($md) {
      margin: 6px 0px;
      padding-left: 4px;
    }
    &::before {
      content: '';
      display: none;
    }
  }
  .glossary-index__item-link {
    color: #fff;
    display: block;
    padding: 13px;
    line-height: .7;
    border-radius: 3px;
    text-decoration: none;
    background: $brand-primary;
    transition: all .3s 0s ease-in-out;
    &:focus {
      background: lighten($brand-primary, 20%);
    }
    &:hover {
      background: lighten($brand-primary, 20%);
      display: block;
    }
    @include media($md) {
      padding: 11px 13px;
    }
  }
}

.glossary-accordions {
  .accordion__item {
    transition: all .3s 0s ease-in-out;
    &.active {
      background: #f6f6f6;
      background: linear-gradient(#f8f8f8, transparent);
    }
  }
}