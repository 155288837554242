body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  color: $grey-blue;
  font-weight: normal;
  a {
    color: $brand-primary;
    &:hover {
      color: darken($brand-primary, 10%);
    }
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    font-size: modular-scale(6 - $i);
  }
}

ul,
li {
  list-style-position: inside;
}

p,
li {
  margin: 0 0 $small-spacing;
  font-weight: normal;
}

strong {
  font-weight: 700;
}

a {
  color: $grey-darker;
  text-decoration: none;
  transition: color $base-duration $base-timing;
  &:active,
  &:focus,
  &:hover {
    color: $grey-blue;
    outline: 0;
  }
}

a:focus {
  //outline: 2px solid $focus-colour;
  background-color: $focus-colour;
  color: $grey-darker;
}

// Common elements
.row__header {
  font-size: modular-scale(2, 1.3em);
  padding: $small-spacing/2 0;
  margin: 0;
}

.btn {
  appearance: none;
  //text-transform: uppercase;
  background-color: $brand-2024-rose2;
  outline: none;
  /*border: 2px solid $brand-primary;*/
  color: #fff;
  cursor: pointer;
  display: inline;
  font-family: $inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 7px 9px;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  &:hover,
  &:focus {
    background-color: lighten($brand-primary, 4%);
    color: #fff;
  }
  &:focus {
    border: 2px solid $grey-blue;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      background-color: $action-color;
    }
  }
}
.btn--black-no-background {
    color: black;
    background-color: inherit;
    &:hover,
    &:focus {
        color: black!important;
        background-color: inherit !important;
    }
    &::after {
        background-image: url('#{$image-path}/icons/png/arrow1-black.png')!important;
        background-image: url('#{$image-path}/icons/arrow1-black.svg')!important;
    }

}
.btn--arrow {
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 15px;
  padding-right: 33px;
  padding-left: 12px;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 12px;
    width: 12px; // top: 7px;
    top: 50%;
    right: 7px;
    background-image: url('#{$image-path}/icons/png/arrow1.png');
    background-image: url('#{$image-path}/icons/arrow1.svg'),
    linear-gradient(transparent,
    transparent);
    background-size: cover;
    transform: perspective(1px) translateY(-50%);
  }
}

.btn--lrg {
  font-size: 14px;
  padding: ($small-spacing*1.2) $base-spacing*1.3;
}

.btn--small {
  padding: .5em 1em;
  font-size: 11px;
  &.btn--arrow {
    padding-right: $base-spacing*1.7;
  }
}

// Short, grey button used in search areas
.btn--util {
  font-weight: bold;
  background-color:#fff;
  border: 1px solid $brand-2024-rose2;
  color: $brand-2024-rose2;
  padding-right: 25px;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  &::after {
    content: "\f105";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: bold;
    text-decoration: inherit;
    /*--adjust as necessary--*/
    color: $brand-2024-rose2;
    font-size: 18px;
    padding-right: 0.5em;
    position: absolute;
    top: 4px;
    right: 3px;
  }
  &:hover,
  &:focus {
    &::after {
      color: #fff;
    }
  }
}

.btn--muted {
  color: $grey-darker;
  background: $grey-lighter;
  padding: 8px 16px;
  font-weight: normal;
  border: 1px solid $grey-lighter;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($grey-lighter, 4%);
    color: $grey-darker;
  }
  &:focus {
    border: 1px solid $grey;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      background-color: $action-color;
    }
  }
}

.btn-success {
  background-color: $brand-success;
  &:hover,
  &:focus {
    background-color: shade($brand-success, 20%);
  }
  &:disabled {
    &:hover {
      background-color: $brand-success;
    }
  }
}

.pill {
  color: #fff;
  background: $brand-primary;
  font-size: 12px;
  display: inline;
  font-family: $inter-bold;
  font-weight: lighter;
  padding: 4px 7px;
  text-align: center;
  text-transform: uppercase;
}



.bold-header-small {
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
}
