// Related card
.related {
  @include outer-container;
  border-top: 1px solid $grey-lighter;
  padding-top: ($base-spacing * 1.5)
}

.related__panel {
  @include media($md) {
    @include span-columns(6);
    @include omega(2n);
  }
}

.related__list-link {
  text-decoration: none;
  color: $brand-primary;
  &:hover {
    color: $grey-blue;
    background: none;
  }
}

.related__title {
  font-size: modular-scale(1);
  border-bottom: 1px solid $grey-lighter;
  padding: $base-spacing/4 0;
  font-family: $inter-bold;
  font-weight: normal;
  @include media($lg) {
    @include span-columns(4);
    padding-top: 0;
  }
}

.related__list {
  @include media($lg) {
    @include span-columns(8);
    @include omega();
  }
}

// Related Card
.related-card {
  position: relative;
  text-align:center;
  @include media($md) {
/*    box-shadow: -9px -15px 21px rgba(52, 52, 52, 0.1);
    @include shift(1);
*/    @include span-columns(10.5);
    border-radius: 0 0 5px 0;
    border: 1px solid $brand-2024-sky2;
    margin-bottom: $base-spacing;
    &:before {
/*      content: "";
      position: absolute;
      bottom: -2px;
      right: -3px;
      border-width: 0px 0px 25px 25px;
      border-style: solid;
      box-shadow: -6px -2px 7px rgba(0, 0, 0, 0.08), 2px 5px 0px #fff;
      border-color: #ffffff #f8f8f8;
      border-radius: 0 0 0 5px;
      width: 0;
      display: block;*/
    }
  }
}

/*.related-card__title {
  color: $brand-black;
  text-transform: uppercase;
  background: $brand-yellow;
  padding: $small-spacing/1.8 $small-spacing;
  font-size: modular-scale(0);
  @include media($md) {
    position: absolute;
    top: -$base-spacing;
    left: -$base-spacing;
  }
}*/

.related-card__content {
    background-color: $brand-2024-sky2;
    text-align:center;

    @include media($md) {
        padding: $small-spacing ($base-spacing*1.5);
    }
}

.related-card__content-title {
  font-size: modular-scale(0);
  color: $brand-primary;
  padding: $small-spacing 0;
  padding-bottom: 0;
  margin: 0;
  @include media($sm) {
    /*@include span-columns(10);*/
  }
  @include media($md) {
    @include span-columns(12);
    font-size: modular-scale(1);
    font-weight: normal;
  }
}

.relevant-links {
  &__title {
    @include padding(null $base-spacing * 0.66);
    @include margin(50px 0 $base-spacing * 0.33);
    font-weight: bold;
    color: black;

    @include media($sm) {
      @media screen and (orientation: portrait) {
        margin-bottom: $base-spacing * 0.66;
      }
    }

    @include media($lg) {
      margin-bottom: $base-spacing * 0.66;
    }
  }

  &__bg {
      background-color: $brand-2024-sand2;
  }

  &__wrapper {
      * {
        color: black;
        fill: #fff;
      }

      padding: $base-spacing * 1.5;
      width: 100%;

      @include media($sm) {
          display: table;
      }

      > * {
        @include padding($base-spacing * 1.5 null);

        width: 100%;
        border: {
          style: solid;
          width: 1px 0 0;
          color: black;
        }

        @include media($sm) {
          position: relative;
          display: table-cell;
          vertical-align: top;
          padding: 0 $base-spacing * 1.5;
          border-width: 0 1px 0 1px;

          @for $i from 1 through 10 {
            &:nth-last-child(#{$i}),
            &:nth-last-child(#{$i}) ~ * {
              width: 100% / $i;
            }
          }

          &:not(:first-child) {
            left: -1px;
          }
        }

        &:first-child,
        &.relevant-links__featuredimg + * {
          padding-top: 0;
          padding-left: 0;
          border-top-width: 0;
          border-left-width: 0;
        }

        &:last-child {
          padding-bottom: 0;
          padding-right: 0;
          border-bottom-width: 0;
          border-right-width: 0;
        }

        &.relevant-links__featuredimg {
          display: none;

          > img {
            display: block;
            width: 100%;
          }

          &,
          & ~ * {
            border: none;
            padding-left: 0;

            @include media($xl) {
              display: table-cell;
            }
          }
        }
      }

      .relevant-links__subtitle {
        font-size: modular-scale(1, 1.5em);
        margin-bottom: $base-spacing * 0.5;
      }

      .relevant-links__item {
        $link-icon-size: 8px;
        $link-padding: $base-spacing * 0.33;
        $duration: 0.3s;
        $effect: ease;

        max-width: 400px;
        margin: 0;
        padding: $link-padding;
        padding-left: 0;
        font-size: 1em;
        transition: background $duration $effect, padding $duration $effect;
        background: {
          position: right $link-padding center;
          size: $link-icon-size auto;
          repeat: no-repeat;
        }

        &:hover {
          background-color: #33333B;
          padding-left: $link-padding;
        }

        > a {
          width: 100%;
          padding-right: $link-icon-size * 2;
          display: table;
          transition: color 0.3s ease, fill 0.3s ease;

          &:not(.btn):hover,
          &:not(.btn):hover > * {
            color: $brand-grey3;
            fill: $brand-grey3;
          }

          &.btn {
              @include media($lg) {
                width: 66%;
              }
          }

          > * {
            display: table-cell;
            transition: color 0.3s ease, fill 0.3s ease;

            &:first-child {
              text-align: left;
            }
            &:last-child {
              font-size: 0.75em;
              text-align: right;
              /*color: $brand-grey3;*/
            }
          }
        }

        &--internal {
          background-image: url('/images/icons/png/arrow1-black.png');
          background-image: none, url('/images/icons/arrow1-black.svg');
        }
        &--external {
          background-image: url('/images/icons/png/external-black.png');
          background-image: none, url('/images/icons/external-black.svg');
        }
        &--media {
          background-image: url('/images/icons/png/download-black.png');
          background-image: none, url('/images/icons/download-black.svg');
        }
      }

      .relevant-links__items {
        &:not(:last-child) {
          margin-bottom: $base-spacing * 1.5;
        }

        &--featured > * {
          background-image: none !important;
        }
      }
  }
}
