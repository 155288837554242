/*
    ** lowest supported jQuery versions 
    
    Slick carousel - 1.7
    jBox - 3.11
    mmenu - 1.7 


    The Gulp task already outputs all the rules in the main stylesheet as a flattened version for
    IE with all media queries removed.

    Use this file to additionally target specific elements using the .lt-ie9 and .lt-ie8 classes.
    
*/

.lt-ie9 {
  .media-block__media {
    float: left;
  }
  .theme {
    .site-header,
    .navbar {
      .navbar__container {
        border-bottom: 0
      }
    }
    .navbar .navbar-logo {
      background: transparent url(#{$image-path}/rmg-logo-wht-small.png) no-repeat center center;
    }
    .site-header--clone .navbar-logo {
      max-width: 220px;
    }
    .main-nav__list--levelone>.main-nav__list-item {
      &.current>a {
        background-color: lighten($brand-primary, 10%);
      }
      >a {
        &:focus,
        &:hover {
          background-color: lighten($brand-primary, 15%);
        }
      }
    } // IE8 Fallbacks for coloured header themes
    // &.theme--1 {
    //     .site-header, .navbar { background: $brand-primary; }
    //     .main-nav__list--levelone > .main-nav__list-item {
    //         &.current > a {  
    //             background-color: lighten($brand-primary, 10%);  
    //         }
    //         > a {
    //             &:focus, &:hover {                  
    //             background-color: lighten($brand-primary, 15%);
    //             } 
    //         }
    //     }
    // }
    // &.theme--2 {
    //     .main-nav__list--levelone > .main-nav__list-item {
    //         &.current > a {  
    //             background-color: lighten($brand-secondary, 10%);  
    //         }
    //         > a {
    //             &:focus, &:hover {                  
    //             background-color: lighten($brand-secondary, 15%);
    //             } 
    //         }
    //     }
    // }
    // &.theme--3 {
    //     .main-nav__list--levelone > .main-nav__list-item {
    //         &.current > a {  
    //             background-color: lighten($brand-tertiary, 10%)!important;
    //         }
    //         > a {
    //             &:focus, &:hover {                  
    //             background-color: lighten($brand-tertiary, 15%);
    //             } 
    //         }
    //     }
    // }
    // &.theme--4 {
    //     .main-nav__list--levelone > .main-nav__list-item {
    //         &.current > a {  
    //             background-color: lighten($brand-teal, 10%);  
    //         }
    //         > a {
    //             &:focus, &:hover {                  
    //             background-color: lighten($brand-teal, 12%);
    //             } 
    //         }
    //     }
    // }
  } // Media indicator icons
  .media-indicator__icon {
    display: block;
    width: 30px;
  }
  .news-cards {
    .news-card {
      width: 400px;
      display: block;
      float: left;
      &:first-child {
        +div+div+div+div {
          clear: both;
        }
      }
    }
  }
  .insta-feed__item {
    float: left;
    display: block;
    max-width: 300px;
  }
  #brandStripes {
    display: none;
  } // Galleries
  .expand-icon:after {
    display: none;
  }
  .navbar__container {
    border-bottom: 1px solid $brand-primary;
  }
  .navbar-logo {
    height: 55px;
    top: 6px;
  } // Main show and hides
  .site-header__desktop {
    display: block;
  }
  .mobile-nav-wrap,
  .site-header__mobile,
  #js-menu-toggle,
  #mobile-menu {
    display: none;
  }
  .btn--arrow {
    padding-right: 12px;
  }
  .hero {
    background-color: #333;
  }
  .news__meta-item--share {
    display: none;
  }
  .article__meta-item--share {
    display: none;
  }
  .container {
    max-width: 1200px;
    min-width: none;
    position: relative;
  }
  .bg-photo {
    background-image: none !important;
    background: $grey-dark;
  }
  .main-nav__list-item>.main-nav__submenu {
    border: 1px solid $grey;
  }
  .submenu-content {
    padding-right: 0;
  }
  .submenu-content__item:first-child+.submenu-content__item {
    margin-right: 0;
  }
  .editor-note__contact.editor-note__content {
    margin-right: 0;
  }
  .hero__item {
    position: relative;
  }
  .hero__content {
    bottom: 20%;
  }
  .hero__item:before {
    //content: ' ';
    position: absolute;
    background: #333;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    display: none;
  }
  .navbar {
    background-color: #ffffff
  }
  .body__home .navbar {
    background-color: #333333
  }
  .slick-active .hero__content {
    background: #333;
    padding: 1em;
  }
  .hero__dots {
    bottom: 50px;
    max-width: 100%;
    left: 20px;
    padding-left: $small-spacing;
    li {
      margin-right: 5px;
    }
    .container {
      padding-left: 0
    } // Override 'font size' of bullet character. Defined first in Slick Theme
    .slick-dots {
      li button:before {
        font-size: 14px;
        left: 5px;
        bottom: 10px;
      }
    }
  }
  .gallery__directions .slick-arrow {
    height: 36px;
    width: 36px;
  } ////////////////////////
  // Navigation
  .main-nav {
    position: absolute;
    display: none;
    top: $navigation-height + 3;
    bottom: 0;
    left: 0; //padding: $base-spacing;
    background: #fff;
    position: fixed;
    -webkit-overflow-scrolling: touch; // mobile safari 
    &.show {
      display: block;
    } // Bump nav off the top by the additional height of the red social bar
    top: $navigation-height+26px;
    display: block;
    position: relative;
    background: none;
    padding: 0;
    overflow-y: visible;
    @include span-columns(10);
    @include shift(2);
    @include omega();
    top: auto;
  }
  // Main list
  .main-nav__list {
    margin: 0;
    padding: 0;
    float: right;
  }
  .main-nav__list-item {
    float: left;
    list-style: none;
    margin: 0;
  }
  .main-nav__megamenu-list--sub {
    display: none;
  }
  .main-nav__list--levelone>.main-nav__list-item {
    >a {
      font-size: .9em;
    } // Search item
    &.main-nav__list-item--search {
      display: block;
      margin-right: 0;
      margin-top: 2px;
      margin-left: 10px;
      >a {
        background: transparent url(#{$image-path}/icons/png/search-ie.png) no-repeat center center;
      }
    }
  }
  // Second level
  .main-nav__submenu--nested {
    @include clearfix;
    padding: $small-spacing 0;
    padding-left: $small-spacing;
  }
  .main-nav__view-all {
    color: $brand-primary;
    cursor: pointer;
    margin: 8px 0 12px 0;
  }
  .main-nav__list--levelone {
    position: relative;
    height: 100%;
    padding: 14px 0;
    padding-right: 0; //@include media($desktop-menu){        
    .body__home & {
      >li>a {
        color: #fff;
      }
    } // }
  }
  .main-nav__list--leveltwo {
    position: absolute;
    top: 0;
    right: 0;
  }
  .main-nav__list-item--has-submenu .main-nav__list-icon {
    background: transparent url(#{$image-path}/icons/png/arrow-right-red.png) no-repeat center center;
    background-size: contain;
    display: none;
  }
  // Submenus
  .main-nav__submenu {
    display: none;
  }
  .main-nav__list-item--has-submenu {
    position: relative;
  }
  // View all link
  .submenu-section__link-panel {
    border-right: 1px solid $grey-lighter;
    min-height: 210px;
  }
  // Turn navs on and off
  #mobile-menu {
    display: none;
  }
  .main-nav {
    display: block;
  }
  // Prevent flash of unstyled content before menu loads
  .mobile-menu:not(.mm-menu) {
    display: none;
  }
  .hamburger {
    display: none;
  }
  .main-nav {
    box-shadow: none;
    .mm-listview {
      color: $brand-primary;
      font-size: modular-scale(0);
    }
  }
  .main-nav__submenu-section {
    list-style: none;
    @include span-columns(5.5);
    a {
      color: $brand-primary;
    } // First submenu (news) has more columns
    .main-nav__submenu--news & {
      @include span-columns(3);
    }
  }
  ////////////////////////////////
  /////////// Header
  .site-header {
    position: relative;
    .body__home & {
      background: $brand-black;
      background: rgba(0, 0, 0, .75);
    }
  }
  .site-header__social {
    display: block;
    height: 30px;
  }
  // Logo
  .navbar-logo {
    position: absolute;
    width: 230px;
    top: 7px;
    left: 0;
    background: #fff url('#{$image-path}/rmg-logo-blk.jpg') no-repeat 0 center;
    a:focus {
      outline: 1px solid $grey-lighter;
      background: none;
    }
  }
  .body__home .navbar-logo {
    background: #333 url('#{$image-path}/rmg-logo-wht.jpg') no-repeat 0 center;
  }
  .navbar-logo-link {
    .body__home & {
      color: #fff;
    }
  }
  .media-block__popular .btn {
    margin-top: 0;
  }
  ////////////////////////////
  /////// Footer
  .footer__menu-item--welsh a {
    background: none;
  }
  .footer {
    background: $grey-blue;
    @include clearfix;
  }
  .footer__blocks-container {
    border-bottom: 1px solid $brand-yellow;
    @include outer-container(100%);
    display: block;
    margin-bottom: $base-spacing*2;
    padding-bottom: $base-spacing*2;
  }
  .footer__blocks {
    @include span-columns(12);
  }
  .footer {
    a {
      color: #fff;
      padding: 2px 0;
      &:focus,
      &:active {
        background-color: $grey-darker;
        outline: 1px;
      }
    }
  }
  .footer__blocks-item {
    @include span-columns(2.5);
  } // Footer toggle
  .toggle-footer {
    display: none;
  }
  .toggle-footer__button {
    display: none;
  }
  .js-reveal {
    display: block !important;
  }
  .toggle-label-ref {
    display: none;
  }
  // Footer menus 
  .footer__menus {
    @include span-columns(10);
  }
  .footer__menu-col {
    margin-bottom: $base-spacing/2;
    @include span-columns(2.4);
    @include omega(5n);
    &.footer__menu-col--stamp {
      padding: $base-spacing*1.5;
      padding-top: 0;
      @include span-columns(2);
      @include omega()
    }
  }
  .footer__meta-col {
    padding-top: $base-spacing/2;
    @include span-columns(2);
    @include omega();
  }
  .footer-toggle {
    display: none;
  }
  .footer__lastbar {
    background: $off-white;
    padding: ($base-spacing/2) 0;
    padding: $base-spacing/1.3 0;
  }
  .footer__lastbar-copy {
    padding-top: $small-spacing;
    @include span-columns(6);
  }
  .footer__logos {
    @include span-columns(5);
    float: right;
    padding-top: 12px;
  }
  // Individual logos
  .footer__logos-logo {
    @include span-columns(4);
    @include omega(3n);
    padding-left: 25px;
    padding-top: 16px;
/*    &:first-child {
      padding-top: 0;
      padding-left: 0;
      padding-right: 24px;
      margin-right: 0; // Last-child
      +img+img {
        padding-right: 5px;
      }
    }*/
  }
  ////////////////////
  ////// Layout
  .main-content {
    //padding-top: $navigation-height;
    padding-top: 0;
    /* Some views don't have a padding on the main element, eg.
      if there's a a header graphic */
    .body__home &,
    .body__careers & {
      padding-top: 0;
    }
  }
  // Image panel
  .img-panel__image,
  .img-panel__related {
    @include span-columns(4);
  }
  .img-panel__related {
    margin-top: $base-spacing;
  }
  .img-panel__title {
    margin-top: 0;
  }
  .img-panel__content {
    @include span-columns(8);
  }
  // CTA panel 
  .cta-panel__image {
    @include span-columns(6);
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    height: 100%;
    background-position: center center;
    background-size: contain !important;
  }
  .cta-panel__content {
    padding: $base-spacing;
    p {
      margin-bottom: $base-spacing;
    }
    @include span-columns(6);
    padding: $base-spacing*1.5;
  }
  .cta-panel__title {
    color: $grey-darker;
    font-size: modular-scale(2);
    font-weight: bold;
  }
  // Breadcrumbs
  .breadcrumb {
    display: block;
  }
  .breadcrumb__item {
    display: inline;
    margin-bottom: 4px;
    font-weight: lighter;
  }
  // In this section grid
  .suggestion-grid__item {
    margin-bottom: $base-spacing;
    float: left;
    padding: 0 12px;
    width: 25%;
    margin: 0;
  }
  .suggestion-grid__content {
    padding: $base-spacing/1.5;
    position: relative;
    position: relative;
  }
  // Parent has modifier class - size the child items differently...
  .suggestion-grid--small-items {
    margin-bottom: 0;
    .suggestion-grid__item {
      padding: 0 10px;
      width: 400px;
      margin-right: 20px;
    }
    .suggestion-grid__content {
      padding: $small-spacing;
      position: relative;
    }
    .suggestion-grid__content-title {
      font-size: modular-scale(0);
      min-height: 70px;
    }
  }
  .suggestion-grid--medium-items {
    .suggestion-grid__item {
      @include span-columns(4);
      @include omega(3n);
    }
    .suggestion-grid__content {
      padding: $small-spacing;
    }
    .suggestion-grid__content-title {
      font-size: modular-scale(0);
      min-height: 50px;
    }
    .news__meta {
      margin-top: 0;
    }
    .suggestion-grid__image {
      margin-bottom: 0;
    }
  }
  input[type="search"],
  input.search-input {
    background-image: url('#{$image-path}/icons/png/search-ie.png');
  }
  .search-sections-container {
    @include span-columns(3);
    display: block;
    padding-bottom: $base-spacing*2;
  }
  .search-results-container {
    @include span-columns(8);
    @include shift(1);
    @include omega();
  }
  // Two column ::content
  .two-cols__col {
    @include span-columns(6);
    @include omega(2n);
    img {
      width: 100%;
    }
    &:first-child+div {
      margin-right: 0;
    }
  }
  // Download blocks
  .download-block {
    @include shift(3);
  }
  /////////////////////////////////
  /////// News Landing
  //News landing
  .news__hero-block {
    padding: 0;
    @include box-shadow();
  }
  .news__hero-title {
    font-size: modular-scale(1);
    color: $brand-primary;
    font-weight: normal;
    font-size: modular-scale(2);
  }
  .news__lead-image {
    margin-bottom: 0;
    @include span-columns(8);
    position: relative;
    overflow: hidden;
  }
  .center-image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
  }
  .image-cont--dummy {
    padding-top: 80%;
    /* forces 1:1 aspect ratio */
  } // News image element itself
  .news__lead-image--img {
    display: block;
  }
  .news__hero-content {
    padding-left: 0;
    padding-bottom: 0;
    @include span-columns(3.5);
    padding-right: $base-spacing;
  }
  // Article hero list view. Inherits styles from the main hero with modifier
  .news__hero-block--list-view {
    box-shadow: none;
    .news__hero-item {
      @include clearfix;
      margin-bottom: $base-spacing;
      @include span-columns(12);
    }
    .news__lead-image {
      @include span-columns(5);
    }
    .news__hero-content {
      padding-top: 0;
      @include span-columns(6);
    }
    .news__hero-title {
      padding-top: $small-spacing;
      font-size: modular-scale(2);
    }
    .news__hero-intro {
      margin-bottom: $base-spacing*3;
    }
    .news__meta-list {
      margin-bottom: $base-spacing;
    }
    .news__meta {
      border-top: 1px solid $grey-lighter;
      .btn {
        float: right;
      }
    }
  }
  // Search and filter 
  .news-options {
    padding-top: $base-spacing;
  }
  .news-options__search {
    input[type="search"] {
      font-size: modular-scale(0);
      padding: $small-spacing;
      padding-left: 60px;
    }
  }
  .news-options__filter {
    @include span-columns(6);
    p {
      float: left;
      font-size: modular-scale(0);
      padding: 0;
      padding-right: $small-spacing;
    }
  }
  .news-options__search {
    @include span-columns(5);
    float: right;
  }
  .news-options__submit {
    visibility: hidden;
    position: absolute;
  }
  .news-options__selection {
    float: left;
    line-height: 40px; // For webkit only 
    width: auto;
    padding-right: $base-spacing*2;
  }
  .news-paging {
    padding-bottom: $base-spacing;
  }
  ///////////////////////////
  ////// Search
  .search-suggestions {
    @include outer-container(59vw)
  }
  .search__suggestions__list-item {
    font-size: 18px;
    @include span-columns(6);
    @include omega(2n); // Results as shown in the nav drop-down
    .main-nav__submenu & {
      font-size: modular-scale(1 + .1);
      float: none;
    }
  }
  // Search results as shown in the nav drop-down
  .search-panel {
    padding: $base-spacing*1.5 0;
    @include outer-container(70vw)
  }
  .no-results {
    padding: $base-spacing 0;
  }
  // Search results in Navigation
  .main-nav__search-submit {
    background: transparent url('#{$image-path}/icons/png/search-ie.png') no-repeat 12px center;
    background-size: 25px;
  }
  .main-nav__list-item>.main-nav__submenu {
    border-top: 2px solid $brand-primary;
    box-shadow: 0px 6px 16px rgba(52, 52, 52, .4);
    position: absolute;
    right: 0;
    left: 0;
    top: 94%;
    width: 840px;
    max-width: 100%;
    z-index: 10;
  }
} // .lt-ie9
// .body__home .lt-ie9 { 
//     .main-nav__list--levelone > .main-nav__list-item {
//         &.current > a {
//              background: #504f51;
//         }
//         > a {
//             color: #fff!important;
//             &:focus, &:hover {                  
//                 background: #504f51;
//             }
//         }
//     }
// }
// home .lt-ie9
// IE7
.lt-ie8 {
  #brandStripes {
    display: none;
  }
  .main-nav__submenu {
    display: block;
    position: relative;
    display: block;
    left: 0;
    clear: both;
    width: 100%;
    float: left;
  }
  .main-nav__submenu-section {
    float: left;
    margin-right: 1%;
  }
  .main-nav__list-item>.main-nav__submenu {
    display: block !important;
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    padding: 12px;
  }
  .main-nav__list-item {
    clear: both;
    display: block;
    float: left;
    width: 45%;
  }
  .navbar {
    height: auto;
  }
}