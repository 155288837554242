// Generic Gallery buttons
.row.gallery {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
}
.gallery__direction {
  float: left;
  position: relative;
}

// Used as for directions on galleries and news cards
.gallery__directions {
  transition: opacity .2s .2s; // Preload hover backgrounds then hide
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url('#{$image-path}/icons/arrow-right.svg') url('#{$image-path}/icons/arrow-right-grey.svg') url('#{$image-path}/icons/arrow-left.svg') url('#{$image-path}/icons/arrow-left-grey.svg');
  }
  button {
    background-color: transparent;
    background-size: cover;
    position: relative;
    transition: opacity .3s 0;
  }
  .slick-next,
  .slick-prev {
    left: auto;
    right: auto;
    display: inline;
    margin: 0;
    height: 32px;
    width: 32px;
  }
  .slick-next {
    background-image: url('#{$image-path}/icons/png/arrow-right.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/arrow-right.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    &.slick-disabled {
      background-image: url('#{$image-path}/icons/png/arrow-right-grey.png');
      background-image: linear-gradient(transparent,
      transparent),
      url('#{$image-path}/icons/arrow-right-grey.svg');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: auto;
    }
  }
  .slick-prev {
    background-image: url('#{$image-path}/icons/png/arrow-left.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/arrow-left.svg');
    position: relative;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
    &.slick-disabled {
      background-image: url('#{$image-path}/icons/png/arrow-left-grey.png');
      background-image: linear-gradient(transparent,
      transparent),
      url('#{$image-path}/icons/arrow-left-grey.svg');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: auto;
    }
  }
  &.disabled {
    opacity: 0;
  }
}

.gallery__directions--hidden {
  display: none;
}

.gallery-item__image {
  margin: 0 auto;
  display: block;
}

.thumb-gallery {
  .slick-arrow {
    z-index: 2;
    height: 35px;
    width: 35px;
    top: 0;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
}

/* 'expand' icon on all lightboxed images.
    Add to anything to show the expand icon in the top corner. */

.expand-icon {
  position: relative;
  &:after {
    opacity: 0;
    content: '';
    position: absolute;
    pointer-events: none;
    background-color: #50535b;
    background-color: rgba(80, 83, 91, .8);
    background-image: url('#{$image-path}/icons/png/enlarge-white.png');
    background-image: linear-gradient(transparent,
    transparent),
    url('#{$image-path}/icons/enlarge-white.svg');
    background-size: 80%;
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity linear .2s;
    padding: 6px;
    top: 12px;
    right: 12px;
    width: 40px;
    height: 40px;
    transition: all .3s 0s; // Adjustments for this element in specific contexts
    // ie. 'Large media' component, large gallery
    .large-media__image & {
      right: $base-spacing*2;
    }
    .gallery-item--large & {
      opacity: .8
    }
    .gallery-item--large:hover {
      &:after {
        opacity: 1;
      }
    }
  } // Fade in the enlarge button on js init
  &.slick-slide:after {
    opacity: .8;
  }
  &.slick-slide:hover:after {
    opacity: 1;
  }
}

.hidden-span {
  // This is a dummy element used to get data into the Slick Slider Lightbox.
  display: none;
  visibility: hidden;
}

.thumb-gallery__title {
  font-size: modular-scale(0);
  min-height: 43px;
  margin: 0;
  span {
    display: block;
  }
  @include media($lg) {
    width: 125%;
    position: absolute;
    z-index: 11;
    left: 0;
    top: $base-spacing;
  }
}

.thumb-gallery__pager {
  margin: $base-spacing 0;
  @include clearfix;
  .slick-current {
    opacity: 1;
  }
  @include media($lg) {
    //@include shift(3);
    overflow: hidden;
    position: relative;
    max-width: 100%;
  }
  .slick-list.draggable {
    @include media($lg) {
      left: 0;
      top: 0;
      overflow: visible;
    }
  }
}

.single-gallery__image {
  min-width: 100%;
}

.gallery-item {
  cursor: pointer;
  margin: 0;
  @include media($md) {}
}

.gallery-item--thumb {
  opacity: .5;
  margin: 0 $small-spacing;
  margin-left: 0;
  img {
    width: 100%;
  }
}

// The two main cols of the small gallery: 'info' and 'media'
.thumb-gallery__info {
  position: relative;
  margin: $small-spacing 0;
  @include media($md) {
    @include span-columns(4.5);
    @include shift(0.5);
    padding-left: $small-spacing;
  }
  @include media($lg) {
    padding-top: 100px;
  }
}

.thumb-gallery__media {
  @include media($md) {
    @include span-columns(6.5);
    margin-bottom: $base-spacing;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} // Handle initial heights for galleries during slow load.
#js-page {
  // Gallery wrapper elements
  .thumb-gallery__media,
  .large-gallery__media {
    overflow: hidden;
    max-height: 300px;
    opacity: 0;
    @include media($md) {
      max-height: 500px;
    } // Class added with JS - see rm.js
    &.js-slider-loaded {
      overflow: initial;
      max-height: none;
      -webkit-animation: fadein 0.5s;
      animation: fadein 0.5s;
      opacity: 1;
    }
  }
}

.thumb-gallery__description {
  font-style: italic;
  color: $grey-dark;
  font-weight: lighter;
  font-size: modular-scale(1, .8em);
  @include media($md) {
    margin-left: $base-spacing/2;
  }
  @include media($lg) {
    @include span-columns(10);
    padding-top: $small-spacing;
    margin-left: $base-spacing;
    padding-left: 0;
  }
}

.thumb-gallery__description--small {
  @include media($lg) {
    margin-left: $base-spacing/2;
  }
}

.thumb-gallery__counter {
  font-size: modular-scale(1, .8em);
  font-style: italic;
  color: $grey-dark;
  margin: 0;
  margin-left: $base-spacing/2;
  padding-top: $small-spacing/2;
  @include media($lg) {
    margin-bottom: $small-spacing;
  }
}

.thumb-gallery__counter--large {
  font-family: $inter-bold;
  font-style: normal;
  @include media($lg) {
    text-align: right;
    float: right;
  }
}

.thumb-gallery__counter--small {
  margin: $small-spacing 0;
  @include media($lg) {
    margin-left: $small-spacing;
  }
}

// Handle overflow while loading
.large-gallery__list,
.thumb-gallery__list {
  @include media($md) {
    //height: 600px;
    &.slick-initialized {
      //height: initial!important;
    }
  }
}

.large-gallery__list {
  @include media($md) {
    margin: 0 auto;
  }
}

.large-gallery__list {
  .slick-arrow {}
}

.slick-list {
  height: initial !important;
}

// Large galleries
.large-gallery__item {
  margin: 0 $small-spacing/2;
  @include media($md) {
    margin: 0 $base-spacing;
  }
}

.large-gallery .slick-arrow {
  //height: 100%;
  top: 0; //background: none;
  z-index: 10;
  &.slick-next {
    right: 0;
  }
}

.large-gallery__image {
  width: 100%;
  display: block;
  margin: 0 auto;
  @include media($md) {
    width: auto;
    margin: 0 auto;
  }
}

.gallery-item--large {
  opacity: .4;
  transition: opacity .2s;
  margin: 0;
  &.slick-current {
    opacity: 1;
  }
}

.large-gallery__title {
  font-size: modular-scale(0);
  margin-top: $small-spacing;
  pointer-events: none;
  @include media($lg) {
    z-index: 10;
    margin-top: 0;
    left: 0;
  }
}

.large-gallery__info {
  @include media($md) {
    @include span-columns(12);
  }
  @include media($lg) {
    @include span-columns(10);
    @include omega(0);
  }
}

.gallery__directions--large {
  margin-top: $small-spacing;
  margin-left: $base-spacing;
  text-align: left;
  @include media($md) {
    @include span-columns(2);
    margin-left: $base-spacing;
    margin-top: 0;
  }
  @include media($lg) {
    @include span-columns(1.5);
    @include omega();
    margin-left: $small-spacing;
  }
}

.large-gallery__footer {
  position: relative;
  @include span-columns(12);
  @include media($lg) {
    padding-top: $base-spacing;
    @include span-columns(11);
    @include shift(1);
    padding-right: 60px;
  }
}

// .slick-lightbox-slick-iframe-wrap {
//     iframe {
//         max-height: 650px;
//         max-height: 70vh;
//         width: 80%;
//         padding: 4%;
//         top: 0;
//     }
// }
.slick-lightbox-slick-iframe-wrap {
  width: 90%;
  height: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 90%;
  height: 0px;
  padding-bottom: 45%;
  @include media($sm) {
    padding-bottom: 50vw;
  }
  @include media($md) {
    // 768
    max-width: 90%;
    padding-bottom: 60%;
  }
  @include media($lg) {
    padding-bottom: 40%;
    max-width: 70%;
  }
  @include media($xl) {
    padding-bottom: 36vw;
  }
}

.slick-lightbox-video {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  max-height: 90vh;
}

.slick-lightbox.small-gallery {
    .slick-lightbox-slick-caption, .lightbox-info-btn {
        display: none !important;
    }
}


// Full Bleed Slider Gallery
.image-slider {
  $margin-size: $base-spacing;
  overflow: hidden;

  .container {
    $wrapper-padding: $base-spacing * 2.75;

    @include padding(null $wrapper-padding);
    max-width: calc(1200px + #{$wrapper-padding * 2.075});

    // @include media($md) {
    //   padding: 0;
    // }
  }

  &__overlay {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.8;
    z-index: 11;

    &--left {
      left: 100%;
    }

    &--right {
      right: 100%;
    }
  }
  &__wrapper {
    position: relative;
  }

  &__media {
    position: relative;
  }

  &__slick {
      $button-size: 36px;

    button {
      height: $button-size;
      width: $button-size;
      z-index: 20;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      border: none;
      background: {
        repeat: no-repeat;
        position: center center;
      }
    }


    &.slick-initialized {
      z-index: unset !important;
    }

    .slick-list {
      overflow: visible !important;
    }

    .slick-slide {
      @include margin(0 $margin-size * 0.5);

      .js-lightbox-image {
        cursor: pointer;
      }

      img {
        height: auto;
        max-height: 100vh;
        width: 100%;
        margin-bottom: $margin-size * 0.5;
      }

      &:focus,
      *:focus {
        outline: none;
      }
    }

    .slick-arrow {
      $button-offset: $button-size * -1.75;

      top: 50%;
      margin-top: $button-size * -0.5;
      z-index: 12;
      background: {
        image: url('/images/icons/png/arrow1-red.png');
        image: none, url('/images/icons/arrow1-red.svg');
        size: 75%;
        color: transparent;
      }

      &.slick-prev {
        left: $button-offset;
        transform: rotate(180deg);
      }

      &.slick-next {
        right: $button-offset;
      }
    }
  }

  &__description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;

    @include media($sm) {
      flex-direction: row;
    }

    > * {
      align-self: flex-start;

      &:first-child {
        width: 75%;
        font-size: 0.75rem;
        line-height: 125%;
        margin-top: $base-spacing * 0.33;

        @include media($sm) {
          font-size: 1rem;
        }
      }

      &:last-child {
        width: 25%;

        @include media($sm) {
          text-align: right;
          align-self: flex-end;
        }
      }
    }
  }

  &__button--fullscreen {
    position: absolute;
    bottom: 0;
    right: 0;
    background: {
      color: $brand-primary;
      size: 75%;
      image: url('/images/icons/png/enlarge-white.png');
      image: none, url('/images/icons/enlarge-white.svg');
    }
  }
}
