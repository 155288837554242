// Committee
.body__committee {
  .content__header-title {
    color: $brand-primary;
  }
}

.board__member {
  cursor: pointer;
  list-style: none;
  @include media($sm) {
    @include span-columns(6);
    @include omega(2n);
  }
  @include media($md) {
    @include span-columns(4);
    @include omega-reset(2n);
    @include omega(3n);
  }
  @include media($lg) {
    @include span-columns(3);
    @include omega-reset(3n);
    @include omega(4n);
    .no-js & {
      @include span-columns(6);
      @include omega-reset(4n);
      @include omega(2n);
    }
  }
}

.board__member-image {
    height: 200px;
    object-fit: cover;
    width: 100%;
    transition: opacity 0.3s 0s;

    .board__member:hover & {
        opacity: .7;
    }
}

.board__member-name {
  font-weight: normal;
  color: $brand-primary;
  margin-bottom: $small-spacing/2;
  font-size: modular-scale(0);
  padding-right: 30px;
  @include media($md) {
    font-size: modular-scale(1);
  }
  .board__content & {
    font-size: modular-scale(2);
    clear: both;
    @include media($md) {
      clear: none;
    }
  }
}

.board__member-role {
  font-size: modular-scale(0);
  margin-bottom: $base-spacing;
  font-weight: normal;
}

.board__member-dets {
  padding: $small-spacing;
  cursor: pointer;
  .no-js & {
    display: none;
  }
}

.board__content {
  display: none;
  font-weight: normal;
  padding: $base-spacing;
  padding-left: 50px;
  padding-top: 45px;
  position: relative;
  a {
    text-decoration: none;
    @include nice-underline($grey-blue, $grey-dark, $grey-lighter);
    @include link-states($grey-dark);
  }
  .no-js & {
    display: block;
  }
}

.board__content-photo {
  margin-right: 30px;
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  @include media($md) {
    display: inline;
    margin: 0;
    margin-right: 30px;
    float: right;
  }
}

.board__content {
  .editor-content {
    p,
    h2 {
      margin-bottom: 10px;
    }
  }
}

.jBox-closeButton-box:before {
  display: none;
}

.jBox-closeButton-box .jBox-closeButton {
  top: 12px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 0;
  background: none;
  background: no-repeat center center transparent;
  background-color: #fff;
  background-image : url('#{$image-path}/icons/png/close.png');
  background-image : linear-gradient(transparent,
  transparent),
  url('#{$image-path}/icons/close.svg');
  background-size: cover;
  cursor: pointer;
  @include media($md) {
    height: 30px;
    width: 30px;
  }
}

.board__close-content {
  position: fixed; //position: absolute;
  height: 40px;
  width: 40px; //transform: translateX(-50%);
  outline: 0;
  border: none;
  text-indent: -999em;
  background: no-repeat center center transparent;
  background-color: #fff;
  background-image : url('#{$image-path}/icons/png/close.png');
  background-image : linear-gradient(transparent,
  transparent),
  url('#{$image-path}/icons/close.svg');
  background-size: cover;
  cursor: pointer;
  top: 20px;
  margin-left: -40px;
  @include media($sm) {}
  @include media($md) {
    height: 30px;
    width: 30px;
  }
}

.board_list {
  list-style: none;
}