/* apply a natural box layout model to all elements, but allowing components to change */

html {
  box-sizing: border-box;
  margin: 0;
  font-size: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $base-font-family;
  font-style: normal;
  min-height: 100%;
  position: relative;
  &.nav-open {
    // Removes scrolling when the mobile nav is open.
    overflow-y: hidden;
  }
}

main {
  display: block;
}

img {
  max-width: 100%;
}

a > img {
  border: 0;
}

// Utility classes
// Apply this to the parent element of any slider you'd like to scroll horizontally outside of its container, typically at the .row level
.overscroll {
  overflow: hidden;
  .slick-list {
    overflow: visible;
    @include media($md) {
      margin-left: 0;
    }
  }
  .container {
    overflow-x: hidden;
  }
}

.roomy-above {
  @include media($md) {
    margin-top: $base-spacing * 1.5 !important;
  }
}

.roomy-above--large {
  @include media($md) {
    margin-top: $base-spacing * 3 !important;
  }
}

.roomy-below {
  margin-bottom: $base-spacing * 1.5 !important;
}

.roomy-below--large {
  margin-bottom: $base-spacing * 3 !important;
}

.roomy-above--small {
  margin-top: $base-spacing/1.8 !important;
}

.roomy-below--small {
  margin-bottom: $base-spacing/1.5 !important;
}

.tight-above {
  margin-top: 0 !important;
}

.tight-below {
  margin-bottom: 0 !important;
}

.centered {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.relative {
  position: relative;
}

.capitalise {
  text-transform: uppercase;
}

.border-top {
  border-top: 1px solid $grey;
  padding-top: $base-spacing;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

/* Use .container elements to create centered sections of content */

.container {
  $padding: 16px;

  @include iefix();
  @include clearfix;
  @include outer-container($local-max-width: $site-max-width);
  padding: 0 $padding;
  &.container--compact {
    max-width: 80%;
    @include media($lg) {
      max-width: 70%;
    }
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  // Hacky workaround to break out of container
  // Calculate distance of container to outer edge (including) padding and invert number
  &__full {
    &--grey {
      background-color: $off-white;
    }

    > .row {
      // @include padding(null $base-spacing * 1.5);
      @include margin(0 auto !important);

      box-sizing: content-box;
      max-width: calc(#{$x-large-screen - ($padding * 2)});
    }

    @include media($xl) {
      $full-width: calc(100vw - #{$padding * 2});

      position: relative;
      left: calc((50vw - (#{$x-large-screen} / 2)) * -1);
      width: $full-width;
      max-width: $full-width;
    }
  }
}

.container--flex {
  display: flex;
  width: 100%;
}

.row {
  margin: $base-spacing * 1.5 0;
  clear: both;
  display: block;
  @include clearfix;

  &--small-x {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
  }
}

.row--none {
  @include margin(0 null);
  @include padding(0 null);
}

.row--tight {
  @include margin($small-spacing null);
}

.row--fat {
  padding-top: 50px;
  @include margin(50px null);
}

a[href="#main"] {
    position: absolute;
    top: 0;
    right: 200%;
    z-index: 10;
    background: #333;
    color: #fff;
    opacity: 0;
}

a[href="#main"]:focus {
  right: auto;
  opacity:1;
}

.main-content {
  clear: both;
  opacity: 1;
  padding-top: 64px;
  .body__home &,
  .body__careers & {
    padding-top: 0;
  }
  @include media($desktop-menu) {
    /* Some views don't have a padding on the main element, eg.
      if there's a a header graphic */
    padding-top: 0;
  }
  ::selection {
    background: $brand-yellow;
    /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: $brand-yellow;
    /* Gecko Browsers */
  }
}

// Image panel component
.img-panel {
  margin-top: $base-spacing;
  margin-bottom: $base-spacing;
}
.img-panel__image,
.img-panel__related {
  @include media($md) {
    @include span-columns(5);
  }
  @include media($lg) {
    @include span-columns(4);
  }
}

.img-panel__related {
  /*margin-top: $base-spacing;*/
}

.img-panel__title {
  color: $brand-primary;
  margin: $small-spacing 0;
  font-size: modular-scale(2);
  @include media($md) {
    margin-top: 0;
    margin-bottom: $small-spacing;
    font-size: modular-scale(
      2,
      1em
    ) !important; // TODO: MDM Better way to override generic h3 style applied to editor content
  }
}

.img-panel__content {
  @include media($md) {
    @include span-columns(7);
    @include omega();
  }
  @include media($lg) {
    @include span-columns(8);
    padding-left: $base-spacing;
  }
}

.section__header-title {
  font-size: modular-scale(2);
  margin-bottom: 0;
  font-family: $inter-bold;
  font-weight: normal;
}

// CTA panel component
.cta-panel {
  background: $grey-lighter;
  position: relative;
  overflow: hidden;
}

.cta-panel__image {
  min-height: 220px;
  min-height: 40vw;
  @include media($md) {
    @include span-columns(6);
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    height: 100%;
    background-position: center center;
  }
  @include media($lg) {
    background-size: contain !important;
  }
}

.cta-panel__content {
  padding: $base-spacing;
  p {
    margin-bottom: $base-spacing;
  }
  @include media($md) {
    @include span-columns(6);
    padding: $base-spacing * 1.5;
  }
}

.cta-panel__title {
  color: $grey-darker;
  font-size: modular-scale(2);
  font-weight: bold;
}

// Suggestion component - 'In this section'
.suggestion-grid__item {
  margin-bottom: $base-spacing;
  max-width: 1090px;
  @include media($sm) {
    @include span-columns(6);
    @include omega-reset(2n);
    @include omega(2n);
  }
  @include media($lg) {
    @include span-columns(4);
    @include omega-reset(2n);
    @include omega(3n);
  }
}

.suggestion-grid__item > a {
  &:focus {
    background-color: transparent;
  }
}

.suggestion-grid__content {
  padding: $small-spacing;
  @include media($md) {
    padding: $base-spacing/1.5;
  }

  .press-center & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.suggestion-grid__title {
  font-size: modular-scale(1);
  margin-bottom: $base-spacing;
}

.suggestion-grid__content-title {
  color: $brand-primary;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: $base-spacing/3;
}

.suggestion-grid__image {
  width: 100%;
  margin: 0;
}

.suggestion-grid__image-wrap {
  position: relative;
  display: inline;

  margin-bottom:30px;
}

.suggestion-grid__content-intro {
  font-weight: normal;
}

.suggestion-grid--small-items {
  margin-bottom: 0;
  .suggestion-grid__item {
    @include media($sm) {
      @include omega-reset(2n+1);
      @include span-columns(6);
      @include omega(2n);
    }
    @include media($lg) {
      @include omega-reset(2n);
      @include span-columns(4);
      @include omega(3n);
    }
  }
  .suggestion-grid__content {
    padding: $small-spacing;
    position: relative;
  }
  .suggestion-grid__content-title {
    a {
      color: $grey-darker;
    }
    font-size: modular-scale(0);
    color: initial;
    @include media($sm) {
      min-height: 80px;
    }
    @include media($md) {
      min-height: 70px;
    }
    @include media($lg) {
      min-height: 98px;
    }
    @include media($xl) {
      min-height: 80px;
    }
  }
  .news__meta {
    margin-top: 0;
    padding-left: 0;
    .btn {
      float: right;
    }
  }
  .news__meta-list {
    width: auto;
  }
  .suggestion-grid__image {
    margin-bottom: 0;
  }
}

.suggestion-grid--medium-items {
  @include clearfix;
  .suggestion-grid__item {
    @include media($sm) {
      @include omega-reset(2n+1);
      @include span-columns(6);
      @include omega(2n);
    }
    @include media($lg) {
      @include omega-reset(2n);
      @include span-columns(4);
      @include omega(3n);
    }
  }
  .suggestion-grid__content {
    padding: $small-spacing;
  }
  .suggestion-grid__item {
    display: flex;
    flex-direction: column;
  }
  .suggestion-grid__content-title {
    font-size: modular-scale(0);
    @include media($md) {
      min-height: 50px;
    }
  }
  .news__meta {
    margin-top: 0;
    padding-top: 6px;
  }
  .suggestion-grid__image {
    margin-bottom: 0;
  } // Suggestion boxes in Search Results context
  .search-results-section & {
    @include media($md) {
      .suggestion-grid__content-title {
        min-height: 80px;
      }
    }
  }
}

.search-sections-container {
  display: none;
  @include media($md) {
    @include span-columns(3);
    display: block;
    padding-bottom: $base-spacing * 2;
  }
}

.search-results-container {
  @include media($md) {
    @include span-columns(8);
    @include shift(1);
    @include omega();
  }
}

// Two column content
.two-cols {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;

    @at-root {
        .jumbo-header ~ &,
        .jumbo-header + .container {
            .two-cols__col {
                padding: {
                    left: ($base-spacing * 1.5);
                    right: ($base-spacing * 1.5);
                }

                @include media($md) {
                    &:first-child {
                        padding-right: 0;
                    }

                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }


    &[class*="background-colour"] {
        .two-cols__col .two-cols__content p:last-child {
            margin-bottom: 0;
        }

        @at-root {
            .jumbo-header + .container + &,
            .editor-content + & {
                margin-top: 0 !important;
            }
        }
    }

    &.background-colour-- {
        &grey {
            background-color: $brand-grey5;
        }
    }
}

.two-col--50-50 {
  .two-cols__col {
    @include media($md) {
      @include span-columns(6);
      @include omega(2n);
    }
  }
}

.two-col--25-75 {
  .two-cols__col {
    @include media($md) {
      @include span-columns(4);
      @include omega(2n);
    }
    @include media($lg) {
      @include span-columns(3);
      @include omega(2n);
    }
    + .two-cols__col {
      @include media($md) {
        @include span-columns(8);
        @include omega(2n);
      }
      @include media($lg) {
        @include span-columns(9);
        @include omega(2n);
      }
    }
  }
}

.two-col--75-25 {
  .two-cols__col {
    @include media($md) {
      @include span-columns(8);
      @include omega(2n);
    }
    @include media($lg) {
      @include span-columns(9);
      @include omega(2n);
    }
    + .two-cols__col {
      @include media($md) {
        @include span-columns(4);
        @include omega(2n);
      }
      @include media($lg) {
        @include span-columns(3);
        @include omega(2n);
      }
    }
  }
}

.two-col--34-66 {
  .two-cols__col {
    @include media($md) {
      @include span-columns(4);
      @include omega(2n);
    }
    + .two-cols__col {
      @include media($md) {
        @include span-columns(8);
        @include omega(2n);
      }
    }
  }
}

.two-col--66-34 {
  .two-cols__col {
    @include media($md) {
      @include span-columns(8);
      @include omega(2n);
    }
    + .two-cols__col {
      @include media($md) {
        @include span-columns(4);
        @include omega(2n);
      }
    }
  }
}

.two-col--multi {
  $margin-base: 10%;

  .two-cols__col {
    @for $i from 1 through 12 {
      &:first-child:nth-last-child(#{$i}n),
      &:first-child:nth-last-child(#{$i}n) ~ * {
        $margin-sing: $margin-base / ($i - 1);

        @include media($md) {
          width: ((100% / $i) + ($margin-sing / $i)) - $margin-sing;
          margin-right: $margin-sing;
          float: left;
          display: block;
          // @include span-columns(12 / $i);
          // @include omega(#{$i}n);
        }
      }
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.two-cols__col {
  @include media($md) {
    img {
      width: 100%;
    }
  }
}

.two-cols__content {
  a {
    color: $brand-primary;
    font-family: $inter-bold; //@include nice-underline($grey-blue, $grey-dark, $grey-lighter);
  }
}

.two-cols__title {
  font-size: modular-scale(2 * 0.5);
  font-weight: bold;
  margin: $small-spacing 0;
  color: $brand-primary;
}

// Download blocks
.download-block {
  .container {
    padding: $small-spacing/2 0;
    background-color: $off-white;
  }
}

.sitemap {
  padding: $base-spacing;
}

.sitemap__list {
  margin-bottom: $small-spacing;
  padding-top: $small-spacing;
}

.sitemap {
  .fa {
    font-size: 13px;
    padding: 6px $small-spacing/1.5;
    margin-left: 8px;
    font-weight: bold;
    border-radius: 2px;
    background: $link-grey;
    cursor: pointer;
    &:hover {
      color: $brand-primary;
    }
  }
}

.sitemap__list--top-level {
  > .sitemap__list-item {
    margin-bottom: $base-spacing;
  }
}

.sitemap__list-link--top-level {
  font-size: modular-scale(0, 1.2em);
  font-family: $inter-bold;
}

.component--3col .suggestion-grid__item .news__meta {
  padding-top: 0.5em;
  text-align: left;
}

.slick-lightbox-slick-item-inner {
  //width: 100%;
}
.slick-lightbox-slick-img {
  max-width: 94%;
}
.slick-lightbox-slick-caption {
  right: 3%;
}

#cookie-wrapper {
  padding: 0.5em 1em;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  border-top: 1px solid #d1d1d1;

  h1 {
    display: inline-block;
    vertical-align: middle;
    margin: 0 1em 0 0;
    font-size: 1em;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 1em;

    @media all and (max-width: 1024px) {
      display: block;
      margin: 1em 0 0;
    }
  }

  a {
    color: $brand-primary;
    text-decoration: underline;
  }
}

.background-color,
.background-colour {
  &-grey,
  &--grey {
    background-color: $brand-grey5;
  }
}

[class*="background-color"],
[class*="background-colour"] {
  &.container,
  & > .container {
    padding: $base-spacing * 1.5;

    @at-root {
      main.main-content > & {
        @include padding(null $base-spacing * 0.7);
      }
    }
  }
}

body {
  /*padding-bottom: 47px;*/

  @media all and (max-width: 1024px) {
    /*padding-bottom: 87px;*/
  }

  @media all and (max-width: 750px) {
    /*padding-bottom: 111px;*/
  }

  @media all and (max-width: 450px) {
    /*padding-bottom: 135px;*/
  }
}


//Override two_cols padding
.two-cols__col {
    padding-right: 0 !important;
    padding-left: 0 !important;
}