.investors-chart {
  overflow-x: auto;
  svg {
    display: block;
    margin: 0 auto;
  }
}

.chart-title {
  display: inline-block;
  font-size: modular-scale(0);
  padding-right: $small-spacing;
  margin-bottom: $small-spacing;
}

.chart__options {
  display: block;
  float: right;
  margin-top: 20px;
  select {
    margin: 0;
  }
}

.chart__current-price {
  font-size: modular-scale(3);
  font-family: $inter-bold;
  font-weight: normal;
  margin-bottom: $small-spacing/4;
}

.line {
  fill: none;
  stroke: $brand-yellow;
  stroke-width: 5px;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

.investors-info p {
  margin-bottom: 0;
}

.investors-info__table {
  width: 100%;
  th {
    font-weight: bold;
  }
  td,
  th {
    padding: 6px $small-spacing;
    border: 1px solid $grey-lighter;
    margin: 0;
  }
  @include media($md) {
    margin: $small-spacing 0;
  }
}

.investors-info__title {
  font-size: modular-scale(1);
  margin: 0 0 $small-spacing;
}

.chart-container {
  overflow: scroll;
  @include media($md) {
    overflow: hidden;
  }
}

// .investors-info {
// @include span-columns(12);
// @include media($md) {
// @include span-columns(6);
// @include omega(2n);
// padding-top: 0;
// &:first-child { border-right: 2px solid $grey-lighter; }
// }
// }
// .investors-info--table {
// padding-top: $base-spacing;
//@include shift(1);
//@include omega();
// }
.row--investors {
  display: flex;
  flex-direction: column;
  @include media($lg) {
    flex-direction: row;
  }
}

.investors--col {
  margin: 0 0 30px;
  padding: 0;
  position: relative;
  width: 100%;
  &.investors-info,
  &.investors-chart {
    text-align: center;
  }
  &.investors-info--table {
    margin-bottom: 0;
  }
  @include media($lg) {
    margin: 0;
    padding: 0 ($small-spacing*2);
    width: 20%;
    &.investors-chart {
      border: 1px solid $grey-lighter;
      border-width: 0 1px;
      width: 50%;
    }
    &.investors-info--table {
      width: 30%;
    }
    &.investors-info {
      text-align: left;
    }
  }
}

#chart {
  svg {
    g.x {
      g.tick {
        &:nth-child(even) {
          display: none;
          @include media($lg) {
            display: block;
            >text {}
          }
        }
      }
    }
  }
}