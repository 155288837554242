//News landing
.news__hero-block {
    @include media($md) {
        padding: 0;
        //@include box-shadow();
    }

    @include media($lg) {
        //max-height: 530px;
        @include box-shadow();
    }
}

.content__header--news-landing {
  @include media($md) {
    margin-bottom: $base-spacing;
  }
  @include media($lg) {
    //padding-top: 20px;
  }
}

.news__hero-title {
  font-size: modular-scale(1);
  color: $brand-primary;
  font-weight: normal;
  @include media($lg) {
    font-size: modular-scale(2);
  }
}

.news__hero__sub {
  font-size: modular-scale(0, 1em);
}

.news__hero-intro {
  font-size: modular-scale(0); //padding-bottom: $base-spacing;
}

.news__lead-image {
  margin-bottom: $small-spacing;
  @include media($md) {
    @include span-columns(6);
    margin-bottom: 0;
  }
  @include media($lg) {
    @include span-columns(8);
    position: relative;
    overflow: hidden;
  }
}

.center-image-container {
  @include media($lg) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
  }
}

.image-cont--dummy {
  @include media($lg) {
    padding-top: 70%;
    /* forces 1:1 aspect ratio */
  }
} // News image element itself
.news__lead-image--img {
  display: block;
  width: 100%;
  @include media($sm) {
      width: 50%;
  }
  @include media($md) {
      width: auto;
  }
}

.news__hero-content {
    @include media($md) {
        /*@include span-columns(6);
    padding: $base-spacing;
    padding-left: 0;
    padding-bottom: 0;*/
    }

    @include media($lg) {
        @include span-columns(4);
        padding: $base-spacing;
        padding-left: 0;
        padding-bottom: 0;
        padding-right: $base-spacing;
    }
}

// Article hero list view. Inherits styles from the main hero with --list-view modifier
.news__hero-block--list-view {
  box-shadow: none;
  .news__hero-item {
    @include clearfix;
    margin-bottom: $base-spacing;
    @include span-columns(12);
  }
  .news__lead-image {
    @include media($sm) {
      @include span-columns(4);
    }
    @include media($md) {
      @include span-columns(5);
    }
  }
  .news__hero-content {
    @include media($sm) {
      @include span-columns(8);
      @include omega();
    }
    @include media($md) {
      padding-top: 0;
      @include span-columns(7);
    }
  }
  .news__hero-title {
    padding-top: $small-spacing;
    @include media($md) {
      font-size: modular-scale(1);
    }
    @include media($lg) {
      font-size: modular-scale(2);
    }
  }
  .news__hero-intro {
    @include media($md) {
      //margin-bottom: $base-spacing;
    }
  }
  .news__meta-list {
    @include span-columns(12);
    padding-top: $small-spacing;
    margin-bottom: $base-spacing;
    @include media($md) {
      @include span-columns(6);
      margin-right: 0;
      padding-top: 0;
    }
  }
  .news__meta {
    border-top: 1px solid $grey-lighter;
    @include media($md) {
      padding-top: $small-spacing;
    }
    .btn {
      float: left;
      @include media($md) {
        float: right;
      }
    }
  }
}

// Search and filter 
.news-options {
  @include clearfix;
  @include media($md) {
    @include span-columns(7);
    @include omega();
  }
  @include media($lg) {
    // @include span-columns();
    // @include omega();
  }
}

.news-options__search {
  .search-input {
    font-size: modular-scale(0);
  }
}

.news-options__filter--label {
  display: none;
}

.news-options__filter {
  p {
    font-size: modular-scale(0);
  }
  @include media($md) {
    padding-right: 12px;
    margin-right: 12px; //margin-top: -34px;
    p {
      float: left;
      padding: 10px;
      padding-bottom: 0;
      margin: 0;
    }
    @include span-columns(6);
  }
}

.news-options__search {
  @include media($md) {
    @include span-columns(6);
  }
  @include media($lg) {
    //@include span-columns(6);
  }
}

.news-options__submit {
  visibility: hidden;
  position: absolute;
}

.news-options__selection {
  padding: $small-spacing;
  width: 100%;
  border-radius: 0;
  position: relative;
  height: 48px;
  @include media($md) {
    padding-right: $base-spacing;
  }
  &:after {
    content: "\f107";
    display: block;
    font-family: FontAwesome;
    right: 5px;
    top: 5px;
    position: absolute;
    color: #333;
  }
}

//Paging
.news-paging__link {
  color: $brand-primary;
  padding: 6px 10px;
  font-size: modular-scale(1);
  font-family: $inter-bold;
  &.current {
    color: $grey-dark;
  }
  &:focus,
  &:active {
    color: $grey-dark;
    background: $grey-lighter;
  }
}

.news-paging {
  text-align: right;
  padding: $base-spacing*2 0;
  border-top: 1px solid $grey;
}

.news-grid-head {
    .row {
        padding: $small-spacing 0 0;
        margin-bottom: 0;
        border-top: 1px solid $grey;
    }

    h5 {
        margin: 0;
    }
}