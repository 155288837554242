/*
    Styles to over-ride or control on the classes
    added by the Rich Text Editor in Umbraco.

    All editor content is inside this class container.

*/

.editor-content {
  // Capturing potentially unformatted instances of <a> links in user-editable content
  > p a,
  li > a,
  div > a,
  strong a {
    text-decoration: none; // Args: colour, hover colour, bg colour
    @include nice-underline($grey-blue, $grey-dark, $grey-lighter);
    @include link-states($grey-dark);
    &:focus {
      @include nice-underline($grey-blue, $grey-dark, $grey-lighter);
    }
  }
  table {
    border: 0 !important;
  } // Umbraco adds inline styles and fixed dimensions to images added in the text editor. Removing them.
  p > img {
    height: auto !important;
    width: auto !important;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin: 0 0 $base-spacing 0;
  }
  p {
    margin-bottom: $base-spacing/1.2;
    line-height: 1.6;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: modular-scale(3);
  }
  .h2,
  h2 {
    font-size: modular-scale(2);
  }
  .h3,
  h3 {
    font-size: modular-scale(1);
  }
  .h4,
  .h5,
  .h6 {
    font-size: modular-scale(0);
  }
  ol,
  ul {
    padding-left: $small-spacing;
  }
  iframe {
    max-width: 100%;
  }
  .pdf-icon {
    text-decoration: none; // Args: colour, hover colour, bg colour
    @include nice-underline($grey-blue, $grey-dark, $grey-lighter);
    @include link-states($grey-dark);
    &:focus {
      @include nice-underline($grey-blue, $grey-dark, $grey-lighter);
    }
    &:after {
      color: $grey-blue;
    }
  }
}

.editor-content,
.rte {
  @at-root {
    .jumbo-header ~ &,
    .jumbo-header + .container {
      .editor-content {
        padding: {
          left: $base-spacing * 1.5;
          right: $base-spacing * 1.5;
        }
      }
    }
  }

  &[class*="background-colour"] {
    .editor-content {
      > p:last-child {
        margin-bottom: 0;
      }
    }

    @at-root {
      .jumbo-header + .container + &,
      .editor-content + & {
        margin-top: 0 !important;
      }
    }
  }
}
