.dropdown-select-label {
  margin-right: 10px;
  font-weight: bold;
  float: left;
}

.events-filter {
  line-height: 2;
}

.dropdown-select {
  $action-height: 38px;
  $list-gap: 8px;
  position: relative;
  display: inline-block;
  .dropdown-select__action {
    @include clearfix;
    display: block;
    cursor: pointer;
    font-size: $base-font-size;
    height: $action-height;
    background: $grey-lighter;
    padding: 6px $base-spacing;
    width: auto;
    outline: 0;
    transition: all $base-duration $base-timing;
    border: 1px solid $grey-lighter;
    &:after {
      content: '\f078';
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      margin: 0px 0px 0px 10px;
      text-decoration: none;
      display: inline-block;
    }
  }
  .event-list {
    @include box-shadow(0, 8px, 20px, rgba(0, 0, 0, .35));
    background-color: $grey-lighter;
    display: none;
    position: absolute;
    padding: 0;
    padding-bottom: $list-gap;
    z-index: 1;
    top: $action-height + $list-gap;
    transition: all .5s .5s;
    opacity: 0;
    line-height: 1.4;
    .no-js & {
      opacity: 1;
      display: block;
      top: 5px;
      position: relative;
    }
    li {
      list-style: none;
      white-space: nowrap;
      margin-bottom: 0;
    }
    a {
      padding: 8px $base-spacing 8px $base-spacing * 1.5;
      position: relative;
      display: block;
      &:hover,
      &:focus {
        &:before {
          content: '\f061';
          font-family: FontAwesome;
          font-weight: normal;
          font-style: normal;
          margin: 0px;
          text-decoration: none;
          position: absolute;
          left: 10px;
          top: 8px;
        }
      }
    }
    .selected {
      a {
        font-weight: bold;
        &:before {
          content: '\f00c';
          font-family: FontAwesome;
          font-weight: normal;
          font-style: normal;
          margin: 0px;
          text-decoration: none;
          position: absolute;
          left: 10px;
          top: 8px;
        }
      }
    }
  }
  span {
    background-color: $brand-primary;
    color: #fff;
    font-size: $small-font-size;
    margin-left: .75em;
    padding: 3px 4px;
    text-transform: uppercase;
    font-weight: normal;
  }
  &.active {
    .event-list {
      display: block;
      opacity: 1;
    }
  }
}