﻿.tabs {
    margin: $base-spacing -17px;
    overflow-x: hidden;

    @include media($lg) {
        margin: $base-spacing 0;
        //overflow: visible;
    }

    &__controls {
        padding: 0 17px 0 0;
        margin-bottom: $base-spacing;

        @include media($lg) {
            display: none;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    &__nav {
        display: flex;
        transition: all 300ms;

        &-item {
            flex: 0 0 auto;
            display: inline-block;
            vertical-align: bottom;
            margin: 0 0 0 0;
            border: 2px solid $brand-2024-sky2;
            border-bottom: 0;

            a {
                display: block;
                padding: $small-spacing $base-spacing;
                font-size: 1.333em;
                background-color: white;
            }

            &--active {
                position: relative;
                background-color: $brand-2024-sky2;

                &:after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $brand-2024-sky2;
                }

                a {
                    background-color: $brand-2024-sky2;
                }
            }
        }
    }

    &__item {
        display: none;
        padding: $base-spacing;
        /*border: 1px solid $brand-2024-sky3;*/
        background-color: $brand-2024-sky2;


        &--0 {
            display: block;
        }

        .row {
            margin: 0;
        }

        .container {
            padding: 0;
        }
    }
}
